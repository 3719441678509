import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Tag, Divider, Button } from "antd";
import "antd/dist/antd.css";
import logo from "../img/M_logo.jpg";
import Column from "rc-table/lib/sugar/Column";
import { FiChevronDown } from "react-icons/fi";
import GoPage from "../lib/GoPage";
import { MenuOutlined } from "@ant-design/icons";
import { Carousel } from "antd";
import { Drawer } from "antd";
import DrawerMenu from "./DrawerMenu";

const TopContent = styled.ul`
  display: flex;
  background-color: #264a7b;
  align-items: center;
  margin-bottom: 0;
  padding: 10px 0;
  li {
    text-align: center;
    display: block;
  }
  .center-area {
    flex: 1;
  }
  .left-area,
  .right-area {
    width: 50px;
  }
`;
const ProfileContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
const ProfileContent = styled.div`
  width: 940px;
  color: #484848;
`;
const ProfileTitle = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  font-size: 22px;
  margin-top: 30px;
  margin-bottom: 30px;
  color: #274c7e;
`;

const ProfileInfo = styled.div`
  display: flex;
  justify-content: left;
  font-size: 16px;
  flex-direction: column;
`;
// const BookListContent = styled.div`
//   display: flex;
//   flex-flow: wrap;
//   justify-content: start;

//   .bookItem {
//     /* border: 3px solid red; */
//     margin: 7px;
//   }
//   .bookName {
//     justify-content: center;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     font-size: 20px;
//   }
// `;

const DrawerConainer = styled.div`
  a {
    text-decoration: none !important;
  }
  .drawer-font {
    font-size: 70px;
  }
`;

function MobileProp1() {
  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const click_inquiry = () => {
    window.open(
      "https://docs.google.com/forms/d/e/1FAIpQLScNmpqRE8Lc977mqSY07FxWrOPEMTWNOyGU6upUAfiyaZyw3w/closedform",
      "_blank",
      "width=600, height=800, top=100, left=100"
    );
    //새창 팝업창 띄우기
    //window.open("https://www.naver.com", "_blank", "width=600, height=800, top=300, left=300")
  };

  return (
    <div style={{ width: "100%" }}>
      <DrawerMenu />

      <div>
        <img src="/img/M_teacher4_img.png" width="100%"></img>
      </div>

      <ProfileContainer style={{ paddingLeft: "5%", paddingRight: "5%" }}>
        <ProfileContent>
          <ProfileTitle>
            <img
              style={{ height: 25, marginRight: 5 }}
              src="./img/prop_icon.jpg"
            />
            학력사항
          </ProfileTitle>
          <ProfileInfo>
            · 부산외국어대학교 스포츠재활학 석사 졸업 <br />· 부산외국어대학교
            스포츠재활학 박사 과정
          </ProfileInfo>

          <ProfileTitle>
            <img
              style={{ height: 25, marginRight: 5 }}
              src="./img/prop_icon.jpg"
            />
            교육 경력 사항
          </ProfileTitle>
          <ProfileInfo>
            · 전) 함안 대신고등학교 여자 축구부 팀닥터
            <br />
            · 전) 부산외국어대학교 배드민턴 선수부 컨디셔닝 관리 (제95회
            전국체육대회)
            <br />
            · 현) 부산외국어대학교 스포츠재확학과 겸임교수
            <br />
            · 현) KARFE 교육강사 <br />· 현) 스마트핏 구서점 팀장
          </ProfileInfo>

          <ProfileTitle>
            <img
              style={{ height: 25, marginRight: 5 }}
              src="./img/prop_icon.jpg"
            />
            자격 및 면허
          </ProfileTitle>
          <ProfileInfo>
            · Ultimateinstability(Aquabag&Aquaball) Instructor
            <br />
            · STC(basic, advance, rehab) Master
            <br />
            · CFSC Level 1 Instructor
            <br />
            · KARFE CPT, CES, PES Instructor
            <br />
            · Funtional Training(GV, KP, SR, CFT) Instructor
            <br />· 산전&산후 전문가, 수기치료 카이로프랙틱 전문가 과정
          </ProfileInfo>

          {/* <ProfileTitle>
            <img
              style={{ height: 25, marginRight: 5 }}
              src="./img/prop_icon.jpg"
            />
            저서 및 역서
          </ProfileTitle>
          <div>
            <img src="/img/M_teacher4_book.jpg" width="100%"></img>
          </div>

          <ProfileTitle>
            <img
              style={{ height: 25, marginRight: 5 }}
              src="./img/prop_icon.jpg"
            />
            학술회 발표
          </ProfileTitle>
          <ProfileInfo>
            2011, Asia Western Pacific Region of WCPT, Bally, Indonesia
            <div style={{ height: 15 }}></div>
            2012, 2013 Oklahoma Association for Health, Physical Education,
            Recreation, and Dance <div style={{ height: 15 }}></div>
            2013, Central States ACSM Regional - Physical Activity and Weight
            Status of UCO College Students
            <div style={{ height: 15 }}></div>2014, East Tennesse State
            University, Center of Excellence for sport science and coach
            educaiton, (December 5th-6th, 2014)
          </ProfileInfo> */}
          <div style={{ height: 50 }}></div>
        </ProfileContent>
      </ProfileContainer>
    </div>
  );
}

export default MobileProp1;
