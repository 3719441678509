import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import pr from "../lib/Pr";
import Axios from "axios";
import moment from "moment";
import { Spin } from "antd";
import GetToken from "../lib/GetToken";
import GetLogin from "../lib/GetLogin";
// import Container from './Container';
import Layout from "./Layout";
import Loading from "./Loading";
import { Tag, Divider, Button, Input, Modal } from "antd";
import "antd/dist/antd.css";
import GoPage from "../lib/GoPage";
import GetHost from "../lib/GetHost";
import axios from "axios";
import GetCid from "../lib/GetCid";
import { LeftOutlined } from "@ant-design/icons";
import BackBtn from "./BackBtn";



const RootContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const TopContainer = styled.div`
  flex: 1;
  /* padding: 5px; */
  /* border: 2px solid black; */
  display: flex;
  justify-content: center;
  Button {
    height: 40px;
    font-size: 18px;
  }
`;
const Content = styled.div`
  width: 90%;
  /* border: 2px solid green; */
`;

const BottomContainer = styled.div`
  /* border: 2px solid red; */
  height: 260px;
`;

const LoginLogoContainer = styled.div`
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding-top: 30px; */
`;
const LoginLogoContent = styled.div`
  /* border: 1px solid blue; */
  text-align: center;
  img {
    width: 260px;
  }
`;

const LoginTextContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
`;

const LoginText = styled.div`
  width: 80%;
  /* border: 1px solid orange; */
  .login-text1 {
    /* border: 1px solid green; */
    font-size: 20px;
    font-weight: bold;
  }
  .login-text2 {
    /* border: 1px solid green; */
    font-size: 16px;
    color: #7f7f7f;
  }

`;

const LoginInputContainer = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: center;
`;


const LoginContainer = styled.div`
  /* border: 1px solid red; */
  display: flex;
  justify-content: center;
`;
const InputContent = styled.div`
  /* border: 1px solid purple; */
  width: 80%;
  Input {
    border: none;
    border-bottom: 1px solid #7f7f7f;
    padding-top: 5px;
    margin-bottom: 10px;
  }
  Button {
    /* margin-top: 10px; */
  }
  [ant-click-animating-without-extra-node]:after {
    -webkit-animation: none !important;
    -moz-animation: none !important;
    -o-animation: none !important;
    -ms-animation: none !important;
    animation: none !important;
  }
  input:focus {
    outline: 0;
    box-shadow: none;
  }
`;
const LoginInputTitle = styled.div`
  font-size: 14px;
  /* margin-bottom: 10px; */
`

function FindPw1() {
  let result;

  const [loaded, setLoaded] = useState(false);
  const [showFind, setShowFind] = useState(true);  // 01. 찾기클릭 true
  const [showAuth, setShowAuth] = useState(false);  // 02. 인증번호 입력 false
  const [showPass, setShowPass] = useState(false); // 03. 비밀번호 변경 false
  const cid = GetCid();
  let data = {};

  setTimeout(()=>{
    setLoaded(true);
  },1000)

  const onNameChange = e => {
    data.name = e.target.value;
  }

  const onPhoneChange = e => {
    data.phone = e.target.value;
  }

  const onAuthChange = e => {
    data.auth = e.target.value;
  }

  const onPw1Change = e => {
    data.pw1 = e.target.value;
  }

  const onPw2Change = e => {
    data.pw2 = e.target.value;
  }

  const onFindClick = async () => {
    result = await axios.post(GetHost() + "/gympass/find_pw1", {
      cid:cid,
      name:data.name,
      phone:data.phone
    });
    if (result.data.ret == "Y") {
          setShowFind(false);
          setShowAuth(true);
    } else {
      Modal.error({ title: "오류 메시지", content: result.data.msg });
    }
  };

  const onAuthClick = async () => {

    const result = await axios.post(GetHost()+'/gympass/find_pw2',{
      cid:cid,
      name:data.name,
      phone:data.phone,
      auth:data.auth,
    });

    if(result.data.ret==='Y') {
      setShowFind(false);
      setShowAuth(false);
      setShowPass(true);
    }
    else {
      Modal.error({title:'오류',content:result.data.msg});
    }

  }

  const onNewPassClick = async() => {

    if(data.pw1 === undefined || data.pw1==='') {
      Modal.error({ title: "오류", content: '비밀번호를 입력하세요.' });
      return;
    }
    if(data.pw2 === undefined || data.pw2==='') {
      Modal.error({ title: "오류", content: '비밀번호를 입력하세요.' });
      return;
    }

    const result = await axios.post(GetHost()+'/gympass/find_pw3',{
      cid:cid,
      phone:data.phone,
      name:data.name,
      auth:data.auth,
      pw1:data.pw1,
      pw2:data.pw2,
    })
    if(result.data.ret==='Y') {
      Modal.success({
        title:'성공메시지',
        content:'비밀번호 변경이 완료되었습니다.',
        onOk:()=>{
          GoPage('login',cid);
        }
    });
    }
    else {
      Modal.error({title:'오류메시지',content:result.data.msg});
    }
  };

  return (
    <Layout>
      {loaded === false ? (
        <Loading />
      ) : (
        <>
          <BackBtn go="home" cid={cid} onClick={() => GoPage("login")} />

          <RootContainer>
            <TopContainer>
              <Content>
                <LoginLogoContainer>
                  <LoginLogoContent>
                    <img
                      src="/img/common/main_logo_black.png
"
                      alt="#"
                    ></img>
                  </LoginLogoContent>
                </LoginLogoContainer>

                <LoginTextContainer>
                  <LoginText>
                    <div className="login-text1">비밀번호찾기</div>
                    <div className="login-text2">회원정보를 입력하세요.</div>
                  </LoginText>
                </LoginTextContainer>

                <LoginInputContainer>
                  <InputContent>
                    <div>
                      <div style={{ display: showFind ? "block" : "none" }}>
                        <LoginInputTitle>
                          이름<Input onChange={onNameChange}></Input>
                        </LoginInputTitle>

                        <LoginInputTitle>
                          휴대폰번호
                          <Input
                            onChange={onPhoneChange}
                            placeholder="-없이 숫자만 입력"
                          ></Input>
                          <div style={{ marginTop: 15 }}></div>
                        </LoginInputTitle>

                        <Button type="primary" onClick={onFindClick}>
                          비밀번호 찾기
                        </Button>
                      </div>

                      <div style={{ display: showAuth ? "block" : "none" }}>
                        <LoginInputTitle>인증번호</LoginInputTitle>
                        <LoginInputTitle>
                          인증번호를 휴대폰으로 전달 하였습니다. 인증번호를
                          입력하세요.
                        </LoginInputTitle>
                        <Input onChange={onAuthChange}></Input>
                        <div style={{ marginTop: 15 }}>
                          <Button type="primary" onClick={onAuthClick}>
                            인증확인
                          </Button>
                        </div>
                      </div>

                      <div style={{ display: showPass ? "block" : "none" }}>
                        <LoginInputTitle>
                          새 비밀번호 입력
                          <Input
                            type="password"
                            placeholder="6-12자리, 알파벳과 숫자 조합"
                            onChange={onPw1Change}
                          ></Input>
                          <Input
                            type="password"
                            placeholder="비밀번호 확인"
                            onChange={onPw2Change}
                          ></Input>
                        </LoginInputTitle>
                        <Button type="primary" onClick={onNewPassClick}>
                          비밀번호 변경
                        </Button>
                      </div>
                    </div>
                  </InputContent>
                </LoginInputContainer>
              </Content>
            </TopContainer>

            <BottomContainer>
              <LoginContainer>
                {/* <LoginBtn onClick={() => handleLogin()}>로그인하기</LoginBtn> */}
              </LoginContainer>
            </BottomContainer>
          </RootContainer>


        </>
      )}
    </Layout>
  );
}

export default FindPw1;

 /* <Divider orientation="left">비밀번호 찾기</Divider>
          <div>
            <div style={{ display: showFind ? "block" : "none" }}>
              <div>
                이름<Input ref={nameInput}></Input>
              </div>
              <div>
                휴대폰번호
                <Input ref={phoneInput} placeholder="-없이 숫자만 입력"></Input>
              </div>
              <Button type="primary" onClick={onFindClick}>
                비밀번호 찾기
              </Button>
            </div>

            <div style={{ display: showAuth ? "block" : "none" }}>
              인증번호
              <div>
                인증번호를 휴대폰으로 전달 하였습니다. 인증번호를 입력하세요.
              </div>
              <Input ref={authInput} placeholder=""></Input>
              <Button type="primary" onClick={handleAuth}>
                인증확인
              </Button>
            </div>

            <div style={{ display: showPass ? "block" : "none" }}>
              새 비밀번호 입력
              <Input
                ref={pw1Input}
                type="password"
                placeholder="6-12자리, 알파벳과 숫자 조합"
              ></Input>
              <Input
                ref={pw2Input}
                type="password"
                placeholder="비밀번호 확인"
              ></Input>
              <Button type="primary" onClick={onNewPassClick}>
                비밀번호 변경
              </Button>
            </div>
          </div>
          <div></div> */