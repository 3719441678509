import React from "react";
import styled from "styled-components";
import "antd/dist/antd.css";
import "antd/dist/antd.css";
import GoPage from "../lib/GoPage";
import logo from "../img/logo_08.jpg";

const MenuContent = styled.ul`
  width: 100%;
  height: 80px;
  font-size: 20px;
  background-color: #dcdddd;
  justify-content: center;
  display: flex;
  margin-bottom: 0;
  li {
    text-align: center;
    width: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #2e2f29;
    cursor: pointer;
  }
  .underline {
    text-decoration: underline #244673;
    text-underline-position: under;
    cursor: pointer;
  }

`;


const RootContainer = styled.div`
  .propContainer {
    color: #fff;
    background: url(/img/teacher5.jpg) no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 800px;
  }
  .propContent {
    display: flex;
    width: 1060px;
    height: 330px;
    letter-spacing: 1px;
  }

  .propLeft {
    width: 50%;
  }
  .propRight {
    width: 50%;
  }

  .propContetnTitle {
    font-size: 30px;
    font-family: RixCrossRoadR;
    color: #41433f;
  }
  .propContetnText {
    font-size: 100px;
  }
`;

const ProfileContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
const ProfileContent = styled.div`
  width: 940px;
  color: #484848;


`;
const ProfileTitle = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  font-size: 30px;
  margin-top: 30px;
  margin-bottom: 30px;
  color: #274c7e;

`;

const ProfileInfo = styled.div`
  display: flex;
  justify-content: left;
  font-size: 25px;
  flex-direction: column;

`;
const BookListContent = styled.div`
  display: flex;
  flex-flow: wrap;
  justify-content: start;

  .bookItem {
    border: 3px solid red;
    margin: 7px;
  }
  .bookName {
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 20px;
  }
`;
const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background-color: #264a7b;
  color: #fff;
  font-size: 17px;
`;

const MenuListItem = styled.div`
  display: flex;
  list-style: none;
  justify-content: center;
  align-items: center;
  li {
    width: 170px;
    text-align: center;
    cursor: pointer;
  }
  button {
    background-color: #3f968e;
  }
`;



function Prop1() {
  let nowActiveMenu1 = "";
  let nowActiveMenu2 = "";
  let nowActiveMenu3 = "";
  let nowActiveMenu4 = "";
  let nowActiveMenu5 = "";

  // if(active==="about1") {
  //   nowActiveMenu1 = "now-menu-is-active";
  // }
  // if (active === "about2") {
  //   nowActiveMenu2 = "now-menu-is-active";
  // }
  // if(active==="about3") {
  //   nowActiveMenu3 = "now-menu-is-active";
  // }
  // if (active === "about4") {
  //   nowActiveMenu4 = "now-menu-is-active";
  // }
  // if (active === "about5") {
  //   nowActiveMenu5 = "now-menu-is-active";
  // }

   const GoPage = (go) =>{
    if (go ==='prop1'){
      window.location.href='/Prop1'
    }
    if (go === "prop2") {
       window.location.href = "/Prop2";
     }
    if (go === "prop3") {
        window.location.href = "/Prop3";
      }
    if (go === "prop4") {
         window.location.href = "/Prop4";
       }
    if (go === "prop5") {
          window.location.href = "/Prop5";
        }
   }

  return (
    <RootContainer>
      <MenuContainer>
        <div style={{ height: 42 }}></div>
        <div>
          <img src={logo}></img>
        </div>
        <div style={{ height: 38 }}></div>
        <MenuListItem>
          <li>
            <a href="/" style={{ color: "inherit" }}>
              HOME
            </a>
          </li>
          <li>
            <a href="/#page2" style={{ color: "inherit" }}>
              예방운동관리사 소개
            </a>
          </li>
          <li>
            <a href="/#page3" style={{ color: "inherit" }}>
              조직도
            </a>
          </li>
          <li onClick={() => GoPage("prop1")}>
            <a href="/#page4" style={{ color: "inherit" }}>
              교수소개
            </a>
          </li>
          <li>
            <a href="/#page5" style={{ color: "inherit" }}>
              교육일정
            </a>
          </li>
          <li>
            <a href="/#page6" style={{ color: "inherit" }}>
              등록안내
            </a>
          </li>
          <div>
            <button>교육등록바로가기</button>
          </div>
        </MenuListItem>
        <div style={{ height: 27 }}></div>
      </MenuContainer>
      <MenuContent>
        <li className={nowActiveMenu1} onClick={() => GoPage("prop1")}>
          박일봉 교수
        </li>
        <li className={nowActiveMenu2} onClick={() => GoPage("prop2")}>
          이채관 교수
        </li>
        <li className={nowActiveMenu3} onClick={() => GoPage("prop3")}>
          김정연 교수
        </li>
        <li className={nowActiveMenu4} onClick={() => GoPage("prop4")}>
          이자연 교수
        </li>
        <li className={nowActiveMenu5} onClick={() => GoPage("prop5")}>
          <span className="underline">강슈호 교수</span>
        </li>
      </MenuContent>
      <div className="propContainer">
        <div className="propContent">
          <div className="propLeft"></div>
          <div className="propRight">
            <div className="propContetnTitle">
              스포츠 필라테스 트레이닝 전문가
            </div>

            <div className="propContetnText">
              강 슈 호 <span style={{ fontSize: 70 }}>교수</span>
              <br />
            </div>
          </div>
        </div>
      </div>

      <ProfileContainer>
        <ProfileContent>
          <ProfileTitle>
            <img
              style={{ height: 25, marginRight: 5 }}
              src="./img/prop_icon.jpg"
            />
            학력사항
          </ProfileTitle>
          <ProfileInfo>
            · 일본 와코대학 예술학과(농구선수) 졸업 <br />
            · 부산외국어대학교 일반대학원 스포츠재활학과 석사 졸업
            <br />· 부산외국어대학교 스포츠건강융합학과 박사과정 재학 중
          </ProfileInfo>

          <ProfileTitle>
            <img
              style={{ height: 25, marginRight: 5 }}
              src="./img/prop_icon.jpg"
            />
            교육 경력 사항
          </ProfileTitle>
          <ProfileInfo>
            · 전) 샤샤필라테스 매니저 <br />· 현) 버티힐즈 필라테스 & PT 원장{" "}
            <br />· 현) 대한기능재활운동협회 KARFE 교육이사 <br />
          </ProfileInfo>

          <ProfileTitle>
            <img
              style={{ height: 25, marginRight: 5 }}
              src="./img/prop_icon.jpg"
            />
            자격 및 면허
          </ProfileTitle>
          <ProfileInfo>
            · Powerplate Master (USA) <br />· Ultimateinstability Aquabag Master
            Trainer(Netherlands) <br />· STC Master Trainer
            <br /> · Powerplate Master
            <br /> · RAD Master Trainer (USA) <br />· CFSC Level 1, 2 (Certified
            Functional Strength Coach) <br />· DNS A, B, Pilates, Foot Course
            <br />· PMA Membership(Pilates Method Alliance at USA)
            <br /> · NASM CPT, CES, PES
          </ProfileInfo>

          <ProfileTitle>
            <img
              style={{ height: 25, marginRight: 5 }}
              src="./img/prop_icon.jpg"
            />
            논문
          </ProfileTitle>
          <ProfileInfo>
            · 필라테스 운동이 무지외반증의 발 변형각도에 미치는 영향 <br />
            - 부산외국어대학교 석사학위 논문, 2020
            <br />
          </ProfileInfo>

          <ProfileTitle>
            <img
              style={{ height: 25, marginRight: 5 }}
              src="./img/prop_icon.jpg"
            />
            방송 및 잡지 출현
          </ProfileTitle>
          <ProfileInfo>
            · 2014년 키즈맘 출산 후 다이어트필라테스 촬영
            <br /> · 2015년 JTBC (닥터의 승부) 출연
            <br /> · 2016년 키즈맘 아리와 함께 하는 필라테스 촬영 <br />· 2017년
            체널A (닥터 지바고) 출연
          </ProfileInfo>
          <div style={{ height: 200 }}></div>
        </ProfileContent>
      </ProfileContainer>
    </RootContainer>
  );
}

export default Prop1;
