import React, { useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./components/Home";
import Home2022 from "./components/Home2022";
import About1 from "./components/About1";
import About2 from "./components/About2";
import About3 from "./components/About3";
import About4 from "./components/About4";
import About5 from "./components/About5";
import Login from "./components/Login";
import FindPw1 from "./components/FindPw1";
import Logged from "./components/Logged";
import MyPt from "./components/MyPt";
import MyCoupon from "./components/MyCoupon";
import PtReservation from "./components/PtReservation";
import MyFq from "./components/MyFq";
import MyOrder from "./components/MyOrder";
import MyInfo from "./components/MyInfo";
import Join1 from "./components/Join1";
import Join2 from "./components/Join2";
import Join3 from "./components/Join3";
import Order from "./components/Order";
import OrderResult from "./components/OrderResult";
import Test from "./components/Test";
import Test1 from "./components/Test1";

import Test2 from "./components/Test2";
import Notice from "./components/Notice";
import TestScroll from "./components/TestScroll";
import Privacy from "./components/Privacy";
import Prop1 from "./components/Prop1";
import Prop2 from "./components/Prop2";
import Prop3 from "./components/Prop3";
import Prop4 from "./components/Prop4";
import Prop5 from "./components/Prop5";
import DrawerMenu from "./components/DrawerMenu";
import MobileHome from "./components/MobileHome";
import MobileProp1 from "./components/MobileProp1";
import MobileProp2 from "./components/MobileProp2";
import MobileProp3 from "./components/MobileProp3";
import MobileProp4 from "./components/MobileProp4";
import MobileProp5 from "./components/MobileProp5";



import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from "recoil";


function App() {
  return (
    <RecoilRoot>
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/Home2022" component={Home2022} />
          <Route exact path="/home" component={Home} />
          <Route exact path="/draw_menu" component={DrawerMenu}/>
          <Route exact path="/mobile_home" component={MobileHome} />
          <Route exact path="/mobile_prof1" component={MobileProp1} />
          <Route exact path="/mobile_prof2" component={MobileProp2} />
          <Route exact path="/mobile_prof3" component={MobileProp3} />
          <Route exact path="/mobile_prof4" component={MobileProp4} />
          <Route exact path="/mobile_prof5" component={MobileProp5} />

          <Route exact path="/about1" component={About1} />
          <Route exact path="/about2" component={About2} />
          <Route exact path="/about3" component={About3} />
          <Route exact path="/about4" component={About4} />
          <Route exact path="/about5" component={About5} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/findpw1" component={FindPw1} />
          <Route exact path="/logged" component={Logged} />
          <Route exact path="/join1" component={Join1} />
          <Route exact path="/join2" component={Join2} />
          <Route exact path="/join3" component={Join3} />
          <Route exact path="/order" component={Order} />
          <Route exact path="/order_result" component={OrderResult} />
          <Route exact path="/my_coupon" component={MyCoupon} />
          <Route exact path="/my_pt" component={MyPt} />
          <Route exact path="/my_fq" component={MyFq} />
          <Route exact path="/my_order" component={MyOrder} />
          <Route exact path="/my_info" component={MyInfo} />
          <Route exact path="/pt_reservation" component={PtReservation} />
          <Route exact path="/notice" component={Notice} />
          <Route exact path="/test" component={Test} />
          <Route exact path="/test1" component={Test1} />

          <Route exact path="/test2" component={Test2} />
          <Route exact path="/test_scroll" component={TestScroll} />
          <Route exact path="/privacy" component={Privacy} />
          <Route exact path="/prop1" component={Prop1} />
          <Route exact path="/prop2" component={Prop2} />
          <Route exact path="/prop3" component={Prop3} />
          <Route exact path="/prop4" component={Prop4} />
          <Route exact path="/prop5" component={Prop5} />
        </Switch>
      </Router>
    </RecoilRoot>
  );
}

export default App;
