import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import pr from "../lib/Pr";
import Axios from "axios";
import moment from "moment";
import GetToken from "../lib/GetToken";
import GetLogin from "../lib/GetLogin";
import GetYoil from "../lib/GetYoil";
// import Container from './Container';
import Layout from "./Layout";
import Loading from "./Loading";
import { Form, Tag, Divider, Button, Modal, Select, Input } from "antd";
import "antd/dist/antd.css";
import GoPage from "../lib/GoPage";
import TitleText from "./TitleText";
import MyPtMenu from "./MyPtMenu";
import GetHost from "../lib/GetHost";
import GetMcd from "../lib/GetMcd";
import axios from "axios";
import queryString from "query-string";
import { ScheduleOutlined, LeftOutlined } from "@ant-design/icons";
import GetCid from "../lib/GetCid";
import { useRecoilValue } from "recoil";
import { loginState } from "../lib/State";
import LoginAuth from "../lib/LoginAuth";

const { confirm, success, error } = Modal;
const { Option } = Select;

const Container = styled.div`
  background-color: #111111;
  color: #ffffff;
  height: 100%;
`;
const ReservationTop = styled.div`
  display: flex;
  width: 100%;
  height: 50px;
  align-items: center;
  font-size: 20px;
  padding-top: 30px;
  padding-bottom: 30px;
  border-bottom: 2px solid #333333;
  li {
    text-align: center;
    list-style: none;
  }
  .reservation-top {
    flex: 1;
  }
`;

// const CardStyle = styled.div`
//   color: #fff;
//   background-color: #222222;
//   border-radius: 10px;
//   padding: 25px 20px;
//   border: 2px solid #535353;
//   margin-bottom: 10px;
//   position: relative;

//   & .state {
//     font-size: 16px;
//     margin-bottom: 10px;
//   }

//   & .state img {
//     margin-right: 4px;
//     width: 20px;
//   }

//   & .yellow {
//     color: #fff566;
//   }

//   & .orange {
//     color: #fa8c16;
//   }

//   & p {
//     margin-bottom: 0;
//     font-size: 13px;
//   }
// `;

// const ButtonStyle = styled.button`
//   padding: 3px 12px;
//   font-size: 11px;
//   border: none;
//   border-radius: 5px;
//   position: absolute;
//   right: 5%;
//   top: 50%;
//   margin-top: -25px;

//   &.rate {
//     background-color: #ff4b50;
//   }

//   &.req {
//     background-color: #4c6eec;
//   }
//   & img {
//     display: block;
//     margin: 0 auto;
//     padding-top: 3px;
//     width: 20px;
//   }
// `;

const DateList = styled.div`
  display: flex;
  border-top: 1px solid #333333;
  overflow-x: scroll;
  height: 100px;
  align-items: center;
  .date {
    font-size: 12px;
    background: #111111;
    color: #ffffff;
  }
`;

const DateItem = styled.div`
  text-align: center;
  min-width: 40px;
  .day {
    font-size: 14px;
  }
  .circle {
  }
`;

const ReservationContainer = styled.div`
  display: flex;
  justify-content: center;
  font-size: 16px;
  .select-date {
    /* margin-top: 20px;
    margin-bottom: 20px; */
  }
`;

const ReservationContent = styled.div`
  width: 90%;
  align-items: center;

  .trainer {
    font-size: 16px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
`;

const SelectDate = styled.div`
  width: 90%;
  .select-date {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .selected-date {
    padding-top: 10px;
    padding-bottom: 10px;
  }
`;

const SelectTime = styled.div`
  display: flex;
  flex: 1;
  align-items: center;

  .pt-time-container {
    display: flex;
    flex-wrap: wrap;
  }

  .pt-time-item {
    margin-right: 6px;
    margin-bottom: 3px;
  }
`;

let formData = {
  mcd: "",
  cid: "",
  tdate: "",
  tcd: "",
  category: "",
  memo: "",
  coupon_seq:0,
};

const CategoryContainer = styled.div`
  display: flex;
  align-items: center;
  .label {
    width: 80px;
  }
  margin-bottom: 5px;
`;

function PtReservation() {
  const [tdate, setTdate] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [rowDate, setRowDate] = useState([]);
  const [teacher, setTeacher] = useState([]);
  const cid = GetCid();
  let { coupon_seq, pt_type } = queryString.parse(window.location.search);
  const login = useRecoilValue(loginState);

  // 데이타 : 예약
  const onLoadData = async () => {
    let result = await axios.post(
      GetHost() + "/gympass/get_reservation_date",
      {}
    );
    setRowDate(result.data);
    setLoaded(true);
  };

  // 예약 : 날짜클릭
  const onDateClick = async (params) => {
    const { date, pt_type } = params;

    let result = await axios.post(
      GetHost() + "/gympass/get_reservation_teacher",
      {
        cid: login.cid,
        tdate: date,
        pt_type: pt_type,
      }
    );
    setTdate(date);
    setTeacher(result.data);
    formData.tdate = date;
  };

  const handle_time_click = async (params) => {
    const { tcd, class_hour_seq } = params;
    formData.tcd = tcd;
    formData.class_hour_seq = class_hour_seq;
    setVisible(true);
  };

  const [opt, setOpt] = useState([]);
  const get_option = async () => {
    let result = await axios.post(GetHost() + "/gympass/get_category");
    setOpt(result.data);
  };
  useEffect(() => {
    get_option();
  }, []);

  const formRef = useRef();
  const tdateRef = useRef();
  const tcdRef = useRef();
  const hourRef = useRef();

  const categoryRef = useRef();
  const memoRef = useRef();

  const [visible, setVisible] = useState(false);
  const show_confirm = () => {
    setVisible(true);
  };

  const hide_confirm = () => {
    setVisible(false);
    memoRef.current.state.value = "";
    //categoryRef.current.state.value=''; ?
  };

  // 예약하기
  const onReservation = async () => {
    
    // 운동목적 확인
    if (formData.category === "") {
      error({ title: "오류", content: "운동목적을 선택하세요." });
      return;
    }

    formData.mcd=login.mcd;
    formData.cid=login.cid;  
    formData.coupon_seq=coupon_seq;
    formData.pt_type = pt_type;
    let result = await axios.post(GetHost() + "/gympass/reservation", formData);
    if (result.data.ret === "Y") {
      success({
        title: "성공",
        content: "예약 되었습니다.",
        onOk() {
          GoPage("my_pt", cid);
        },
      });
    } else {
      error({ title: "오류", content: result.data.msg });
      setVisible(false);
    }
  };

  useEffect(() => {
    if (login.loaded === true) {
      if (login.mcd !== "") {
        onLoadData();
      } else {
        GoPage("home", login.cid);
      }
    }
  }, [login.mcd, login.token]);

  return (
    <Layout>
      <LoginAuth />
      {loaded === false ? (
        <Loading />
      ) : (
        <Container>
          {/* <Button onClick={() => pr(formData)}>보기</Button>
          <Button onClick={() => show_confirm()}>컨펌</Button> */}

          <Modal
            visible={visible}
            title="수업예약"
            onOk={onReservation}
            onCancel={hide_confirm}
          >
            <CategoryContainer>
              <div className="label">운동목적</div>
              <div>
                <Select
                  ref={categoryRef}
                  style={{ width: 150 }}
                  defaultValue=""
                  onChange={(value) => {
                    formData.category = value;
                  }}
                >
                  <Option value="">선택</Option>
                  {opt.map((item, index) => {
                    return (
                      <Option key={index} value={item}>
                        {item}
                      </Option>
                    );
                  })}
                </Select>
              </div>
            </CategoryContainer>
            <CategoryContainer>
              <div className="label">메모</div>
              <div>
                <Input
                  ref={memoRef}
                  onChange={(event) => (formData.memo = event.target.value)}
                  style={{ width: 200 }}
                ></Input>
              </div>
            </CategoryContainer>
          </Modal>

          <ReservationTop>
            <li>
              <LeftOutlined
                style={{ fontSize: "150%", padding: 5, color: "white" }}
                onClick={() => GoPage("my_coupon", cid)}
              />
            </li>
            <li className="reservation-top">
              <TitleText>수업예약</TitleText>
            </li>
            {/* <Button onClick={() => GoPage("my_coupon", cid)}>
              BACK MYCOUPON</Button> */}
          </ReservationTop>

          <DateList>
            {rowDate.map((n, i) => {
              const date = n;
              const format_date = moment(n).format("M/D");
              const yoil = GetYoil(date);
              //pr(format_date);
              return (
                <DateItem key={i}>
                  <div className="day">{yoil}</div>
                  <Button
                    type="circle"
                    className="date"
                    onClick={() =>
                      onDateClick({ date: date, pt_type: pt_type })
                    }
                  >
                    {format_date}
                  </Button>
                </DateItem>
              );
            })}
          </DateList>

          <ReservationContainer>
            <ReservationContent>
              <SelectDate>
                {tdate === "" ? (
                  <div className="select-date">날짜를 선택하세요.</div>
                ) : (
                  <div className="selected-date">선택일자 : {tdate}</div>
                )}
              </SelectDate>

              {teacher.map((item, index) => {
                return (
                  <div key={index}>
                    <div className="trainer">{item.tname} 트레이너</div>
                    <SelectTime>
                      <div className="pt-time-container">
                        {item.time.map((item1, index1) => {
                          return (
                            <div className="pt-time-item" key={index1}>
                              {item1.count >= item1.max_count ? (
                                <Button
                                  className="pt-time-btn"
                                  disabled={true}
                                  block
                                >
                                  {item1.hour}
                                </Button>
                              ) : (
                                <Button
                                  type="primary"
                                  block
                                  className="pt-time-btn"
                                  onClick={() =>
                                    handle_time_click({
                                      tcd: item.tcd,
                                      class_hour_seq: item1.seq,
                                    })
                                  }
                                >
                                  {item1.hour}
                                </Button>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </SelectTime>
                  </div>
                );
              })}
            </ReservationContent>
          </ReservationContainer>
        </Container>
      )}
    </Layout>
  );
}

export default PtReservation;
