import React,{useEffect} from "react";
import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from "recoil";
import GetCid from "./GetCid";
import GetLogin from "./GetLogin";
import GetMcd from "./GetMcd";
import GetToken from "./GetToken";
import pr from "./Pr";
import { loginState } from "./State";

// 로그인 인증처리
function LoginAuth() {

  pr("lib --- LoginAuth()");
  const [login, setLogin] = useRecoilState(loginState);

  const start = async () => {
    const token = GetToken();
    const cid = GetCid();
    const mcd = GetMcd();
    const result = await GetLogin({ token: token, mcd: mcd, cid: cid });
    const { auth, mb_id, mb_seq } = result.data;
    setLogin((state) => {
      return {
        ...state,
        auth: auth,
        mcd: mb_id,
        mb_seq: mb_seq + "",
        cid: cid,
        token: token,
        loaded: true,
      };
    });
  };

  useEffect(() => {
    start();
  }, []);

  return null;
}

export default LoginAuth;