import React, { useState, useEffect, useRef } from "react";
import { Tag, Divider, Button } from "antd";
import "antd/dist/antd.css";
import axios from "axios";
import styled from "styled-components";
import { CloseOutlined } from "@ant-design/icons";
import moment from "moment";
import "moment/locale/ko";
import store from "../store";
import smoothscroll from "smoothscroll-polyfill";
import header_bg from "../images/header_bg.png";
import header_title from "../images/header_title.png";
import header_info from "../images/header_info.png";
import level_stone_0 from "../images/level_stone_0.png";
import level_stone_1 from "../images/level_stone_1.png";
import level_stone_2 from "../images/level_stone_2.png";
import level_stone_3 from "../images/level_stone_3.png";
import level_stone_4 from "../images/level_stone_4.png";
import level_stone_5 from "../images/level_stone_5.png";
import level_stone_6 from "../images/level_stone_6.png";
import level_stone_7 from "../images/level_stone_7.png";
import level_stone_8 from "../images/level_stone_8.png";
import level_stone_9 from "../images/level_stone_9.png";
import level_stone_10 from "../images/level_stone_10.png";
import level_name_0 from "../images/level_name_0.png";
import level_name_1 from "../images/level_name_1.png";
import level_name_2 from "../images/level_name_2.png";
import level_name_3 from "../images/level_name_3.png";
import level_name_4 from "../images/level_name_4.png";
import level_name_5 from "../images/level_name_5.png";
import level_name_6 from "../images/level_name_6.png";
import level_name_7 from "../images/level_name_7.png";
import level_name_8 from "../images/level_name_8.png";
import level_name_9 from "../images/level_name_9.png";
import level_name_10 from "../images/level_name_10.png";
import level_box_0 from "../images/level_box_0.png";
import level_box_1 from "../images/level_box_1.png";
import level_box_2 from "../images/level_box_2.png";
import level_box_3 from "../images/level_box_3.png";
import level_box_4 from "../images/level_box_4.png";
import level_box_5 from "../images/level_box_5.png";
import level_box_6 from "../images/level_box_6.png";
import level_box_7 from "../images/level_box_7.png";
import level_box_8 from "../images/level_box_8.png";
import level_box_9 from "../images/level_box_9.png";
import level_box_10 from "../images/level_box_10.png";
import stamp_black from "../images/stamp_black.png";
import stamp_color from "../images/stamp_color.png";
import stamp_line_black from "../images/stamp_line_black.png";
import stamp_line_color from "../images/stamp_line_color.png";
import stamp_big_black_1 from "../images/stamp_big_black_1.png";
import stamp_big_black_2 from "../images/stamp_big_black_2.png";
import stamp_big_black_3 from "../images/stamp_big_black_3.png";
import stamp_big_black_4 from "../images/stamp_big_black_4.png";
import stamp_big_black_5 from "../images/stamp_big_black_5.png";
import stamp_big_black_6 from "../images/stamp_big_black_6.png";
import stamp_big_black_7 from "../images/stamp_big_black_7.png";
import stamp_big_black_8 from "../images/stamp_big_black_8.png";
import stamp_big_black_9 from "../images/stamp_big_black_9.png";
import stamp_big_black_10 from "../images/stamp_big_black_10.png";
import stamp_big_color_1 from "../images/stamp_big_color_1.png";
import stamp_big_color_2 from "../images/stamp_big_color_2.png";
import stamp_big_color_3 from "../images/stamp_big_color_3.png";
import stamp_big_color_4 from "../images/stamp_big_color_4.png";
import stamp_big_color_5 from "../images/stamp_big_color_5.png";
import stamp_big_color_6 from "../images/stamp_big_color_6.png";
import stamp_big_color_7 from "../images/stamp_big_color_7.png";
import stamp_big_color_8 from "../images/stamp_big_color_8.png";
import stamp_big_color_9 from "../images/stamp_big_color_9.png";
import stamp_big_color_10 from "../images/stamp_big_color_10.png";
import reward_box_black from "../images/reward_box_black.png";
import reward_box_color from "../images/reward_box_color.png";
import level_bar_bg from "../images/level_bar_bg.png";
import level_gage_1 from "../images/level_gage_1.svg";
import level_gage_2 from "../images/level_gage_2.png";
import info_close from "../images/info_close.png";
import level_close from "../images/level_close.png";
import level_title from "../images/level_title.png";
import level_title_1 from "../images/level_title_1.png";
import level_title_2 from "../images/level_title_2.png";
import level_title_3 from "../images/level_title_3.png";
import level_title_4 from "../images/level_title_4.png";
import level_title_5 from "../images/level_title_5.png";
import level_title_6 from "../images/level_title_6.png";
import level_title_7 from "../images/level_title_7.png";
import level_title_8 from "../images/level_title_8.png";
import level_title_9 from "../images/level_title_9.png";
import level_title_10 from "../images/level_title_10.png";
import coupon from "../images/coupon.png";
import reward from "../images/reward.png";
import checked from "../images/checked.png";
import { startOfDay } from "date-fns";
import GetHost from "../lib/GetHost";
import BackBtn from "./BackBtn";
import GoPage from "../lib/GoPage";
import { LeftOutlined } from "@ant-design/icons";

let level_stone_image = level_stone_0;
let level_name = level_name_0;
let active_level = [];
let level_up_class = "";
let top = 0;
let unit_vw = window.innerWidth / 100;
let rewardData = [];
smoothscroll.polyfill();

const Container = styled.div`
  position: relative;
  background-color: #f7fbbe !important;
  height: 670vw;
  & * {
    margin: 0;
    padding: 0;
  }
`;

const HeaderStyle = styled.div`
  text-align: center;
  color: black;
  background-color: #faf9d0;

  & .back-icon {
    position: fixed;
    top: 10px;
    left: 10px;
    font-size: 22px;
    z-index: 999;
    padding: 10px;
  }
`;

const HeaderContainer = styled.div`
  /* position: fixed; */
  z-index: 99;

  & .background-image {
    width: 100%;
    z-index : 10;
    /* position : fixed; */
    top: 0;
    left: 0;
  }

  & .title-image {
    position: absolute;
    width: 34vw;
    left: 8.5vw;
    top: 4.7vw;
  }

  & .info-container {
    position: absolute;
    top: 4.7vw;
    left: 86vw;
    z-index: 999;
    padding: 10px;
  }

  & .info-container img {
    width: 7vw;
  }

  & .level-container {
    position: absolute;
    top: 11.7vw;
    left: 50vw;
  }

  & .level-container .level-stone-image {
    width: 13.5vw;
    position: absolute;
    z-index: 10;
  }

  & .level-container .level-bar-container {
    position: absolute;
    top: 0.2vw;
    left: 6.2vw;
  }

  & .level-container .level-bar-container .level-bar-bg {
    width: 36.8vw;
    margin-top: 3.4vw;
  }

  & .level-gage-container {
    /* border : 2px solid #ff0; */
    position: absolute;
    top: 3.9vw;
    left: 7vw;
    display: flex;
  }

  & .level-gage-bar {
    height: 7vw;
    /* width : 7vw; */
    background-image: url(${level_gage_1});
    background-size: auto 100%;
    /* animation: growGage 1s linear; */
  }

  & .level-gage-bar-0.Y {
    width: 27.5vw;
    animation: growGage0 1s linear;
  }

  & .level-gage-bar-1 {
    width: 5.5vw;
    animation: growGage1 1s linear;
  }

  & .level-gage-bar-2 {
    width: 11vw;
    animation: growGage2 1s linear;
  }

  & .level-gage-bar-3 {
    width: 16.5vw;
    animation: growGage3 1s linear;
  }

  & .level-gage-bar-4 {
    width: 22vw;
    animation: growGage4 1s linear;
  }

  & .level-gage-bar.level-0 {
    width: 0;
    animation: none;
  }

  & .level-gage-bar.level-50 {
    width: 27.5vw !important;
    animation: none;
  }

  & .level-gage-bar.level-up.Y {
    width: 0;
    animation: growGageLevel 1s linear;
  }

  @keyframes growGage0 {
    0% {
      width: 0vw;
    }
    100% {
      width: 27.5vw;
    }
  }

  @keyframes growGage1 {
    0% {
      width: 0;
    }
    100% {
      width: 5.5vw;
    }
  }

  @keyframes growGage2 {
    0% {
      width: 0;
    }
    100% {
      width: 11vw;
    }
  }

  @keyframes growGage3 {
    0% {
      width: 0;
    }
    100% {
      width: 16.5vw;
    }
  }

  @keyframes growGage4 {
    0% {
      width: 0;
    }
    100% {
      width: 22vw;
    }
  }

  @keyframes growGageLevel {
    0% {
      width: 27.5vw;
      opacity: 0.5;
    }
    100% {
      width: 0vw;
    }
  }

  & .level-gage-2 {
    height: 7vw;
  }

  & .level-name-container {
    position: absolute;
    width: 29.6vw;
    text-align: center;
    height: 7vw;
    line-height: 7vw;
  }

  & .level-name {
    height: 3.5vw;
  }
`;

const BodyContainer = styled.div`
  /* margin : 37.5vw 8.5vw 0 8.5vw; */
  display: flex;
  flex-direction: column;
  align-items: center;

  & .level-box {
    width: 81.5vw;
    margin-bottom: 3.1vw;
  }

  & .level-box-container {
    position: relative;
  }

  & .inner-container {
    position: absolute;
    top: 9.3vw;
    left: 2.3vw;
  }

  & .stamp-container {
    height: 8.5vw;
    position: relative;
    margin-left: 4.2vw;
  }

  & .image-container {
    display: inline-block;
    position: absolute;
    width: 5.3vw;
    height: 5.3vw;
  }

  & .stamp {
    width: 8.5vw;
    z-index: 10;
    position: absolute;
    margin-left: -4.2vw;
  }

  & .stamp1 {
    /* animation: showHide 0.5s 1s 4 ease-in; */
  }

  & .stamp3 {
    /* animation: showHide 0.5s 1s 4; */
  }

  & .stamp5 {
    /* animation: showHide 0.5s 2s 1; */
  }

  & .stamp7 {
    /* animation: showHide 0.5s 1s 4; */
  }

  & .stamp-big {
    /* animation: showHide 1s 0.8s 4; */
    /* animation:spin 1s linear 2 alternate; */
  }

  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(100deg);
    }
  }
  @keyframes showHide {
    0% {
      transform: scale(0.8);
    }
    100% {
      transform: scale(1.5);
    }
  }

  & .stamp-line {
    width: 14.5vw;
    vertical-align: top;
    margin-top: 3.9vw;
  }

  & .stamp-line-color {
    margin-top: 3.3vw;
  }

  & .stamp-big {
    width: 16.5vw;
    margin-top: -4vw;
    margin-left: -4.2vw;
  }

  & .reward-container {
    position: relative;
  }

  & .reward-box-image {
    width: 75.3vw;
    margin-top: 3.1vw;
  }

  & .reward-container .reward-inner-container {
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    width: 100%;
    color: #000000;
  }

  & .reward-container .reward-inner-container .coupon-image {
    display: inline-block;
    width: 23.7vw;
    margin-top: 12.5vw;
    margin-bottom: 1vw;
  }

  & .reward-container .reward-inner-container .reward-image {
    display: inline-block;
    width: 13.4vw;
    margin-bottom: 1vw;
  }

  & .reward-container .reward-inner-container .item {
    display: inline-block;
  }

  & .reward-container .reward-inner-container .item-between {
    display: inline-block;
    width: 17.1vw;
  }

  & .coupon_checked {
    display: none;
    width: 7.5vw;
  }

  & .reward_checked {
    display: none;
    width: 7.5vw;
  }

  & .coupon_checked.checked {
    display: block;
  }

  & .reward_checked.checked {
    display: block;
  }

  & .reward1 .coupon_checked {
    position: absolute;
    top: 8.5vw;
    left: 3.9vw;
  }

  & .reward1 .reward_checked {
    position: absolute;
    top: 8.5vw;
    right: 23.5vw;
  }

  & .reward2 .coupon_checked {
    position: absolute;
    top: 9.3vw;
    left: 19.5vw;
  }
`;

const StampInfoContainer = styled.div`
  position: fixed;
  top: 150px;
  left: 15%;
  right: 15%;
  background-color: white;
  padding: 13px;
  box-sizing: border-box;
  border-radius: 18px;
  border: 4px solid #8e8e8e;
  color: black;
  z-index: 999;

  & .inner-wrap {
    position: relative;
  }
  & h3 {
    color: #b1365e;
    font-size: 16px;
    font-weight: 700;
  }

  & .close-icon {
    position: absolute;
    top: -10px;
    right: -5px;
  }

  & .close-icon img {
    width: 15px;
  }

  & ul {
    list-style: none;
  }
`;

const LevelInfoContainer = styled.div`
  position: fixed;
  top: 200px;
  left: 50%;
  margin-left: -40%;
  width: 80vw;
  height: 80vw;
  box-sizing: border-box;
  background: ${(props) => props.background};
  padding: 21.25vw 13px;
  box-sizing: border-box;
  border-radius: 18px;
  border: 4px solid #8e8e8e;
  text-align: center;
  z-index: 9999;

  & .inner-wrap {
    position: relative;
  }

  & .close-icon {
    position: absolute;
    top: -22.5vw;
    right: -2.5vw;
    padding: 10px;
  }

  & .close-icon img {
    width: 15px;
  }

  & h3 {
    margin-bottom: 7px;
  }

  & p {
    color: white;
    font-size: 4vw;
    line-height: 1.2;
  }

  & .title {
    width: 120px;
  }

  & .title-level {
    height: 20px;
  }
`;

const InfoContainer = styled.div`
  position: fixed;
  top: 50px;
  bottom: 50px;
  left: 10%;
  right: 10%;
  background-color: white;
  padding: 13px;
  box-sizing: border-box;
  border-radius: 18px;
  border: 4px solid #8e8e8e;
  color: black;
  z-index: 999;

  & .inner-wrap {
    position: relative;
    height: 100%;
    overflow-y: scroll;
    word-break: keep-all;
  }
  & h3 {
    color: #b1365e;
    font-size: 16px;
    font-weight: 700;
  }

  & .close-icon {
    position: absolute;
    top: 0px;
    right: 0px;
    padding: 10px;
  }

  & .close-icon img {
    width: 4.6vw;
  }

  & ul {
    list-style: none;
  }
`;

const BKBackground = styled.div`
  width: 100%;
  height: 100%;
  background-color: #00000080;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9990;
`;

const levelInfoData = [
  {
    id: 0,
    name: "베이직",
    ename: "BASIC",
    title: level_name_0,
    popup_title: level_title_1,
    stone_image: level_stone_0,
    background: "#605351",
    top: 0,
  },
  {
    id: 1,
    name: "아이언",
    ename: "IRON",
    title: level_name_1,
    popup_title: level_title_1,
    stone_image: level_stone_1,
    background: "#605351",
    level_box_image: level_box_1,
    stamp_big: [stamp_big_black_1, stamp_big_color_1],
    coupon: "5% 할인권",
    reward: "N",
    top: 60.9 * unit_vw * 1 + 2.5 * unit_vw,
  },
  {
    id: 2,
    name: "브론즈",
    ename: "BRONZE",
    title: level_name_2,
    popup_title: level_title_2,
    stone_image: level_stone_2,
    background: "#be573e",
    level_box_image: level_box_2,
    stamp_big: [stamp_big_black_2, stamp_big_color_2],
    coupon: "10% 할인권",
    reward: "N",
    top: 60.9 * unit_vw * 2 + 2.5 * unit_vw,
  },
  {
    id: 3,
    name: "실버",
    ename: "SILVER",
    title: level_name_3,
    popup_title: level_title_3,
    stone_image: level_stone_3,
    background: "#90a4a8",
    level_box_image: level_box_3,
    stamp_big: [stamp_big_black_3, stamp_big_color_3],
    coupon: "15% 할인권",
    reward: "N",
    top: 60.9 * unit_vw * 3 + 2.5 * unit_vw,
  },
  {
    id: 4,
    name: "골드",
    ename: "GOLD",
    title: level_name_4,
    popup_title: level_title_4,
    stone_image: level_stone_4,
    background: "#e39049",
    level_box_image: level_box_4,
    stamp_big: [stamp_big_black_4, stamp_big_color_4],
    coupon: "20% 할인권",
    reward: "Y",
    top: 60.9 * unit_vw * 4 + 2.5 * unit_vw,
  },
  {
    id: 5,
    name: "플레티넘",
    ename: "PLATINUM",
    title: level_name_5,
    popup_title: level_title_5,
    stone_image: level_stone_5,
    background: "#577e7b ",
    level_box_image: level_box_5,
    stamp_big: [stamp_big_black_5, stamp_big_color_5],
    coupon: "25% 할인권",
    reward: "N",
    top: 60.9 * unit_vw * 5 + 2.5 * unit_vw,
  },
  {
    id: 6,
    name: "다이아몬드",
    ename: "DIAMOND",
    title: level_name_6,
    popup_title: level_title_6,
    stone_image: level_stone_6,
    background: "#798ab5",
    level_box_image: level_box_6,
    stamp_big: [stamp_big_black_6, stamp_big_color_6],
    coupon: "30% 할인권",
    reward: "Y",
    top: 60.9 * unit_vw * 6 + 2.5 * unit_vw,
  },
  {
    id: 7,
    name: "마스터",
    ename: "MASTER",
    title: level_name_7,
    popup_title: level_title_7,
    stone_image: level_stone_7,
    background: "#754a89",
    level_box_image: level_box_7,
    stamp_big: [stamp_big_black_7, stamp_big_color_7],
    coupon: "35% 할인권",
    reward: "N",
    top: 60.9 * unit_vw * 7 + 2.5 * unit_vw,
  },
  {
    id: 8,
    name: "그랜드 마스터 ",
    ename: "GRAND MASTER",
    title: level_name_8,
    popup_title: level_title_8,
    stone_image: level_stone_8,
    background: "#cb3146",
    level_box_image: level_box_8,
    stamp_big: [stamp_big_black_8, stamp_big_color_8],
    coupon: "40% 할인권",
    reward: "Y",
    top: 60.9 * unit_vw * 8 + 2.5 * unit_vw,
  },
  {
    id: 9,
    name: "챌린저",
    ename: "CHALLENGER",
    title: level_name_9,
    popup_title: level_title_9,
    stone_image: level_stone_9,
    background: "#51a1ae",
    level_box_image: level_box_9,
    stamp_big: [stamp_big_black_9, stamp_big_color_9],
    coupon: "45% 할인권",
    reward: "N",
    top: 60.9 * unit_vw * 9 + 2.5 * unit_vw,
  },
  {
    id: 10,
    name: "레전드",
    ename: "LEGEND",
    title: level_name_10,
    popup_title: level_title_10,
    stone_image: level_stone_10,
    background:
      "linear-gradient(135deg, rgba(234,83,82,1) 0%, rgba(184,54,98,1) 50%, rgba(121,32,112,1) 100%)",
    level_box_image: level_box_10,
    stamp_big: [stamp_big_black_10, stamp_big_color_10],
    coupon: "50% 할인권",
    reward: "Y",
    top: 60.9 * unit_vw * 10 + 2.5 * unit_vw,
  },
];

let stampData = [
  { id: 1, type: "stamp", stampId: 1 },
  { id: 2, type: "line", stampId: 2 },
  { id: 3, type: "stamp", stampId: 2 },
  { id: 4, type: "line", stampId: 3 },
  { id: 5, type: "stamp", stampId: 3 },
  { id: 6, type: "line", stampId: 4 },
  { id: 7, type: "stamp", stampId: 4 },
  { id: 8, type: "line", stampId: 5 },
  { id: 9, type: "stamp_big", stampId: 5 },
];

const StampInfo = ({ closePopup, id, stampInfoData, level }) => {
  let tname = "";
  let class_date = "";
  let exp_date = "";

  stampInfoData.map((item, index) => {
    let num = index + 1;
    if (num === id) {
      tname = item.tname;
      class_date = moment(item.class_date).format("YYYY.MM.DD (ddd)");
      exp_date = moment(item.exp_date).format("YYYY.MM.DD");
    }
  });
  return (
    <BKBackground>
      <StampInfoContainer>
        <div className="inner-wrap">
          <h3>스탬프 정보</h3>
          <div className="close-icon" onClick={closePopup}>
            <img src={info_close} />
          </div>
          <ul>
            {tname === "" ? <></> : <li>트레이너 : {tname} 트레이너</li>}
            <li>수업일 : {class_date}</li>
            <li>유효기간 : {exp_date} 까지</li>
          </ul>
        </div>
      </StampInfoContainer>
    </BKBackground>
  );
};

const LevelInfo = ({ closePopup, level }) => {
  let level_title_num = "";
  let name = "";
  let background = "";
  levelInfoData.map((item, index) => {
    let num = item.id * 5;
    if (level === num) {
      level_title_num = item.popup_title;
      name = item.name;
      background = item.background;
    }
  });

  if (level == 0) {
    return false;
  }
  if (level % 5 !== 0) {
    return false;
  }

  return (
    <BKBackground>
      <LevelInfoContainer background={background}>
        <div className="inner-wrap">
          <div className="close-icon" onClick={closePopup}>
            <img src={level_close} />
          </div>
          <h3>
            <img src={level_title} className="title" />
            <br />
            <img src={level_title_num} className="title-level" />
          </h3>
          <p>짝짝짝! 축하합니다!</p>
          <p>{name} 레벨을 획득하셨습니다.</p>
        </div>
      </LevelInfoContainer>
    </BKBackground>
  );
};

const Info = ({ closePopup }) => {
  return (
    <BKBackground>
      <InfoContainer>
        <div className="close-icon" onClick={closePopup}>
          <img src={info_close} />
        </div>
        <div className="inner-wrap">
          <h1>프리퀀시 안내</h1>
          <ul>
            <li>
              <h3>프리퀀시 란?</h3>
              <p>
                일주일 한번 PT를 받고 목표 스탬프를 완료하면 할인권과 상품을
                받을 수 있고 해당 목표치마다 각각의 레벨을 부여받으면서 운동의
                즐거움과 목표 레벨을 달성하는 성취감까지 느낄 수 있는 시스템
                제도입니다.
              </p>
            </li>
            <li>
              <h3>프리퀀시 맴버쉽 등급 제도</h3>
              <p>VIP1 : 헬스 1개월 등록한 회원</p>
              <p>VIP3 : 헬스 3개월 등록한 회원</p>
              <p>VIP6 : 헬스 6개월 등록한 회원</p>
            </li>
            <li>
              <h3>맴버쉽 쿠폰 안내</h3>
              <h4>슈퍼 쿠폰</h4>
              <p>
                24시간 전 무료 1:1헬스가이드티칭 수업을 언제든지 예약 가능한
                쿠폰입니다.
              </p>
              <p>
                본 쿠폰은 헬스 등록시 전 맴버쉽 등급회원 (VIP1, VIP3, VIP6)에게
                무료로 3장을 지급합니다.
              </p>
              <h4>위클리 쿠폰</h4>
              <p>
                일주일 전(토요일 자정 전)까지 다음주 무료 1:1 헬스가이드티칭
                수업을 예약가능한 쿠폰입니다.
              </p>
              <p>
                본 쿠폰은 VIP1, VIP3 맴버에게 매주 1장씩 무료로 지급합니다.
                (일주일에 한장씩 일요일 자정에 어플로 자동 지급)
              </p>
              <p>
                VIP6 회원은 이벤트 행사 시 기원자에 한 해 무료로 지급합니다.
                (지원은 트레이너에게 직접 지원)
              </p>
            </li>
            <li>
              <h3>프리퀀시 스탬프 획득 방법 안내</h3>
              <ol>
                <li>
                  피지컬베이스 앱을 통해 1주일 전 1:1 헬스가이드티칭 사전예약을
                  신청합니다.
                </li>
                <li>
                  예약한 시간에 담당트레이너와 함께 1:1 헬스가이드티칭을
                  받습니다.
                </li>
                <li>
                  티칭 후 운동결과에 대한 평가를 진행하고 프리퀀시 스탬프를 지급
                  받습니다.
                </li>
                <li>
                  프리퀀시 목표에 달성 시 프리퀀시 레벨과 상품을 지급 받습니다.
                </li>
              </ol>
            </li>
            <li>
              <h3>프리퀀시 이용 주의사항</h3>
              <ol>
                <li>
                  1:1 헬스가이드티칭 예약 변경 시 하루 전 주1회에 한해서만
                  변경이 가능합니다. (담당트레이너에게 요청)
                </li>
                <li>
                  평가를 하지 않는 경우 (수업 종료 후 3시간 이내) 프리퀀시
                  스탬프를 지급 받지 못합니다.
                </li>
              </ol>
            </li>
          </ul>
        </div>
      </InfoContainer>
    </BKBackground>
  );
};

const LevelBox = ({ boxLevel, level, openStampInfo }) => {
  let stamp_image = "";
  let stamp_class = "";
  let level_box_image = level_box_0;
  let reward_image = reward_box_black;
  let box_level_number = boxLevel + 1;
  let stamp_big = [];
  let is_reward = false;
  let coupon_data = "";
  let reward_check = "";

  levelInfoData.map((i, idx) => {
    if (box_level_number == i.id) {
      level_box_image = i.level_box_image;
      stamp_big = i.stamp_big;
      coupon_data = i.coupon;
      if (i.reward === "Y") {
        is_reward = true;
      }
    }
  });

  rewardData.map((i, idx) => {
    if (box_level_number == i.level) {
      if (i.level_reward > 0) {
        reward_check = "checked";
        reward_image = reward_box_color;
      }
    }
  });

  stampData.map((item, index) => {
    let stamp_level = item.stampId + 5 * boxLevel;
    if (stamp_level <= level) {
      active_level[boxLevel] = true;
      if (level % 5 === 0 && level !== 0) {
        active_level[boxLevel + 1] = true;
      }
    }
  });

  return (
    <div className="level-box-container">
      <a name={boxLevel}></a>
      {active_level[boxLevel] ? (
        <img src={level_box_image} className="level-box" />
      ) : (
        <img src={level_box_0} className="level-box" />
      )}
      <div className="inner-container">
        <div className="stamp-container">
          {stampData.map((item, index) => {
            let stamp_level = item.stampId + 5 * boxLevel;
            let num = index + 1;
            if (stamp_level <= level) {
              if (item.type === "stamp") {
                stamp_image = stamp_color;
                stamp_class = "stamp stamp" + num;

                return (
                  <div
                    onClick={() => openStampInfo(stamp_level)}
                    key={index}
                    className="image-container"
                    key={index}
                  >
                    <img src={stamp_image} className={stamp_class} />
                  </div>
                );
              }
              if (item.type === "line") {
                stamp_image = stamp_line_color;
                stamp_class = "stamp-line stamp-line-color stamp-line-" + num;

                return <img src={stamp_image} className={stamp_class} />;
              }
              if (item.type === "stamp_big") {
                stamp_image = stamp_big[1];
                stamp_class = "stamp-big";

                return (
                  <div
                    onClick={() => openStampInfo(stamp_level)}
                    key={index}
                    className="image-container"
                    key={index}
                  >
                    <img src={stamp_image} className={stamp_class} />
                  </div>
                );
              }
            } else {
              if (item.type === "stamp") {
                stamp_image = stamp_black;
                stamp_class = "stamp stamp" + num;
              }
              if (item.type === "line") {
                stamp_image = stamp_line_black;
                stamp_class = "stamp-line stamp-line-black stamp-line-" + num;
              }
              if (item.type === "stamp_big") {
                stamp_image = stamp_big[0];
                stamp_class = "stamp-big";
              }

              return (
                <img src={stamp_image} className={stamp_class} key={index} />
              );
            }
          })}
        </div>
        <div className="reward-container">
          <img src={reward_image} className="reward-box-image" />
          {is_reward ? (
            <div className="reward-inner-container reward1">
              <div className="item">
                <img src={coupon} className="coupon-image" />
                <div>{coupon_data}</div>
                <img
                  src={checked}
                  className={"coupon_checked " + reward_check}
                />
              </div>
              <div className="item-between"></div>
              <div className="item">
                <img src={reward} className="reward-image" />
                <div>고급 티셔츠</div>
                <img
                  src={checked}
                  className={"reward_checked " + reward_check}
                />
              </div>
            </div>
          ) : (
            <div className="reward-inner-container reward2">
              <img src={coupon} className="coupon-image" />
              <div>{coupon_data}</div>
              <img src={checked} className={"coupon_checked " + reward_check} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default function (props) {
  const [showStampInfo, setShowStampInfo] = useState(false);
  const [showLevelInfo, setShowLevelInfo] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [stampId, setStampId] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [stampInfoData, setStampInfoData] = useState([]);
  const [level, setLevel] = useState(0);

  // const loaded = false;

  const openStampInfo = (stamp_level) => {
    setShowStampInfo(true);
    setStampId(stamp_level);
  };

  const closeStampInfoPopup = () => {
    setShowStampInfo(false);
  };

  const closeLevelInfoPopup = () => {
    levelInfoData.map((item, index) => {
      let mok = parseInt(level / 5);
      if (mok === item.id) {
        level_name = item.title;
        level_stone_image = item.stone_image;
        level_up_class = "level-up";
        setTimeout(() => {
          window.scrollTo({ behavior: "smooth", top: item.top });
        }, 1000);
      }
    });
    setShowLevelInfo(false);
  };

  const closeInfoPopup = () => {
    setShowInfo(false);
  };

  const moveInfo = () => {
    // window.location.href = '/hg_frequency_info';
    setShowInfo(true);
  };

  const close_webview_window = () => {
    if (window.ReactNativeWebView !== undefined) {
      window.ReactNativeWebView.postMessage("close_webview_window");
    }
  };

  const close_click = () => {
    if (window.ReactNativeWebView === undefined) {
      alert("앱용 기능입니다.");
      return;
    }
    close_webview_window();
  };

  // useEffect(() => {
  //     if(props.isRate === 'Y'){
  //         setTimeout(()=> {
  //             setShowLevelInfo(true);
  //         }, 2000)
  //     }
  // }, []);

  // useEffect(() => {
  //     (async function () {
  //         const { mcd, cid } = props.login;
  //         let api_host = store.production.api_host;
  //         if( process.env.REACT_APP_ENV === 'dev' ) api_host = store.development.api_host;
  //         if( process.env.REACT_APP_ENV === 'staging' ) api_host = store.staging.api_host;

  //         // 01.Frequency list
  //         const result = await axios.post(api_host + '/fq/get_frequency_list', {
  //             mcd: mcd,
  //             cid: cid
  //         });
  //         console.log(result.data.rows)
  //         setStampInfoData(result.data.rows);
  //         // let temp_level = 42;
  //         if(result.data.rows){
  //             setLevel(result.data.rows.length);
  //             // setLevel(temp_level)
  //         }
  //         // let mok = parseInt(temp_level/5);
  //         let mok = parseInt(result.data.rows.length/5);
  //         levelInfoData.map((item, index) => {
  //             if(result.data.rows.length%5 === 0 && result.data.rows.length !== 0 && props.isRate === 'Y'){
  //                 if(mok === item.id+1){
  //                     level_name = item.title;
  //                     level_stone_image = item.stone_image;
  //                     top = item.top;
  //                 }
  //             }else{
  //                 if(mok === item.id){
  //                     level_name = item.title;
  //                     level_stone_image = item.stone_image;
  //                     top = item.top;
  //                 }
  //             }
  //         })

  //         // reward data
  //         const result2 = await axios.post(api_host + '/fq/get_level_reward', {
  //             mcd: mcd,
  //             cid: cid
  //         });
  //         if(result2.data){
  //             rewardData = result2.data;
  //         }

  //         setTimeout(()=>{
  //             window.scrollTo({ behavior: 'smooth', top: top })
  //         }, 1000)
  //         setLoaded(true);
  //     })();
  // }, []);

  // useEffect(() => {
  //     const imgList = [header_bg, header_title, header_info, level_bar_bg, level_box_0, level_box_1, level_box_2, level_box_3, level_box_4, level_box_5, level_box_6, level_box_7, level_box_8, level_box_9, level_box_10]
  //     imgList.map((n,i)=>{
  //         new Image().src = n;
  //       });
  //     setLoading(true);
  // }, []);

  const start = async () => {
    // axios 쓸려면 async()
    console.log("xyz");
    const mcd = "APP00002968";
    const cid = "CT17X10111035.huwy5";

    const result = await axios.post(GetHost() + "/fq/get_frequency_list", {
      mcd: mcd,
      cid: cid,
    });
    console.log(result.data.rows);

    // 리턴값을 유즈스테이트에 넣기
    // 스탬프표시
    setLevel(result.data.rows.length);
    // 선생님 정보 나타남
    setStampInfoData(result.data.rows);
  };

  useEffect(() => {
    setLoaded(true);
    setLoading(true);
    start();
  }, []);

  const onButtonClick = () => {
    setShowLevelInfo(true);
  };

  // const result2 = async () => {

  //   const mcd = "APP00002968";
  //   const cid = "CT17X10111035.huwy5";
  //   const result2 = await axios.post(api_host + "/fq/get_level_reward", {
  //     mcd: mcd,
  //     cid: cid,
  //   });
  //   if (result2.data) {
  //     rewardData = result2.data;
  //   }
  // };

  //         setTimeout(()=>{
  //             window.scrollTo({ behavior: 'smooth', top: top })
  //         }, 1000)
  //         setLoaded(true);
  //     })();
  // }, []);

  return (
    <Container>
      {loaded === true && loading === true ? (
        <>
          <HeaderStyle>
            <LeftOutlined
              className="back-icon"
              onClick={() => GoPage("logged")}
            />
          </HeaderStyle>
          <HeaderContainer>
            <img src={header_bg} className="background-image" />
            <img src={header_title} className="title-image" />
            <div className="info-container" onClick={() => moveInfo()}>
              <img src={header_info} />
            </div>
            <div className="level-container">
              <img src={level_stone_image} className="level-stone-image" />
              <div className="level-bar-container">
                <img src={level_bar_bg} className="level-bar-bg" />
                <div className="level-gage-container">
                  <div
                    className={
                      "level-gage-bar level-gage-bar-" +
                      (level % 5) +
                      " level-" +
                      level +
                      " " +
                      level_up_class +
                      " " +
                      props.isRate
                    }
                  ></div>
                  <img src={level_gage_2} className="level-gage-2" />
                  <div className="level-name-container">
                    <img src={level_name} className="level-name" />
                  </div>
                </div>
              </div>
            </div>
          </HeaderContainer>
          <BodyContainer>
            <LevelBox
              boxLevel={0}
              level={level}
              openStampInfo={openStampInfo}
            />
            <LevelBox
              boxLevel={1}
              level={level}
              openStampInfo={openStampInfo}
            />
            <LevelBox
              boxLevel={2}
              level={level}
              openStampInfo={openStampInfo}
            />
            <LevelBox
              boxLevel={3}
              level={level}
              openStampInfo={openStampInfo}
            />
            <LevelBox
              boxLevel={4}
              level={level}
              openStampInfo={openStampInfo}
            />
            <LevelBox boxLevel={5} level={level} penStampInfo={openStampInfo} />
            <LevelBox boxLevel={6} level={level} penStampInfo={openStampInfo} />
            <LevelBox
              boxLevel={7}
              level={level}
              openStampInfo={openStampInfo}
            />
            <LevelBox
              boxLevel={8}
              level={level}
              openStampInfo={openStampInfo}
            />
            <LevelBox
              boxLevel={9}
              level={level}
              openStampInfo={openStampInfo}
            />
          </BodyContainer>
          {showStampInfo ? (
            <StampInfo
              closePopup={closeStampInfoPopup}
              id={stampId}
              stampInfoData={stampInfoData}
              level={level}
            />
          ) : null}
          {showLevelInfo ? (
            <LevelInfo closePopup={closeLevelInfoPopup} level={level} />
          ) : null}
          {showInfo ? <Info closePopup={closeInfoPopup} /> : null}
        </>
      ) : (
        <></>
      )}
    </Container>
  );
}
