import React, { Component } from "react";
import styled from 'styled-components'

const MySpan = styled.div`
  background: #f8f8f8;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

function Loading() {
    return (
      <MySpan>
        <div>
          <div>Loading...</div>
        </div>
      </MySpan>
    );
  }

export default Loading;
