import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Tag, Divider, Button } from "antd";
import "antd/dist/antd.css";
import logo from "../img/M_logo_new.jpg";
import Column from "rc-table/lib/sugar/Column";
import { FiChevronDown } from "react-icons/fi";
import GoPage from "../lib/GoPage";
import { MenuOutlined } from "@ant-design/icons";
import { Carousel } from "antd";
import { Drawer } from "antd";
import { Menu, Dropdown, Space } from "antd";


const TopContent = styled.ul`
  display: flex;
  background-color: #264a7b;
  align-items: center;
  margin-bottom: 0;
  padding: 10px 0;
  li {
    text-align: center;
    display: block;
    list-style: none;
  }
  .center-area {
    flex: 1;
  }
  .left-area,
  .right-area {
    width: 50px;
  }
`;
const SecondContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .mobile-second-content {
    width: 80%;
  }
  .mobile-second-title {
    color: #3f968e;
    font-size: 18px;
  }
  .mobile-second-text {
    color: #2f2725;
    text-decoration-line: underline;
    text-underline-position: under;
    font-size: 14px;
  }
  .middle-text {
    display: flex;
    justify-content: center;
  }
`;
const PropConatainer = styled.div`
  .prop-title {
    display: flex;
    justify-content: center;
    color: #264a7b;
    font-size: 30px;
  }
  button {
    background: #3f968e;
    color: #fff;
    border: none;
    width: 100%;
    height: 60px;
    font-size: 20px;
    display: flex;
    align-items: center;
    /* text-align: center; */
    justify-content: center;
  }
`;
const ContentStyle = styled.div`
  width: 100%;
`;

const CourseContainer = styled.div`
  .courseInfoContainer {
  }
  .courseInfoContent {
    display: flex;
    justify-content: center;
    text-align: center;
  }

  .courseInfoLeft {
    display: flex;
    width: 80%;
    font-size: 16px;
    flex-direction: column;
    justify-content: center;
    /* letter-spacing: 1px; */
  }
  .courseInfoTitle {
    color: #274c7e;
    font-size: 30px;
    font-weight: bold;
  }
  .courseTitle {
    color: #274c7e;
    font-size: 22px;
    margin-top: 20px;
  }

  .courseButton {
    background: #3f968e;
    color: #fefefe;

    border: none;
    cursor: pointer;
  }
  .blackBold {
    font-weight: bold;
  }
  .fontRed {
    color: #e60012;
  }
  .fontBlue {
    color: #274c7e;
  }
  .fontYellow {
    color: #fff100;
  }
  .courseButton {
    background: #3f968e;
    color: #fefefe;
    width: 320px;
    height: 50px;
    border: none;
    font-size: 32px;
    cursor: pointer;
    a {
      color: #fff;
    }
  }
`;
const TelNumberContainer = styled.div`
  background: #3f968e;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fefefe;
  .telNumberContent {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
  }
  .fontYellow {
    color: #fff100;
  }
`;
const FooterLogoContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 134px;
  align-items: center;
  background: #274c7e;
  width: 100%;
  .footerLogoContent {
    display: flex;
    align-items: center;
  }
  img {
    width: 158px;
    height: 35px;
  }
`;
const DrawerConainer = styled.div`
  a {
    text-decoration: none !important;
  }
  .drawer-font {
    font-size: 70px;
  }
`;

function DrawerMenu() {
  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const click_inquiry = () => {
    window.open(
      "https://docs.google.com/forms/d/e/1FAIpQLScNmpqRE8Lc977mqSY07FxWrOPEMTWNOyGU6upUAfiyaZyw3w/closedform",
      "_blank",
      "width=600, height=800, top=100, left=100"
    );
    //새창 팝업창 띄우기
    //window.open("https://www.naver.com", "_blank", "width=600, height=800, top=300, left=300")
  };

  return (
    <div style={{ width: "100%" }}>
      <TopContent>
        <li className="left-area" onClick={() => showDrawer()}>
          <MenuOutlined style={{ color: "#fff" }} />
        </li>
        <li className="center-area">
          <a href="/">
            <img src={logo} style={{maxWidth:"150px"}}></img>
          </a>
        </li>
        <li className="right-area"></li>
      </TopContent>

      <DrawerConainer>
        <Drawer
          // title="예방운동관리사"
          placement="left"
          closable={true}
          onClose={onClose}
          visible={visible}
        >
          <h2 style={{ fontSize: 20, fontWeight: "bold" }}>
            50+ 생애재설계대학
          </h2>

          <div style={{ fontSize: 18, paddingTop: 7, paddingBottom: 7 }}>
            <a name="page1"></a>
            <div style={{ color: "#484848" }}>Home</div>
          </div>

          <div style={{ fontSize: 16, paddingTop: 3, paddingBottom: 3 }}>
            <a href="/mobile_home" style={{ color: "#3f968e" }}>
              · 건강운동관리사 소개
            </a>
          </div>

          <div style={{ fontSize: 16, paddingTop: 3, paddingBottom: 3 }}>
            <a href="/mobile_home/#chart" style={{ color: "#3f968e" }}>
              · 조직도
            </a>
          </div>

          <div style={{ fontSize: 18, paddingTop: 7, paddingBottom: 7 }}>
            <div style={{ color: "#484848" }}>교수 소개</div>
          </div>

          <div style={{ fontSize: 16, paddingTop: 3, paddingBottom: 3 }}>
            <a href="/mobile_prof1" style={{ color: "#3f968e" }}>
              · 박일봉 교수
            </a>
          </div>
          <div style={{ fontSize: 16, paddingTop: 3, paddingBottom: 3 }}>
            <a href="/mobile_prof2" style={{ color: "#3f968e" }}>
              · 이채관 교수
            </a>
          </div>
          <div style={{ fontSize: 16, paddingTop: 3, paddingBottom: 3 }}>
            <a href="/mobile_prof3" style={{ color: "#3f968e" }}>
              · 김정연 교수
            </a>
          </div>

          <div style={{ fontSize: 16, paddingTop: 3, paddingBottom: 3 }}>
            <a href="/mobile_prof4" style={{ color: "#3f968e" }}>
              · 이자연 교수
            </a>
          </div>
          <div style={{ fontSize: 16, paddingTop: 3, paddingBottom: 3 }}>
            <a href="/mobile_prof5" style={{ color: "#3f968e" }}>
              · 강슈호 교수
            </a>
          </div>

          <div style={{ fontSize: 18, paddingTop: 7, paddingBottom: 7 }}>
            <div>교육일정</div>
          </div>

          <div style={{ fontSize: 16, paddingTop: 3, paddingBottom: 3 }}>
            <a href="/mobile_home/#plan" style={{ color: "#3f968e" }}>
              · 2022년 일정
            </a>
          </div>

          {/* <div
            style={{
              fontSize: 16,
              color: "#484848",
              paddingTop: 3,
              paddingBottom: 3,
            }}
          >
            · 2022년 일정 (2022년 1월 중 오픈)
          </div> */}
        </Drawer>
      </DrawerConainer>
    </div>
  );
}

export default DrawerMenu;
