import React from "react";
import styled from "styled-components";
import "antd/dist/antd.css";
import { FiUnderline } from "react-icons/fi";
import "antd/dist/antd.css";
import GoPage from "../lib/GoPage";
import logo from "../img/logo_08.jpg";

const MenuContent = styled.ul`
  a {
    color: inherit;
  }
  width: 100%;
  height: 80px;
  font-size: 20px;
  background-color: #dcdddd;
  justify-content: center;
  display: flex;
  margin-bottom: 0;
  li {
    text-align: center;
    width: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #2e2f29;
    cursor: pointer;
  }
  .underline {
    text-decoration: underline #244673;
    text-underline-position: under;
  }
`;



const RootContainer = styled.div`
  .propContainer {
    color: #fff;
    background: url(/img/teacher1.jpg) no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 800px;
  }
  .propContent {
    display: flex;
    width: 1060px;
    height: 330px;
    letter-spacing: 1px;
  }

  .propLeft {
    width: 50%;
  }
  .propRight {
    width: 50%;
  }

  .propContetnTitle {
    font-size: 30px;
  }
  .propContetnText {
    font-size: 100px;
  }
`;

const ProfileContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
const ProfileContent = styled.div`
  width: 940px;
  color: #484848;


`;
const ProfileTitle = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  font-size: 30px;
  margin-top: 30px;
  margin-bottom: 30px;
  color: #274c7e;

`;

const ProfileInfo = styled.div`
  display: flex;
  justify-content: left;
  font-size: 25px;
  flex-direction: column;

`;
const BookListContent = styled.div`
  display: flex;
  flex-flow: wrap;
  justify-content: start;

  .bookItem {
    /* border: 3px solid red; */
    margin: 7px;
  }
  .bookName {
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 20px;
  }
`;

const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background-color: #264a7b;
  color: #fff;
  font-size: 17px;
`;

const MenuListItem = styled.div`
  display: flex;
  list-style: none;
  justify-content: center;
  align-items: center;
  li {
    width: 170px;
    text-align: center;
    cursor: pointer;
  }
  button {
    background-color: #3f968e;
  }
`;


function Prop1() {
  let nowActiveMenu1 = "";
  let nowActiveMenu2 = "";
  let nowActiveMenu3 = "";
  let nowActiveMenu4 = "";
  let nowActiveMenu5 = "";
  let nowActiveMenu6 = "";

  // if(active==="about1") {
  //   nowActiveMenu1 = "now-menu-is-active";
  // }
  // if (active === "about2") {
  //   nowActiveMenu2 = "now-menu-is-active";
  // }
  // if(active==="about3") {
  //   nowActiveMenu3 = "now-menu-is-active";
  // }
  // if (active === "about4") {
  //   nowActiveMenu4 = "now-menu-is-active";
  // }
  // if (active === "about5") {
  //   nowActiveMenu5 = "now-menu-is-active";
  // }

   const GoPage = (go) => {
     if (go === "prop1") {
       window.location.href = "/Prop1";
     }
     if (go === "prop2") {
       window.location.href = "/Prop2";
     }
     if (go === "prop3") {
       window.location.href = "/Prop3";
     }
     if (go === "prop4") {
       window.location.href = "/Prop4";
     }
     if (go === "prop5") {
       window.location.href = "/Prop5";
     }

   };



  return (
    <RootContainer>
      <MenuContainer>
        <div style={{ height: 42 }}></div>
        <div>
          <img src={logo}></img>
        </div>
        <div style={{ height: 38 }}></div>
        <MenuListItem>
          <li>
            <a href="/" style={{ color: "inherit" }}>
              HOME
            </a>
          </li>
          <li>
            <a href="/#page2" style={{ color: "inherit" }}>
              예방운동관리사 소개
            </a>
          </li>
          <li>
            <a href="/#page3" style={{ color: "inherit" }}>
              조직도
            </a>
          </li>
          <li onClick={() => GoPage("prop1")}>
            <a href="/#page4" style={{ color: "inherit" }}>
              교수소개
            </a>
          </li>
          <li>
            <a href="/#page5" style={{ color: "inherit" }}>
              교육일정
            </a>
          </li>
          <li>
            <a href="/#page6" style={{ color: "inherit" }}>
              등록안내
            </a>
          </li>
          <div>
            <button>교육등록바로가기</button>
          </div>
        </MenuListItem>
        <div style={{ height: 27 }}></div>
      </MenuContainer>
      <MenuContent>
        <li className={nowActiveMenu1} onClick={() => GoPage("prop1")}>
          <span className="underline">박일봉 교수</span>
        </li>
        <li className={nowActiveMenu2} onClick={() => GoPage("prop2")}>
          이채관 교수
        </li>
        <li className={nowActiveMenu3} onClick={() => GoPage("prop3")}>
          김정연 교수
        </li>
        <li className={nowActiveMenu4} onClick={() => GoPage("prop4")}>
          이자연 교수
        </li>
        <li className={nowActiveMenu5} onClick={() => GoPage("prop5")}>
          강슈호 교수
        </li>
      </MenuContent>

      <div className="propContainer">
        <div className="propContent">
          <div className="propLeft"></div>
          <div className="propRight">
            <div className="propContetnTitle">스포츠 재활 트레이닝 전문가</div>

            <div className="propContetnText">
              박 일 봉 <span style={{ fontSize: 70 }}>교수</span>
              <br />
            </div>
          </div>
        </div>
      </div>
      <ProfileContainer>
        <ProfileContent>
          <ProfileTitle>
            <img
              style={{ height: 25, marginRight: 5 }}
              src="./img/prop_icon.jpg"
            />
            학력사항
          </ProfileTitle>
          <ProfileInfo>
            · 동아대학교 체육학부 졸업 <br />
            · 동아대학교 체육학석사(운동생리학) 졸업 <br />
            · 동아대학교 이학박사(운동생리학) 졸업 <br />
            · 부산가톨릭대학교 물리치료학과 학사
            <br />
            · 부산대학교 의학전문대학원 의학석사 졸업 <br />
            · 부산대학교 의학전문대학원 의학박사 수료
            <br />· 부산대학교 경영대학원 경영컨설팅학박사 졸업
          </ProfileInfo>

          <ProfileTitle>
            <img
              style={{ height: 25, marginRight: 5 }}
              src="./img/prop_icon.jpg"
            />
            교육 경력 사항
          </ProfileTitle>
          <ProfileInfo>
            · 전) 고신대학교 환경보건학과 외래교수
            <br />
            · 전) 동아대학교 스포츠과학대학 외래교수
            <br />
            · 전) 대경대학교 생활체육지도과 외래교수 <br />
            · 전) 동원과학기술대학교 재활스포츠과 겸임교수
            <br />
            · 현) (사)대한기능재활운동협회 협회장
            <br />· 현) 부산외국어대학교 스포츠재활학과 교수
          </ProfileInfo>

          <ProfileTitle>
            <img
              style={{ height: 25, marginRight: 5 }}
              src="./img/prop_icon.jpg"
            />
            자격 및 면허
          </ProfileTitle>
          <ProfileInfo>
            · 물리치료사 [보건복지부]
            <br />
            · 건강운동관리사 [문화체육관광부]
            <br /> · 생활스포츠지도자 2급 보디빌딩, 야구, 축구, 스쿼시
            [문화체육관광부]
            <br />· 미국스포츠의학회(NASM) CPT, CES, PES
          </ProfileInfo>

          <ProfileTitle>
            <img
              style={{ height: 25, marginRight: 5 }}
              src="./img/prop_icon.jpg"
            />
            수상 경력
          </ProfileTitle>
          <ProfileInfo>
            · 2014 스포츠서울 Innovation 기업&브랜드 혁신리더 부문 대상 (2014)
            <br />
            · NASM PES - 운동수행능력 향상 트레이닝 우수학술도서 선정 (2015)
            <br />· 2016 헤럴드경제 대한민국 가치경영대상 (2016)
            <br />
          </ProfileInfo>
          <ProfileTitle>
            <img
              style={{ height: 25, marginRight: 5 }}
              src="./img/prop_icon.jpg"
            />
            저서 및 역서
          </ProfileTitle>
          <BookListContent>
            <div className="bookItem">
              <img src="./img/book1.jpg"></img>
              <div className="bookName">
                <div>NASM 퍼스널 트레이닝</div>

                <div>(2014)</div>
              </div>
            </div>
            <div className="bookItem">
              <img src="./img/book2.jpg"></img>
              <div className="bookName">
                <div>NASM 교정운동학</div>

                <div>(2014)</div>
              </div>
            </div>
            <div className="bookItem">
              <img src="./img/book3.jpg"></img>
              <div className="bookName">
                <div>NASM 운동수행능력향상</div>

                <div>트레이닝(2014)</div>
              </div>
            </div>
            <div className="bookItem">
              <img src="./img/book4.jpg"></img>
              <div className="bookName">
                <div>Steve Cotter's 케틀벨</div>

                <div>(2014)</div>
              </div>
            </div>
            <div className="bookItem">
              <img src="./img/book5.jpg"></img>
              <div className="bookName">
                <div>재활기능운동학</div>
                <div>(2015)</div>
              </div>
            </div>
            <div className="bookItem">
              <img src="./img/book6.jpg"></img>
              <div className="bookName">
                <div>PNF를 이용한</div>
                <div>촉진스트레칭(2016)</div>
              </div>
            </div>
            <div className="bookItem">
              <img src="./img/book7.jpg"></img>
              <div className="bookName">
                <div>서스펜션 트레이닝TRX</div>
                <div>(2019)</div>
              </div>
            </div>
            <div className="bookItem">
              <img src="./img/book8.jpg"></img>
              <div className="bookName">
                <div>스트랭스 트레이닝과</div>
                <div>코디네이션(2019)</div>
              </div>
            </div>
            <div className="bookItem">
              <img src="./img/book9.jpg"></img>
              <div className="bookName">
                <div>펑셔널 트레이닝</div>
                <div>(2019)</div>
              </div>
            </div>
            <div className="bookItem">
              <img src="./img/book10.jpg"></img>
              <div className="bookName">
                <div>임상재활치료학</div>
                <div>(2019)</div>
              </div>
            </div>
            <div className="bookItem">
              <img src="./img/book11.jpg"></img>
              <div className="bookName">
                <div>중력가속운동의 원리</div>
                <div>(2020)</div>
              </div>
            </div>
          </BookListContent>

          <ProfileTitle>
            <img
              style={{ height: 25, marginRight: 5 }}
              src="./img/prop_icon.jpg"
            />
            논문
          </ProfileTitle>
          <ProfileInfo>
            <div>
              The Comparison on Scoliosis and Functional Movement Screening of
              Baseball Athletes in Growth Period According to Career - Journal
              of Korean Living Environment System, 2018, 25(3).
            </div>
            <div style={{ height: 10 }}></div>
            <div>
              An Epidemiological Investigation Study of Elementary Baseball
              Player - Journal of Korea Sport Society, 2018, 16(1).
            </div>
            <div style={{ height: 10 }}></div>
            <div>
              A Comparison Analysis of EMG according to Phase, Groups and
              Periods of Snatch Weightlifting in High School Female Weight
              Lifter - The Korean Journal of Physical Education, 2008, 48(2),
            </div>
            <div style={{ height: 10 }}></div>
            <div>
              Effects of Exercise Intensity Difference on MDA and SOD
              Concentration in Blood in Elementary Obese Children - The Korean
              society of Elementary Physical Education Vol. 17 (3) 2011
            </div>
            <div style={{ height: 10 }}></div>
            <div>
              The Effect of The Complex of Aerobic Exercise for 24 week on Body
              Composition, Leptin, Total Cholesterol and Bone Mineral Density in
              Middle-aged Women - Korean Alliance for Health Vol. 45(3) 2006
            </div>
            <div style={{ height: 10 }}></div>
            <div>
              Effect on EMG of Upper Limbs and Fatigue Substance in Blood of Two
              Hand Backhand Stroke Repetition Use in Male High School Tennis
              player - Korean Society of Exercise Rehabilitation Vol. 6(4) 2010
            </div>
            <div style={{ height: 10 }}></div>
            <div>
              The Effects of Exercise with Taping on Muscle Strength and Bone
              Mineral Density, IGF-1 in Blood of Elderly Women - Korean Society
              of Physical Medicine Vol. 5(2) 2010
            </div>
            <div style={{ height: 10 }}></div>
            <div>
              The Effects of Aquatic Exercise according to Taping treatment on
              Lower Extremity Muscle Strength, Pain Rating Scale and Bone
              Mineral Density in Elderly Women with Knee Osteoarthritis - The
              Korean Society of Sports Science Vol. 18(4) 2009
            </div>
            <div style={{ height: 10 }}></div>
            <div>
              Effects of Elastic Resistance Exercise on IGF-1, CK Concentration
              in Blood and Isokinetic Muscle Strength in Elementary Baseball
              Players - The Korean society of Elementary Physical Education Vol.
              15(2) 2009
            </div>
            <div style={{ height: 10 }}></div>
            <div>
              Development of Remodeling Exercise Prescription Program for
              Treatment of Obesity and Osteoporosis in Older Women - Korean
              Society of Exercise Physiology Vol. 13(3) 2004
            </div>
            <div style={{ height: 10 }}></div>
            <div>
              A Comparison Analysis of the Indices of Muscle Damage and Fatigue
              in Blood after Submaximal Exercise according to Sports Entries in
              Elementary School Athletes - The Korean Society of Sports Science
              Vol. 17(4) 2008
            </div>
            <div style={{ height: 10 }}></div>
            <div>
              A Change of EMG on Upper Limbs Muscle according to pitching
              variation in Baseball Pitchers - The Korean Society of Sports
              Science Vol. 17(4) 2008
            </div>
            <div style={{ height: 10 }}></div>
            <div>
              A Comparison Analysis of EMG according to Weight Class and
              Increase Record of Clean and Jerk Techniques Weightlifting in High
              School Female Weight Lifters - Korean Society of Sports
              Biomechanics Vol. 18(2) 2008
            </div>
            <div style={{ height: 10 }}></div>
            <div>
              Effect of Repetiton Pitching on Elementary School Baseball
              Pitchers Skill Level on the Indices of Muscle Damage and Fatigue
              in Blood - The Korean Society of Sports Medicine Vol. 26(1) 2008
            </div>
            <div style={{ height: 10 }}></div>
            <div>
              The Change of EMG according to Pitching Number Increase in High
              School Baseball Pitcher - The Korean Society of Sports Medicine
              Vol. 25(2) 2007
            </div>
            <div style={{ height: 10 }}></div>
            <div>
              The effect of pre-season baseball training on growth and
              cardiopulmonary in elementary school boys - Korean Society of
              Exercise Physiology Vol. 12(3) 2003
            </div>
            <div style={{ height: 10 }}></div>
            <div>
              The effect of long-term weight and aerobic exercise on the change
              of bone mineral density, leptin and isokinetic muscle strength in
              older women - Korea Sports Science Vol. 22 pp.41~52
            </div>
            <div style={{ height: 10 }}></div>
            <div>
              A Comparison Analysis of EMG according to Weight Class and
              Increase Record of Clean and Jerk Techniques Weightlifting in High
              School Female Weight Lifters - Korean Society of Sports
              Biomechanics Vol. 18(2) 2008
            </div>
            <div style={{ height: 10 }}></div>
            <div>
              A Study on the Isokinetic muscle strength and Cardiopulmonary in
              Elementary School Baseball Player - Korea Sports Science Vol. 23
              pp.119~128
            </div>
            <div style={{ height: 10 }}></div>
            <div>
              Relative in Exercise and EPO(Erythropoietin) - Korea Sports
              Science Vol. 23 pp.1~8
            </div>
            <div>
              Relative in Exercise and 2,3-DPG(diphosphoglycerate) - Korea
              Sports Science Vol. 23 pp.9~17
            </div>
            <div style={{ height: 10 }}></div>
            <div>
              The effect of long-term weight and aerobic exercise on the change
              of bone mineral density, leptin and isokinetic muscle strength in
              older women - Korea Sports Science Vol. 22 pp.41~52 .
            </div>
            <div style={{ height: 200 }}></div>
          </ProfileInfo>
        </ProfileContent>
      </ProfileContainer>
    </RootContainer>
  );
}

export default Prop1;
