import React, { Component, useEffect } from "react";
import styled from "styled-components";
import { Tag, Divider, Button } from "antd";
import "antd/dist/antd.css";
import GoPage from "../lib/GoPage";
import GetCid from "../lib/GetCid";
import pr from "../lib/Pr";

const MyPtTabContaienr = styled.div`
  /* border: 1px solid blue; */
  width: 100%;
`;


const MyPtTabContent = styled.div`
  background-color: #111111;
  font-size: 18px;
  display: flex;

  li {
    /* border: 1px solid green; */
    list-style: none;
    color: #ffffff;
    flex: 1;
    text-align: center;
    cursor: pointer;
    padding: 7px 0 7px 0;
  }
  .active {
    background: #44b674;
  }
`;

function MyPtMenu(props) {
  const cid = props.cid;
  pr('Render:MyPtMenu()');
  return (
    <MyPtTabContaienr>
      {props.active === "1" ? (
        <MyPtTabContent>
          <li className="active" onClick={() => GoPage("my_pt", cid)}>
            내 수업{" "}
          </li>
          <li onClick={() => GoPage("my_coupon", cid)}>내 쿠폰</li>
          <li onClick={() => GoPage("order", cid)}>쿠폰 구매하기</li>
        </MyPtTabContent>
      ) : props.active === "2" ? (
        <MyPtTabContent>
          <li onClick={() => GoPage("my_pt", cid)}>내 수업</li>
          <li className="active" onClick={() => GoPage("my_coupon", cid)}>
            내 쿠폰
          </li>
          <li onClick={() => GoPage("order", cid)}>쿠폰 구매하기</li>
        </MyPtTabContent>
      ) : props.active === "3" ? (
        <MyPtTabContent>
          <li onClick={() => GoPage("my_pt", cid)}>내 수업</li>
          <li onClick={() => GoPage("my_coupon", cid)}>내 쿠폰</li>
          <li className="active" onClick={() => GoPage("order", cid)}>
            쿠폰 구매하기
          </li>
        </MyPtTabContent>
      ) : null}
    </MyPtTabContaienr>
  );
}

export default MyPtMenu;


