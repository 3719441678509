import React, { useState, useEffect } from "react";
import styled from "styled-components";
import pr from "../lib/Pr";
import Axios from "axios";
import moment from "moment";
import { Spin } from "antd";
import GetToken from "../lib/GetToken";
import GetLogin from "../lib/GetLogin";
import GetYoil from "../lib/GetYoil";
// import Container from './Container';
import Layout from "./Layout";
import Loading from "./Loading";
import { Tag, Divider, Button } from "antd";
import "antd/dist/antd.css";
import GoPage from "../lib/GoPage";
import TitleText from "./TitleText";
import MyPtMenu from "./MyPtMenu";
import GetCid from "../lib/GetCid";
import GetHost from "../lib/GetHost";
import GetMcd from "../lib/GetMcd";
import axios from "axios";
import { CloseOutlined } from "@ant-design/icons";
import TopContainer from "./TopContainer";
import {
  FieldTimeOutlined,
  CalendarOutlined,
  CarryOutOutlined,
  HeartOutlined,
  ExclamationCircleOutlined,
  StarOutlined,
  CloseSquareOutlined,
} from "@ant-design/icons";
import { useRecoilValue } from "recoil";
import { loginState } from "../lib/State";
import LoginAuth from "../lib/LoginAuth";
import { GetDayDifference } from "../lib/GetDayDifference";

const Container = styled.div`
  /* border: 3px solid yellowgreen; */
  background: #111111;
  display: flex;
  flex-direction: column;
  min-height: 145px;
  height: 100%;
`;

const MyPtMenuContainer = styled.div`
  justify-content: center;
  display: flex;
  border: 1px solid #333333;
`;
const BottomContainer = styled.div`
  color: #ffffff;
  display: flex;
  align-items: center;
  flex-direction: column;
`;
const TicketContainer = styled.div`
  height: 100%;
  display: flex;
  margin-bottom: 10px;
`;

const TicketItemLeft = styled.div`
  width: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${(props) => props.url});
  /* url(img/common/coupon_weekly.png); */
  background-repeat: no-repeat;
  background-position: center left;
  font-family: -apple-system, "Roboto";
  text-align: center;
  .ticket-left {
    padding-left: 12px;
  }
`;

const TicketItemCenter = styled.div`
  /* background-image: url(img/common/coupon_weekly.png); */
  background-image: url(${(props) => props.url});
  background-repeat: no-repeat;
  background-position: -120px center;
  width: 25px;
`;

const TicketItemRight = styled.div`
  flex: 1;
  background-image: url(img/common/coupon_weekly.png);
  background-repeat: no-repeat;
  background-position: center right;
  font-size: 14px;
`;

const TicketButtonContainer = styled.div`
  display: flex;
  height: 100%;
`;

const TicketButtonContainerLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 5px;
  padding-right: 5px;
`;

const TicketButtonContainerRight = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  width: 90px;
`;
const MyCouponContainer = styled.div`
  overflow-y: scroll;
  height: 100%;
`;
const CardStyleContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  height: 100%;

`;
const CardStyleContent = styled.div`
  width: 90%;
  /* border: 1px solid orange; */
`;
const CardStyle1 = styled.div`
  color: #fff;
  background-color: #222222;
  border-radius: 10px;
  padding: 20px 10px 30px 20px;
  border: 2px solid #535353;
  margin-bottom: 10px;

  position: relative;
  & p {
    margin-bottom: 0;
    font-size: 13px;
  }
`;
const CardStyle = styled.div`

  display: flex;
  justify-content: center;
  min-height: 145px;
  /* color: red; */
  & .state {
    font-size: 16px;
  }
  & .state img {
    margin-right: 4px;
  }
  & .yellow {
    color: #fff566;
  }
  & .orange {
    color: #fa8c16;
  }
  & p {
    margin-bottom: 0;
    font-size: 13px;
  }
`;

function MyCoupon() {
  const cid = GetCid();
  const [rows, setRows] = useState([]); // 데이타
  const [loaded, setLoaded] = useState(true);
  const login = useRecoilValue(loginState);

  useEffect(() => {
    if (login.loaded === true) {
      if (login.mcd !== "") {
        onLoadData();
      } else {
        GoPage("home", login.cid);
      }
    }
  }, [login.mcd, login.token]);

  const onLoadData = async () => {
    let result = await axios.post(GetHost() + "/gympass/get_coupon", {
      cid: cid,
      mcd: login.mcd,
    });
    setRows(result.data);
    setLoaded(true);
  };

  const on_rate_request_pop = (seq) => {
    alert(1);
    // confirm({
    //   title: "별점요청을 하시겠습니까?",
    //   icon: <ExclamationCircleOutlined />,
    //   content: "회원 출석이 인정됩니다.",
    //   okText: "확인",
    //   cancelText: "취소",
    //   onOk() {
    //     set_rate_state(seq, "요청");
    //   },
    //   onCancel() {},
    // });
  };

  const on_rate_change = (value) => {
    alert(2);
    // setRatingData((state) => {
    //   return { ...state, rating: value.toString() };
    // });
  };

  const on_rating_pop = async (res_seq, tcd, class_date) => {
    alert(3);
    // setRatingData((state) => {
    //   return {
    //     ...state,
    //     cid: login.cid,
    //     mcd: login.mcd,
    //     tcd: tcd,
    //     res_seq: res_seq,
    //     class_date: class_date,
    //   };
    // });

    // let result;
    // result = await axios.post(api_host + "/hg/get_random_comment");
    // setComment(result.data.comment);
    // setIsModalVisible(true);

    // setRatingData((state) => {
    //   return {
    //     ...state,
    //     cid: login.cid,
    //     mcd: login.mcd,
    //     tcd: tcd,
    //     res_seq: res_seq,
    //     category: result.data.category,
    //   };
    // });
  };

  const handle_reservation = (params) => {
    const { coupon_seq, pt_type } = params;
    GoPage(
      "pt_reservation",
      cid + "&coupon_seq=" + coupon_seq + "&pt_type=" + pt_type
    );
  };

  return (
    <Layout>
      <LoginAuth />
      {loaded === false ? (
        <Loading />
      ) : (
        <Container>
          <TopContainer title="내쿠폰" />

          <MyPtMenuContainer>
            <MyPtMenu active="2" cid={cid}></MyPtMenu>
          </MyPtMenuContainer>

          <MyCouponContainer>
            <CardStyleContainer>
              <CardStyleContent>
                {rows.length === 0 ? (
                  <CardStyle1 title="NO RESERVATION" extra={<a href="#"></a>}>
                    <p>쿠폰이 없습니다.</p>
                    <p>쿠폰을 구매해서 예약하실 수 있습니다.</p>
                  </CardStyle1>
                ) : null}
                {rows.map((n, i) => {
                  const green = "#556b2f";
                  const orange = "#fa8c16";
                  let pt_type = n.pt_type;
                  let total_count = n.total_count;
                  let use_count = n.use_count;
                  let is_valid = true;
                  let color = "";
                  let final_state = (
                    <span style={{ color: orange }}>
                      <CalendarOutlined style={{ fontSize: 20 }} /> 예약가능
                    </span>
                  );
                  let enroll_sdate = n.enroll_sdate;
                  let enroll_edate = n.enroll_edate;

                  // 조건 : 잔여쿠폰 확인
                  if (
                    n.total_count === n.use_count ||
                    n.use_count > n.total_count
                  ) {
                    final_state = (
                      <span style={{ color: green }}>
                        <CalendarOutlined style={{ fontSize: 20 }} /> 사용완료
                      </span>
                    );
                    is_valid = false;
                  }

                  // 조건 : 유효기간 확인
                  const now = moment().format("YYYY-MM-DD");
                  const diff1 = GetDayDifference(enroll_sdate, now);
                  const diff2 = GetDayDifference(now, enroll_edate);

                  if (is_valid) {
                    if (diff1 < 0) {
                      final_state = (
                        <span style={{ color: green }}>
                          <CalendarOutlined style={{ fontSize: 20 }} /> 이용기간
                          아님
                        </span>
                      );
                      is_valid = false;
                    }
                  }

                  if (is_valid) {
                    if (diff2 < 0) {
                      final_state = (
                        <span style={{ color: green }}>
                          <CalendarOutlined style={{ fontSize: 20 }} /> 이용기간
                          만료
                        </span>
                      );
                      is_valid = false;
                    }
                  }

                  // 쿠폰 배경색
                  const background =
                    pt_type === "PT"
                      ? "img/common/coupon_super.png"
                      : "img/common/coupon_weekly.png";

                  return (
                    <BottomContainer key={i}>
                      <TicketContainer>
                        <div>
                          <CardStyle extra={<a href="#"></a>}>
                            <TicketItemLeft url={background}>
                              <div className="ticket-left">
                                {pt_type === "PT" ? (
                                  <div style={{ fontWeight: "bold" }}>
                                    <span>PT</span>
                                  </div>
                                ) : (
                                  <div style={{ fontWeight: "bold" }}>
                                    <span>GX</span>
                                  </div>
                                )}
                                <div style={{ fontSize: 13 }}>
                                  {use_count} / {total_count}
                                </div>
                              </div>
                            </TicketItemLeft>

                            <TicketItemCenter
                              url={background}
                            ></TicketItemCenter>

                            <TicketItemRight>
                              <TicketButtonContainer>
                                <TicketButtonContainerLeft>
                                  <div className={"state " + color}>
                                    {/* {pt_type === "pt" ? (
                              <span>[PT 이용권]</span>
                            ) : (
                              <span>[GX 이용권]</span>
                            )} */}
                                    {final_state}
                                  </div>
                                  <p>
                                    유효기간:
                                    <br />
                                    {moment(enroll_sdate).format(
                                      "YYYY-MM-DD"
                                    )}{" "}
                                    -{" "}
                                    {moment(enroll_edate).format("YYYY-MM-DD")}
                                  </p>
                                  <p>
                                    발급일:
                                    {moment(n.regdate).format("YYYY-MM-DD")}
                                  </p>
                                  <p>{n.cname}</p>
                                </TicketButtonContainerLeft>

                                <TicketButtonContainerRight>
                                  {is_valid ? (
                                    <Button
                                      className="my-coupon-btn"
                                      type="primary"
                                      onClick={() =>
                                        handle_reservation({
                                          coupon_seq: n.seq,
                                          pt_type: pt_type,
                                        })
                                      }
                                    >
                                      예약
                                    </Button>
                                  ) : (
                                    <Button disabled="disabled">예약</Button>
                                  )}
                                </TicketButtonContainerRight>
                              </TicketButtonContainer>
                            </TicketItemRight>

                            {/* {show_req_star ? (
                        <ButtonStyle
                          onClick={() => on_rate_request_pop(n.res_seq)}
                          type="primary"
                          shape="round"
                          size="large"
                          className="req"
                        >
                          <img src={icon_star} />
                          별점요청
                        </ButtonStyle>
                      ) : null}
                      {show_rate_star ? (
                        <ButtonStyle
                          onClick={() =>
                            on_rating_pop(
                              n.res_seq.toString(),
                              n.tcd,
                              n.class_date
                            )
                          }
                          type="danger"
                          shape="round"
                          size="large"
                          className="rate"
                        >
                          <img src={icon_star} />
                          별점주기
                        </ButtonStyle>
                      ) : null} */}
                          </CardStyle>
                        </div>
                      </TicketContainer>
                    </BottomContainer>
                  );
                })}
              </CardStyleContent>
            </CardStyleContainer>
          </MyCouponContainer>
        </Container>
      )}
    </Layout>
  );
}

export default MyCoupon;
