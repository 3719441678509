import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import pr from "../lib/Pr";
import Axios from "axios";
import moment from "moment";
import { Spin } from "antd";
import GetToken from "../lib/GetToken";
import GetLogin from "../lib/GetLogin";
// import Container from './Container';
import Layout from "./Layout";
import Loading from "./Loading";
import { Tag, Divider, Button, Input, Modal } from "antd";
import "antd/dist/antd.css";
import GoPage from "../lib/GoPage";
import GetCid from "../lib/GetCid";
import axios from "axios";
import GetHost from "../lib/GetHost";
import BackBtn from "./BackBtn";

// import { ConsoleSqlOutlined } from "@ant-design/icons";

const RootContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

`;

const TopContainer = styled.div`
  flex: 1;
  /* padding: 5px; */
  /* border: 2px solid black; */
  display: flex;
  justify-content: center;
`
const Content = styled.div`
  width: 90%;
  /* border: 2px solid green; */
`

const BottomContainer = styled.div`
  /* border: 2px solid red; */
  height:260px;
`;

const LoginLogoContainer = styled.div`
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
`;
const LoginLogoContent = styled.div`
  /* border: 1px solid blue; */
  text-align: center;
  img {
    width: 260px;
  }
`;

const LoginTextContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top:40px;
`;

const LoginText = styled.div`
  width: 80%;
  /* border: 1px solid orange; */
  .login-text1 {
    /* border: 1px solid green; */
    font-size: 20px;
    font-weight: bold;
  }
  .login-text2 {
    /* border: 1px solid green; */
    font-size: 16px;
    color: #7f7f7f;
  }
`;

const LoginInputContainer = styled.div`
  margin-top:40px;
  display: flex;
  justify-content: center;

`;

const InputContent = styled.div`
  /* border: 1px solid purple; */
  width: 80%;
  Input {
    border: none;
    border-bottom: 1px solid #7f7f7f;
    padding-top: 12px;
  }

`;
const LoginContainer = styled.div`
  display: flex;
  justify-content: center;
  .login-enter {
    width: 100%;
    height: 50px;
    text-align: center;
    background-color: #4c6eec;
    font-size: 18px;
    color: #ffffff;
  }

`;

const LoginContent = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  /* border: 1px solid green; */
  display: flex;
  align-items: center;
  justify-content: center;

`;

const FooterContainer = styled.div`
  /* border: 1px solid red; */
  display: flex;
  justify-content: center;
`;

const FooterContent = styled.div`
  /* border: 1px solid purple; */
  width: 90%;
  text-align: center;
  font-size: 12px;
  color: #7f7f7f;
  margin-top: 30px;
`;

function Login() {
  let result;

  const [token, setToken] = useState(); // --- #인증1
  const [login, setLogin] = useState({}); // --- #인증2
  const [loaded, setLoaded] = useState();
  const [cid, setCid] = useState(""); // GET: cid
  useEffect(() => {
    setCid(GetCid());
  }, []);
  const phoneInput = useRef();
  const pwInput = useRef();

  useEffect(() => {
    setLoaded(true);
  }, []);

  const handleLogin = async () => {
    result = await axios.post(GetHost() + "/gympass/login", {
      cid: cid,
      phone: phoneInput.current.state.value,
      pw: pwInput.current.state.value,
    });
    if (result.data.ret !== "Y") {
      Modal.error({ title: "오류메시지", content: result.data.msg });
    } else {
      localStorage.setItem('token',result.data.token);
      localStorage.setItem('mcd',result.data.mb_id);
      GoPage('logged',cid);
    }
  };

  return (
    <Layout>
      {loaded === false ? (
        <Loading />
      ) : (
        <div>
          {/* <BackBtn go="home" cid={cid} onClick={() => GoPage("login")} /> */}
          <RootContainer>
            <TopContainer>
              <Content>
                <LoginLogoContainer>
                  <LoginLogoContent>
                    <img src="/img/common/main_logo_black.png" alt="#"></img>
                  </LoginLogoContent>
                </LoginLogoContainer>

                <LoginTextContainer>
                  <LoginText>
                    <div className="login-text1">안녕하세요.</div>
                    <div className="login-text1">피지컬베이스입니다.</div>
                    <div className="login-text2">
                      피지컬베이스을 이용해주셔서 감사합니다
                    </div>
                  </LoginText>
                </LoginTextContainer>

                <LoginInputContainer>
                  <InputContent>
                    <Input
                      type="number"
                      ref={phoneInput}
                      placeholder="휴대폰 - 없이 입력"
                    ></Input>
                    <Input
                      ref={pwInput}
                      placeholder="비밀번호"
                      type="password"
                    ></Input>
                  </InputContent>
                </LoginInputContainer>

                <LoginContainer>
                  <LoginContent>
                    <Button type="text" onClick={() => GoPage("findpw1", cid)}>
                      비밀번호 찾기
                    </Button>
                    <div>|</div>
                    <Button type="text" onClick={() => GoPage("join1", cid)}>
                      회원가입
                    </Button>
                  </LoginContent>
                </LoginContainer>
              </Content>
            </TopContainer>

            <BottomContainer>
              <LoginContainer>
                <Button className="login-enter" onClick={() => handleLogin()}>
                  로그인하기
                </Button>
              </LoginContainer>
              <FooterContainer>
                <FooterContent>
                  <div>
                    상호명 : 스마트핏운동센터 주식회사 | 사업자번호:
                    370-85-00416
                  </div>
                  <div>대표: 김경아 | 개인정보관리 책임자: 노근역</div>
                  <div>
                    주소: 부산광역시 기장군 정관읍 정관4로 42, 3층
                    301호(풍산빌딩)
                  </div>

                  <div>통신판매업신고번호: 제2016-부산해운대-0474호</div>
                  <div>유선연락처: 051-728-8771 </div>
                  <div>
                    APP내에서 결제되는 서비스에 대하여 환불, 민원 등이
                    이슈사항은 스마트핏운동센터에서 처리하여 모든 책임은
                    스마트핏운동센터에 있습니다. 민원담당자: 노근역,
                    051-583-9645
                  </div>
                  <div>서비스 제휴/ 이용문의: smartfit3274@gmail.com</div>
                </FooterContent>
              </FooterContainer>
            </BottomContainer>
          </RootContainer>
        </div>
      )}
    </Layout>
  );
}

export default Login;

/* <GetToken setToken={setToken}></GetToken>
      <GetLogin token={token} setLogin={setLogin}></GetLogin>
      <GetAuth login={login} setLoaded={setLoaded}></GetAuth> */
