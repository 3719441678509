import React, { Component, useEffect } from "react";
import styled from "styled-components";
import GoPage from "../lib/GoPage";
import TitleText from "./TitleText";
import { LeftOutlined } from "@ant-design/icons";
import { useRecoilValue } from "recoil";
import { loginState } from "../lib/State";
import LoginAuth from "../lib/LoginAuth";
import pr from "../lib/Pr";
import { Tag, Divider, Button } from "antd";


const TopContent = styled.ul`
  display: flex;
  /* width: 100%; */
  background-color: #111111;
  color: #ffffff;
  /* height: 50px; */
  align-items: center;
  /* font-size: 25px; */
  margin-bottom: 0;
  padding: 10px 0;
  li {
    text-align: center;
    display: block;
  }
  .center-area {
    flex: 1;
  }
  .left-area,
  .right-area {
    width: 50px;
  }
  /* flex: 10px 1 10px; */
`;

function BackButton(props) {

  const {go} = props;
  // const go = props.go;
  // const go2 = props.go2; 2[] 1{}

  return <LeftOutlined
    onClick={() => GoPage(go)}
    style={{ fontWeight: "bold", fontSize: 20, padding: 5 }}
  />;
}

function TopContainer(props) {

  const login = useRecoilValue(loginState);


  return (
    <TopContent>
      <LoginAuth />
      <li className="left-area">
        {login.mcd === "" ? (
          <BackButton go="home"/>
        ) : (
           <BackButton go="logged"/>
        )}
      </li>
      <li className="center-area">
        <TitleText>{props.title}</TitleText>
      </li>
      <li className="right-area"></li>
    </TopContent>
  );
}

export default TopContainer;
