import React, { useState, useEffect } from "react";
import styled from "styled-components";
import "antd/dist/antd.css";
import Layout from "./Layout";
import Loading from "./Loading";
import CenterMenu from "./CenterMenu";
import TopContainer from "./TopContainer";
// import Table from "./Table";
import queryString from "query-string";
import pr from "../lib/Pr";
import axios from "axios";
import Item from "antd/lib/list/Item";
import AddComma from "../lib/AddComma";
import GetCid from "../lib/GetCid";
import GetMcd from "../lib/GetMcd";
import GetToken from "../lib/GetToken";
import GetLogin from "../lib/GetLogin";
import GetHost from "../lib/GetHost";

const Container = styled.div`
  background-color: #111111;
  min-height: 145px;
  height: 100%;
  /* padding-top:20px; */
`;
const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  width: 85%;
  margin-top: 20px;
`;

const TableTopContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const TableContent = styled.table`
  width: 100%;
  thead td {
    text-align: center;
    padding: 5px;
  }
  thead .price {
    width: 130px;
    background: #f31b44;
    font-size: 16px;
  }
  thead .title {
    background: #f31b44;
    font-size: 16px;
  }

  tbody td {
    text-align: center;
    font-size: 14px;
    padding: 5px;
  }

  td {
    border: 1px solid #ffffff;
  }
`;

function About3() {
  const [login, setLogin] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState([]);
  const cid = GetCid();
  const mcd = GetMcd();
  const token = GetToken();
  const host = GetHost({ cid: cid });

  useEffect(() => {
    if (token !== undefined && mcd !== undefined) {
      (async () => {
        if (token === "LOGOUT") {
        } else {
          const result = await GetLogin({ token: token, mcd: mcd, cid: cid });
          setLogin(result.data);
        }
      })();
    }
  }, []);

  const loadData = async () => {
    const result = await axios.post(host + "/gympass/get_product", {
      cid: cid,
    });
    setData(result.data);
    setLoaded(true);
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <Layout>
      {loaded === false ? (
        <Loading />
      ) : (
        <Container>
          <TopContainer title="센터소개" login={login} />
          <CenterMenu active="about3" />
          <TableTopContainer>
            <TableContainer>
              <TableContent>
                <thead>
                  <tr>
                    <td className="title">구분</td>
                    <td className="title">상품명</td>
                    <td className="price">가격</td>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item.gubun}</td>
                        <td className="title">
                          {item.item_name}
                          {item.item}
                        </td>
                        <td className="price">{AddComma(item.amount)}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </TableContent>
            </TableContainer>
          </TableTopContainer>
        </Container>
      )}
    </Layout>
  );
}

export default About3;

/* <GetToken setToken={setToken}></GetToken>
      <GetLogin token={token} setLogin={setLogin}></GetLogin>
      <GetAuth login={login} setLoaded={setLoaded}></GetAuth> */
