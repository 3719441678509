import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Layout from "./Layout";
import Loading from "./Loading";
import "antd/dist/antd.css";
import TopContainer from "./TopContainer";
import CenterMenu from "./CenterMenu";
import { Tabs } from "antd";
import GetCid from "../lib/GetCid";
import GetMcd from "../lib/GetMcd";
import GetToken from "../lib/GetToken";
import GetLogin from "../lib/GetLogin";
import getCenter from "../lib/getCenter";
import queryString from "query-string";


const { TabPane } = Tabs;

const Container = styled.div`
  /* min-height: 145px;
  height: 100%; */
  background-color: #111111;
  ol,
  ul,
  dl {
    margin-bottom: 0;
  }
  span {
    color: #ffffff;
    font-size: 12px;
  }
  .ant-tabs-top > .ant-tabs-nav::before,
  .ant-tabs-bottom > .ant-tabs-nav::before,
  .ant-tabs-top > div > .ant-tabs-nav::before,
  .ant-tabs-bottom > div > .ant-tabs-nav::before {
    border-bottom: 1px solid #212121;
  }

  .ant-tabs-top > .ant-tabs-nav::before,
  .ant-tabs-bottom > .ant-tabs-nav::before,
  .ant-tabs-top > div > .ant-tabs-nav::before,
  .ant-tabs-bottom > div > .ant-tabs-nav::before {
    border-bottom: 1px solid #212121;
  }
  .ant-tabs-nav-wrap {
    justify-content: space-around;
  }
  .ant-tabs-tab {
    padding-left: 8px;
    padding-right: 8px;
    justify-content: center;
  }
  .ant-tabs-ink-bar {
    position: absolute;
    background: #f31b44;
    pointer-events: none;
  }
  .top-menu-box {
    height: 20px;
  }
`;

const AboutContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 20px;
`;
const AboutContent = styled.div`
  // border: 1px solid blue;
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 15px;
`;

const AboutContent1 = styled.div`
  // border: 1px solid blue;
  width: 90%;
  display: flex;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;
`;
const AboutImg = styled.div`
  // border: 2px solid #f31b44;
  width: 170px;
  .alarm {
    width: 135px;
  }
  .large {
    width: 135px;
  }
  .large1 {
    width: 132px;
  }
  .pt1 {
    width: 140px;
  }
  .fq3 {
    width: 138px;
  }

  .fq4 {
    width: 150px;
  }
  .fq5 {
    width: 150px;
  }
`;
const AboutTContent = styled.div`
  // border: 1px solid pink;
  margin-left: 10px;
  flex: 1;
  .about-title {
    color: #44b674;
    font-size: 18px;
    font-weight: bold;
  }
  .about-text {
    color: #ffffff;
    font-size: 12px;
  }
  span {
    font-size: 12px;
  }
  .green {
    color: #44b674;
  }
`;

const TabBodyContainer = styled.div`
  /* height: 100%; */
  /* border: 1px solid yellow; */
  height: 450px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TabBodyContainer1 = styled.div`
  /* height: 100%; */
  /* border: 1px solid yellow; */
  height: 290px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TabBodyContent = styled.div`
  /* border: 1px solid orange; */
  width: 90%;
  height: 100%;
  display: flex;
  justify-content: start;
`;

const TabBodyText = styled.div`
  /* border: 1px solid pink; */
  /* width: 40%; */
  width: 190px;
  right: 0;
  margin-right: 20px;
  margin-top: 150px;
  position: absolute;
  .about-title {
    color: #44b674;
    font-size: 18px;
    font-weight: bold;
  }
  .about-text {
    color: #ffffff;
    font-size: 12px;
  }

  .pink {
    font-size: 12px;
    color: #e52c7c;
    font-weight: bold;
  }
  .purple {
    font-size: 12px;
    color: purple;
    font-weight: bold;
  }
`;

const TabBodyTextFq = styled.div`
  /* border: 1px solid pink; */
  /* width: 40%; */
  width: 160px;
  right: 0;
  margin-right: 40px;
  margin-top: 110px;
  position: absolute;
  span {
    color: #44b674;
  }
  .about-text {
    color: #ffffff;
    font-size: 12px;
  }
  .red {
    color: red;
  }
`;

const TabBodyImg = styled.div`
  img {
    width: 300px;
  }
  .rating {
    width: 330px;
  }
`;

function About3() {

  // 로그인
  const [login, setLogin] = useState({});
  const cid = GetCid();
  const mcd = GetMcd();
  const token = GetToken();

  const [isFrequency, setisFrequency] = useState(false);


  async function start() {
    const result = await getCenter({ cid: cid });
    console.log(result.isFrequency);
  }
  useEffect(()=>{
    start();
  },[]);
  // setisFrequency(true);


  useEffect(() => {
    if (token !== undefined && mcd !== undefined) {
      (async () => {
        if (token === "LOGOUT") {
        } else {
          result = await GetLogin({ token: token, mcd: mcd, cid: cid });
          setLogin(result.data);
        }
      })();
    }
  }, []);

  let result;
  const [loaded, setLoaded] = useState();

  function callback(key) {
    console.log(key);
  }

  useEffect(() => {
    setLoaded(true);
  }, []);




  return (
    <Layout>
      {loaded === false ? (
        <Loading />
      ) : (
        <Container>
          <TopContainer title="센터소개" login={login} />
          {/* <div className="top-menu-box"></div> */}
          <CenterMenu active="about4" />

          <Tabs
            defaultActiveKey="1"
            onChange={callback}
            style={{ width: "100%" }}
          >
            <TabPane
              tab={<span style={{ fontSize: 14 }}>어플 이용안내</span>}
              key="1"
            >
              <AboutContainer>
                <AboutContent>
                  <AboutImg>
                    <img
                      src="./img/common/system_app_1.png"
                      alt="#"
                      className="large1"
                    ></img>
                  </AboutImg>
                  <AboutTContent>
                    <div className="about-title">자동 출입시스템</div>
                    <div className="about-text">
                      이용권 구매를 통해 받은 스마트키를 해당 센터의 출입문으로
                      가까이 다가가면 문열기 버튼이 활성화 됩니다. 이때 버튼을
                      클릭하면 출입문이 자동으로 열립니다.
                    </div>
                  </AboutTContent>
                </AboutContent>
              </AboutContainer>

              <AboutContainer>
                <AboutContent>
                  <AboutImg>
                    <img
                      className="alarm"
                      src="./img/common/system_app_2.png"
                      alt="#"
                    ></img>
                  </AboutImg>
                  <AboutTContent>
                    <div className="about-title">알림 시스템</div>
                    <div className="about-text">
                      이용권 및 헬스 가이드 티칭 관련 푸쉬알림으로 나에게 필요한
                      정보를 알려줍니다.
                    </div>
                  </AboutTContent>
                </AboutContent>
              </AboutContainer>

              <AboutContainer>
                <AboutContent>
                  <AboutImg>
                    <img
                      className="large"
                      src="./img/common/system_app_3.png"
                      alt="#"
                    ></img>
                  </AboutImg>
                  <AboutTContent>
                    <div className="about-title">결제 시스템</div>
                    <div className="about-text">
                      센터에 방문하지 않고 24시간 언제든 편리하게 앱에서
                      이용권을 구매할 수 있습니다.
                    </div>
                  </AboutTContent>
                </AboutContent>
              </AboutContainer>
            </TabPane>

            <TabPane tab={<span style={{ fontSize: 14 }}>PT</span>} key="2">
              <AboutContainer>
                <AboutContent>
                  <AboutImg>
                    <img
                      className="pt1"
                      src="./img/common/system_hg_1.png"
                      alt="#"
                    ></img>
                  </AboutImg>
                  <AboutTContent>
                    <div className="about-title">PT예약 시스템</div>
                    <div className="about-text">
                      수업날짜, 트레이너, 시간을 앱으로 손쉽게 선택하여 예약할
                      수 있습니다.
                    </div>
                  </AboutTContent>
                </AboutContent>
              </AboutContainer>

              <TabBodyContainer1>
                <TabBodyContent>
                  <TabBodyText>
                    <div className="about-title">예약 확인 시스템</div>
                    <div className="about-text">
                      나의 예약 이력과 수업완료 후 평가를 진행할 수 있습니다.
                      <div></div>
                      <br />
                      (수엽평가를 하지 않으면 출석 스탬프가 제공되지 않으니
                      수업후 3시간 이내에 꼭! 평가를 진행하시길 바랍니다.)
                    </div>
                  </TabBodyText>

                  <TabBodyImg>
                    <img src="./img/common/system_hg_2.png" alt="#"></img>
                  </TabBodyImg>
                </TabBodyContent>
              </TabBodyContainer1>


                <TabBodyContainer>
                  <TabBodyContent>

                    { isFrequency &&
                    <TabBodyText>
                      <div className="about-title">쿠폰 발급 시스템</div>
                      <div className="about-text">
                        이용권 구매시 매주 예약 가능한
                        <span className="purple">WEEKLY 쿠폰</span>과 이용권
                        구매시 특별히 지급되는
                        <span className="pink">SUPER 쿠폰</span> 3장이
                        발급됩니다.
                        <div></div>
                        <br />
                        <span className="purple">WEEKLY 쿠폰</span>
                        매주 발급되는 쿠폰으로 해당 쿠폰으로 다음주 헬스
                        티칭가이드 예약을 할 수 있습니다. <div></div>
                        <br />
                        <span className="pink">SUPER 쿠폰</span>
                        이용권 구매시에만 발급되는 쿠폰으로 해당 쿠폰은 발급한
                        다음 날부터 원하는 날짜와 시간에 예약을 할 수 있습니다.
                      </div>
                    </TabBodyText> }

                    { isFrequency &&
                    <TabBodyImg>
                      <img src="./img/common/system_hg_3.png" alt="#"></img>
                    </TabBodyImg>
                    }

                  </TabBodyContent>
                </TabBodyContainer>



            </TabPane>

            { isFrequency === true &
            <TabPane
              tab={<span style={{ fontSize: 14 }}>스마트프리퀀시</span>}
              key="3"
            >
              <AboutContainer>
                <AboutContent>
                  <AboutImg>
                    <img src="./img/common/system_fq_1.png" alt="#"></img>
                  </AboutImg>
                  <AboutTContent>
                    <div className="about-title">스마트 프리퀀시 제도</div>
                    <div className="about-text">
                      스타벅스 프리퀀시 제도처럼 PT를 받을 때마다 스탬프와
                      레벨포인트를 획득하여 레벨 및 출석보상을 받아 운동의
                      즐거움을 느낄 수 있는 제도입니다.
                    </div>
                  </AboutTContent>
                </AboutContent>
              </AboutContainer>

              <TabBodyContainer>
                <TabBodyContent>
                  <TabBodyTextFq>
                    <div className="about-text">
                      담당 트레이너와 PT를 받을 후 평가요청에 별점주기를
                      진행하게 되면 레벨포인트와 출석스탬프가 쌓이게 됩니다.
                      <div></div>
                      <br />
                      <span className="red">
                        수업평가를 하지 않으면 출석 스탬프가 제공되지 않으니
                        수업 후 3시간 이내에 꼭 평가를 진행 바랍니다. 평가
                        별점은 해당 트레이너가 볼 수 없는 부분임으로 평가에
                        알맞는 별점을 주시면 됩니다.<div></div>
                        <br />
                      </span>
                      <span className="green">
                        평가 별점은 해당 트레이너가 볼 수 없는 부분임으로 평가에
                        알맞는 별점을 주시면 됩니다.
                      </span>
                    </div>
                  </TabBodyTextFq>

                  <TabBodyImg>
                    <img
                      className="rating"
                      src="./img/common/system_fq_2.png"
                      alt="#"
                    ></img>
                  </TabBodyImg>
                </TabBodyContent>
              </TabBodyContainer>

              <AboutContainer>
                <AboutContent>
                  <AboutImg>
                    <img
                      className="fq3"
                      src="./img/common/system_fq_3.png"
                      alt="#"
                    ></img>
                  </AboutImg>
                  <AboutTContent>
                    <div className="about-text">
                      지금의 출석도달 단계와 나의 레벨단계가 어디인지 전반적인
                      프리퀀시의 정보를 앱에서 언제든지 한눈에 확인이
                      가능합니다.
                    </div>
                  </AboutTContent>
                </AboutContent>
              </AboutContainer>
              <AboutContainer>
                <AboutContent>
                  <AboutImg>
                    <img
                      className="fq4"
                      src="./img/common/system_fq_4.png"
                      alt="#"
                    ></img>
                  </AboutImg>
                  <AboutTContent>
                    <div className="about-text">
                      출석을 할때마다 해당 레벨포인트가 쌓이고 단계별로 레벨이
                      상승하게 됩니다. <div></div>
                      <br />
                      <span className="green">
                        스마트 프리퀀시에서 제공되는 레벨은 아이언, 브론즈,
                        실버, 골드, 플레티넘, 다이아몬드, 마스터, 그랜드마스터,
                        첼린저, 레전드로 총 10단계 레벨이 구성되어 있습니다.
                      </span>
                    </div>
                  </AboutTContent>
                </AboutContent>
              </AboutContainer>

              <AboutContainer>
                <AboutContent1>
                  <AboutImg>
                    <img
                      className="fq5"
                      src="./img/common/system_fq_5.png"
                      alt="#"
                    ></img>
                  </AboutImg>
                  <AboutTContent>
                    <div className="about-text">
                      PT 평가를 하게되면 1개의 스탬프를 받게되고 점차 쌓여서
                      해당 목표에 도달하면 레벨 보상별 할인권과 보상 상품을 받을
                      수 있습니다. <div></div>
                      <br />
                      이제 PT을 무료로 받고 프리퀀시 제도를 통해 운동의 재미와
                      다양한 보상 혜택을 모두 받아보세요.
                    </div>
                  </AboutTContent>
                </AboutContent1>
              </AboutContainer>
            </TabPane>
            }
          </Tabs>
        </Container>
      )}
    </Layout>
  );
}

export default About3;

/* <GetToken setToken={setToken}></GetToken>
      <GetLogin token={token} setLogin={setLogin}></GetLogin>
      <GetAuth login={login} setLoaded={setLoaded}></GetAuth> */
