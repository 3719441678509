import React, { useState, useEffect } from "react";
import styled from "styled-components";
import pr from "../lib/Pr";
import axios from "axios";
import moment from "moment";
import { Spin } from "antd";
import GetToken from "../lib/GetToken";
import GetLogin from "../lib/GetLogin";
import Layout from "./Layout";
import Loading from "./Loading";
import { Tag, Divider, Button, Modal, Badge } from "antd";
import "antd/dist/antd.css";
import GoPage from "../lib/GoPage";
import GetCid from "../lib/GetCid";
import GetHost from "../lib/GetHost";
import GetMcd from "../lib/GetMcd";
import { BellOutlined, SketchOutlined } from "@ant-design/icons";
import { Carousel } from "antd";
import { useRecoilValue } from "recoil";
import { loginState } from "../lib/State";
import LoginAuth from "../lib/LoginAuth";
import { GetDayDifference } from "../lib/GetDayDifference";
import LoggedBottom from "./LoggedBottom";
import sendWebViewData from "../lib/sendWebViewData";
import get_uuid from "../lib/get_uuid";
import getCenter from "../lib/getCenter";
import TopLogoImage from "./TopLogoImage";
import DoCircuitTopLogo from "./DoCircuitTopLogo";

const Container = styled.div`
  background: #111111;
  display: flex;
  flex-direction: column;
  height: 100%;
  color: white;
`;

const Top = styled.div`
  height: 100px;
`;

const LoggedLogo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  width: 100%;

  li {
    list-style: none;
    display: flex;
  }

  .top-logo {
    width: 140px;
    height: 27px;
  }

  .left-area,
  .right-area {
    width: 50px;
    display: flex;
    justify-content: center;
  }
  .center-area {
    flex: 1;
    display: flex;
    justify-content: center;
  }
`;

const LoggedText = styled.div`
  background: #333333;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  .hello-text {
    color: white;
    font-size: 13px;
  }
`;

const Middle = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const ContentStyle = styled.div`
  background: black;
  height: 200px;
`;

const MiddleBottom = styled.div`
  flex: 1;
  display: flex;
`;

const MiddleBottomContentDoor = styled.div`
  display: flex;
  width: 50%;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  background: #44b674;
`;

const KeyImg = styled.div`
  .key {
    width: 80px;
    padding: 15px 0;
  }
`;

const FontWhite = styled.div`
  color: #ffffff;
  font-size: 14px;
  text-align: center;
`;
//중간 라인 선 긋기
const MiddleBottomContent1 = styled.div`
  /* height: 140px; */
  display: flex;
  width: 50%;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  border-top: 1px solid #333333;
  border-right: 1px solid #333333;
`;

const MiddleBottomContent = styled.div`
  /* height: 140px; */
  display: flex;
  width: 50%;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  border-top: 1px solid #333333;
`;
const MiddleBottomLeft = styled.div`
  text-align: center;
  .font-green {
    color: #44b674;
  }
  .font-green-s {
    color: #44b674;
    font-size: 12px;
  }
`;

const cid = GetCid();

const MiddleBottomRight = () => {
  return (
    <div onClick={() => GoPage("order", cid)}>
      <KeyImg>
        <img className="key" src="./img/common/icon_card.png" alt="#" />
      </KeyImg>
      <FontWhite>등록하기</FontWhite>
    </div>
  );
};

function Logged() {
  const [loaded, setLoaded] = useState(false);
  const login = useRecoilValue(loginState);
  const [mbName, setMbName] = useState("");
  const [edate, setEate] = useState("");
  const [diff, setDiff] = useState(0);
  const [uuid, setUUID] = useState("");
  const [beacon, setBeacon] = useState(false); // 비콘이 근처에 있는지
  const [device, setDevice] = useState({ device: "", sn: "" }); // 휴대폰정보
  const [rows, setRows] = useState([]); // 상단 롤링배너 이미지
  const [badgeCount, setBadgeCount] = useState(0); // 배찌카운터

  // 로그인
  useEffect(() => {
    if (login.loaded === true) {
      if (login.mcd !== "") {
        setLoaded(true);
        sendWebViewData({ k: "mcd", v: login.mcd });

        // 비콘 UUID
        get_uuid({ cid: login.cid })
          .then((result) => {            
            setUUID(result);
          })
          .catch((e) => pr(e));
      } else {
        GoPage("home", login.cid);
      }
    }
  }, [login.mcd, login.token]);

  const loadData = async () => {
    let result = await axios.post(GetHost() + "/gympass/logged_member_one", {
      mcd: login.mcd,
      cid: login.cid,
      token: login.token,
    });
    setMbName(result.data.mb_name);
    if (result.data.edate !== "") {
      setEate(moment(result.data.edate).format("YYYY-MM-DD"));
      let days = GetDayDifference(
        moment().format("YYYY-MM-DD"),
        moment(result.data.edate).format("YYYY-MM-DD")
      );
      setDiff(days);
    }
  };

  useEffect(() => {
    if (loaded === true) {
      loadData();
    }
  }, [loaded]);

  // 송신 : 페이지 로드 완료
  useEffect(() => {
    if (loaded === true) {
      loadData();
      sendWebViewData({ k: "loaded", v: "true" });
    }
  }, [loaded]);

  // 송신 : 스마트키 있음
  useEffect(() => {
    pr('diff='+diff);
    pr('edate='+edate);
    if (edate !== '' && diff >= 0) {
      sendWebViewData({ k: "smartkey", v: "true" });
    }
  }, [edate, diff]);

  // 송신 : 비콘ID
  useEffect(() => {    
    if (uuid !=='' && edate !== '' && diff >= 0) {
      sendWebViewData({ k: "uuid", v: uuid });
    }
  }, [uuid, diff, edate]);

  // 수신 : 비콘 상태
  const onReceiveData = (data) => {
    const { k, v, device, sn } = data;
    if (k === "beacon" && v == "Y") {
      setBeacon(true);
      setDevice({ device: device, sn: sn });
    } else {
      setBeacon(false);
    }

    if( k === "badge") {
      setBadgeCount(v);
    }
  };

  const onDoorOpen = async () => {
    const center = await getCenter({ cid: cid });
    const { door_id } = center;
    const result = await axios.post(GetHost() + "/gympass/open_door", {
      cid: cid,
      door_id: door_id,
      token: login.token,
      mcd: login.mcd,
      device: device.device,
      sn: device.sn,
    });
    if (result.data.ret === "Y") {
      Modal.success({
        title: "성공",
        content: "감사합니다. 출입문이 개방되었습니다.",
      });
    } else {
      Modal.error({ title: "오류", content: result.data.msg });
    }
  };

  // 메시지 수신용 리스너 등록
  useEffect(() => {
    // 웹뷰에서만
    if (window.ReactNativeWebView !== undefined) {
      let os;
      if (/Android/i.test(navigator.userAgent)) {
        os = "android";
      } else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
        os = "ios";
      }

      if (os === "ios") {
        window.addEventListener("message", function (e) {
          const data = JSON.parse(e.data);
          onReceiveData(data);
        });
      } else {
        // android, etc
        document.addEventListener("message", function (e) {
          const data = JSON.parse(e.data);
          onReceiveData(data);
        });
      }
    } else {
      console.log("(통신오류) 웹뷰 기능은 앱에서만 동작합니다.01");
    }
  }, []);

  // 배너 이미지 가져오기
  const loadCenter = async () => {
    const center = await getCenter({ cid: cid });
    setRows(center.banner);
  };

  useEffect(() => {
    loadCenter();
  }, []);  

  return (
    <Layout>
      <LoginAuth />
      {loaded === false ? (
        <Loading />
      ) : (
        <Container>
          <Top>
            <LoggedLogo>
              <li className="left-area"></li>

              <li className="center-area">
                {/* <TopLogoImage /> */}
                <DoCircuitTopLogo />
              </li>

              <li className="right-area" onClick={() => GoPage("notice", cid)}>
                <Badge count={badgeCount} offset={[-22,0]}>
                  <BellOutlined                    
                    style={{
                      color: "white",
                      fontSize: 28,
                    }}
                  />
                </Badge>
                {/* <BellOutlined
                  onClick={() => GoPage("notice", cid)}
                  style={{
                    padding: 5,
                    color: "white",
                    fontSize: 25,
                  }}
                /> */}
              </li>
            </LoggedLogo>
            <LoggedText>
              <div className="hello-text">
                {mbName}님 반갑습니다!! &nbsp;
                {edate !== "" ? (
                  <span>
                    ~{edate}
                    <span style={{ color: "LightGreen" }}> ({diff}일남음)</span>
                  </span>
                ) : (
                  <span> 이용 가능한 스마트키가 없습니다.</span>
                )}
              </div>
            </LoggedText>
          </Top>

          <Middle>
            <Carousel autoplay>
              {rows.map((item, index) => {
                return (
                  <div key={index}>
                    <ContentStyle>
                      <img src={item.src} style={{ width: 400 }} alt="#"></img>
                    </ContentStyle>
                  </div>
                );
              })}
            </Carousel>
            {/* </MiddleTop> */}

            {beacon ? (
              <MiddleBottom>
                <MiddleBottomContentDoor onClick={onDoorOpen}>
                  <MiddleBottomLeft>
                    <KeyImg>
                      <img
                        className="key"
                        src="./img/common/icon_key.png"
                        alt="#"
                      />
                    </KeyImg>

                    <FontWhite>문열기</FontWhite>
                  </MiddleBottomLeft>
                </MiddleBottomContentDoor>

                <MiddleBottomContent>
                  <MiddleBottomRight />
                </MiddleBottomContent>
              </MiddleBottom>
            ) : (
              <MiddleBottom>
                <MiddleBottomContent1>
                  <MiddleBottomLeft>
                    <KeyImg>
                      <img
                        className="key"
                        src="./img/common/icon_key.png"
                        alt="#"
                      />
                    </KeyImg>

                    <FontWhite>
                      <div>
                        <span className="font-green">출입구 가까이</span>
                        &nbsp; 가면
                      </div>
                      문열기 버튼이 &nbsp;
                      <span className="font-green">활성</span> 됩니다.
                    </FontWhite>
                    <div className="font-green-s">
                      * 이용기간 중 버튼 활성 *
                    </div>
                  </MiddleBottomLeft>
                </MiddleBottomContent1>

                <MiddleBottomContent>
                  <MiddleBottomRight />
                </MiddleBottomContent>
              </MiddleBottom>
            )}
          </Middle>

          <LoggedBottom />
        </Container>
      )}
    </Layout>
  );
}

export default Logged;
