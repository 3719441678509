import React, { useState, useEffect } from "react";
import styled from "styled-components";
import "antd/dist/antd.css";
import Layout from "./Layout";
import Loading from "./Loading";
import TopContainer from "./TopContainer";
import CenterMenu from "./CenterMenu";
import GetCid from "../lib/GetCid";
import GetMcd from "../lib/GetMcd";
import GetToken from "../lib/GetToken";
import GetLogin from "../lib/GetLogin";

const Container = styled.div`
  height: 100%;
  background-color: #111111;
`;
const FQAContainer = styled.div`
  width: 100%;
  color: #ffffff;
  justify-content: center;
  display: flex;
  font-size: 14px;
  .faq {
    padding-bottom: 3%;
  }
`;

const FQAContent = styled.div`
  width: 85%;
  /* border: 1px solid pink; */
  justify-content: center;
`;

function About5() {

  // 로그인
  const [login, setLogin] = useState({});
  const cid = GetCid();
  const mcd = GetMcd();
  const token = GetToken();

  useEffect(() => {
    if (token !== undefined && mcd !== undefined) {
      (async () => {
        if (token === "LOGOUT") {
        } else {
          result = await GetLogin({ token: token, mcd: mcd, cid: cid });
          setLogin(result.data);
        }
      })();
    }
  }, []);

  let result;
  const [loaded, setLoaded] = useState();

  useEffect(() => {
    setLoaded(true);
  }, []);

  return (
    <Layout>
      {loaded === false ? (
        <Loading />
      ) : (
        <Container>
          <TopContainer title="센터소개" login={login} />
          <CenterMenu active="about5" />

          <FQAContainer>
            <FQAContent>
              <div className="faq">
                Q.문열기 버튼이 활성화 되지 않을 때는 어떻게 해야하나요?
              </div>
              다음사항을 확인 해 주세요.
              <br />
              *블루투스 기능이 켜져있는지 확인 해 주세요. <br />
              *위치 기능이 켜져있는지, 자세한 위치 권한이 허용되어 있는지 확인
              해주세요. <br />
              *wifi를 꺼주시고, 데이터를 켜주세요.
              <br /> *로그아웃을 하신 후에 다시 로그인을 해주세요. <br />
              *앱을 삭제 후 재설치 해 주세요.
              <br /> *(아이폰의 경우) 기기 OS를 최신으로 업데이트 해주세요.
            </FQAContent>
          </FQAContainer>
        </Container>
      )}
    </Layout>
  );
}

export default About5;

/* <GetToken setToken={setToken}></GetToken>
      <GetLogin token={token} setLogin={setLogin}></GetLogin>
      <GetAuth login={login} setLoaded={setLoaded}></GetAuth> */
