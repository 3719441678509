import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import pr from "../lib/Pr";
import axios from "axios";
import { Spin, Select } from "antd";
import GetToken from "../lib/GetToken";
import GetLogin from "../lib/GetLogin";
import GetMcd from "../lib/GetMcd";
import TopContainer from "./TopContainer";
// import Container from './Container';
import Layout from "./Layout";
import Loading from "./Loading";
import { Tag, Divider, Button, Modal } from "antd";
import { DatePicker, Space } from "antd";
import "antd/dist/antd.css";
import GoPage from "../lib/GoPage";
import GetCid from "../lib/GetCid";
import GetHost from "../lib/GetHost";
import TitleText from "./TitleText";
import moment from "moment";
import { MinusSquareOutlined, CheckSquareOutlined } from "@ant-design/icons";
import queryString from "query-string";
import addComma from "../lib/AddComma";
import GetAgreement from "../lib/GetAgreement";
import getCenter from "../lib/getCenter";
import MyPtMenu from "./MyPtMenu";
import sendWebViewData from "../lib/sendWebViewData";

const { Option } = Select;

const Container = styled.div`
  background-color: #111111;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 145px;
`;
const MyPtMenuContainer = styled.div`
  justify-content: center;
  display: flex;
  border-top: 1px solid #333333;
  border-bottom: 1px solid #333333;
`;
const OderContainer = styled.div`
  /* border: 3px solid orange; */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
  font-size: 16px;
  overflow-y: scroll;
`;
const OderContent = styled.div`
  width: 85%;
  /* border: 3px solid orange; */
  margin: 10px 20px;
`;

const OderTopContainer = styled.div`
  /* margin: 16px; */

  .date-choose {
    padding: 10px 0;
    /* border:1px solid pink; */
  }
`;
const OderBottomContent1 = styled.li`
  display: flex;
  /* padding: 10px 0 16px 0; */
  border-bottom: 1px solid #cccccc;
  padding:10px 0 15px 0;
`;

const OderBottomContent = styled.li`
  /* border: 1px solid blue; */
  border-bottom: 1px solid #cccccc;
  display: flex;
  padding: 10px 0 10px 0;
`;
const OderLeftContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .oder-left-text {
    display: flex;
    align-items: center;
  }
`;

const OderRightContent = styled.div`
  width: 100px;
  display: flex;
  align-items: center;
`;

const cid = GetCid();
const mcd = GetMcd();
const token = GetToken();

function Order() {
  let result;

  const [visible, setVisible] = useState(false);
  const [item, setItem] = useState([]);
  const [member, setMember] = useState({
    name: "",
  });
  const [agree, setAgree] = useState(false);
  const [agreeText, setAgreeText] = useState({});
  const [data, setData] = useState({
    sdate: "",
    discountCoupon: "",
  });
  const [discountCoupon, setDiscountCoupon] = useState([]);
  const [center, setCenter] = useState({});

  // [인증처리 시작]
  // cid > mcd > token > 토큰검사 > 페이지로드
  const [loaded, setLoaded] = useState(false);
  const [login, setLogin] = useState({});

  useEffect(() => {
    if (token !== undefined && mcd !== undefined) {
      (async () => {
        if (token === "LOGOUT") {
          GoPage("home", cid);
        } else {
          result = await GetLogin({ token: token, mcd: mcd, cid: cid });
          setLogin(result.data);
        }
      })();
    }
  }, []);

  useEffect(() => {
    if (login.mb_id !== undefined) {
      (async () => {
        if (login.auth === "user" || login.auth === "teacher") {
          setLoaded(true);

          result = await axios.get(
            GetHost() +
              "/gympass/get_member_one?cid=" +
              cid +
              "&mcd=" +
              mcd +
              "&token=" +
              token
          );

          setMember((state) => {
            return { ...state, name: result.data.name };
          });

          //할인쿠폰
          result = await axios.post(
            GetHost() + "/gympass/get_discount_coupon",
            {
              mcd: login.mb_id,
              cid: cid,
              token: token,
            }
          );
          setDiscountCoupon(result.data);

          // 센터정보
          result = await getCenter({ cid: cid });
          setCenter(result);
        } else {
          GoPage("home", cid);
        }
      })();
    }
  }, [login]);
  // [인증처리 끝]

  // 상품리스트
  useEffect(() => {
    if (cid !== undefined) {
      (async () => {
        result = await axios.post(GetHost() + "/gympass/item_all", {
          cid: cid,
        });
        setItem(result.data);

        //구매약관
        result = await GetAgreement({ cid: cid });
        setAgreeText(result.order);
      })();
    }
  }, [cid]);

  const onChange = (date, dateString) => {
    setData((state) => {
      return { ...state, sdate: dateString };
    });
  };

  const popAgree = () => {
    setVisible(true);
  };

  const callback = (response) => {
    const query = queryString.stringify(response);
    GoPage(`/order_result`, cid + "&mcd=" + login.mb_id + "&" + query);
  };

  const handleDiscountCoupon = (value) => {
    setData((state) => {
      return { ...state, discountCoupon: value };
    });
  };

  const handleOrder = async (param) => {
    let { seq, amount, item_name } = param;

    // 쿠폰검사
    if (data.discountCoupon !== "") {
      param =
        "?cid=" + cid + "&coupon_id=" + data.discountCoupon + "&pid=" + seq;
      result = await axios.get(
        GetHost() + "/gympass/check_discount_coupon" + param
      );
      if (result.data.ret !== "Y") {
        Modal.error({
          title: "오류메시지",
          content: "적용가능한 쿠폰이 아닙니다.",
        });
        return;
      }
      if (result.data.gubun === "rate") {
        // 할인가 적용
        if (result.data.rate > 0) {
          amount = amount - (amount / 100) * result.data.rate;
        }
      }
    }

    // 날짜선택 확인
    if (data.sdate === "") {
      Modal.error({ title: "오류메시지", content: "시작일을 선택하세요." });
      return;
    }

    // 약관동의 확인
    if (agree !== true) {
      Modal.error({
        title: "오류메시지",
        content: "약관 내용을 먼저 확인하세요.",
      });
      return;
    }

    /* 아이엠포트 결제시작 */
    const newData = {
      pg: "html5_inicis", // PG사
      pay_method: "card", // 결제수단
      merchant_uid: `mid_${new Date().getTime()}`, // 주문번호
      amount: amount, // 결제액
      name: item_name, // 주문명
      buyer_name: member.name, // 구매자 이름
      buyer_tel: "", // 매자 전화번호
      buyer_email: "", // 구매자 메일
      buyer_addr: "", // 구매자 주소
      buyer_postcode: "", // 구매자 우편번호
    };

    /* 카드결제 시작정보 저장 */
    const pay_data = {
      cid: cid,
      sdate: data.sdate,
      merchant_uid: newData.merchant_uid,
      pid: seq,
      mcd: login.mb_id,
      amount: amount,
      couponSeq: data.discountCoupon,
    };

    result = await axios.post(GetHost() + "/gympass/cardpay_start", pay_data);
    pr(result.data);
    if (result.data.ret !== "Y") {
      Modal.error({
        title: "오류메시지",
        content: "카드결제 초기화 오류입니다.",
      });
      return;
    }

    // const { IMP } = window;
    // IMP.init(center.userCode);
    // IMP.request_pay(newData, callback);
    // 웹뷰를 통해 값을 다 전달한 후에 엡에서 결제창 호출

    // 웹뷰통신
    sendWebViewData({ k: "pay_start", v: {
      ...newData,
      ...pay_data,
      userCode: center.userCode,
      tierCode: center.tierCode
    } });
  };

  return (
    <Layout>
      {loaded === false ? (
        <Loading />
      ) : (
        <Container>
          <Modal
            title={agreeText.title}
            visible={visible}
            okText="약관 동의함"
            cancelText="약관동의 안함"
            onOk={() => {
              setVisible(false);
              setAgree(true);
            }}
            onCancel={() => {
              setVisible(false);
            }}
          >
            <div
              dangerouslySetInnerHTML={{ __html: agreeText.body }}
              style={{ overflowY: "scroll", height: 300, margin: 0 }}
            ></div>
          </Modal>

          <TopContainer title="카드결제" login={login} />

          <MyPtMenuContainer>
            <MyPtMenu active="3" cid={cid}></MyPtMenu>
          </MyPtMenuContainer>

          <OderContainer>
            <OderContent>
              <OderTopContainer>
                <div className="date-choose">시작일을 선택하세요.</div>
                <div style={{ marginTop: 5 }}>
                  <DatePicker
                    readOnly={true}
                    size="large"
                    onChange={onChange}
                    inputReadOnly
                  />
                </div>
              </OderTopContainer>

              <OderBottomContent1>
                <OderLeftContainer>
                  <div className="oder-left-text">
                    {agree ? (
                      <CheckSquareOutlined
                        style={{ color: "red", fontSize: 20, paddingRight: 5 }}
                      />
                    ) : (
                      <MinusSquareOutlined
                        style={{
                          color: "#cccccc",
                          fontSize: 20,
                          paddingRight: 5,
                        }}
                      />
                    )}
                    (필수) 서비스 이용약관 동의
                  </div>
                </OderLeftContainer>

                <OderRightContent>
                  <Button size="large" type="default" onClick={popAgree}>
                    약관보기
                  </Button>
                </OderRightContent>
              </OderBottomContent1>

              <div>
                {center.discount_coupon === true ? (
                  <>
                    <div>할인쿠폰</div>
                    <Select
                      style={{ width: 200 }}
                      defaultValue=""
                      onChange={handleDiscountCoupon}
                    >
                      <Option value="">선택</Option>
                      {discountCoupon.map((n, i) => {
                        return (
                          <Option key={i} value={n.seq}>
                            {n.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </>
                ) : null}
              </div>

              <ul>
                <div style={{height:10}}></div>
                {item.map((n, i) => (
                  <OderBottomContent key={i}>
                    <OderLeftContainer>
                      {n.gubun === "GX" ? (
                        <>
                          <div>GX 이용권</div>
                          {n.detail} ( {n.period}
                          {n.unit === "1" ? "일" : "개월"} )
                        </>
                      ) : n.gubun === "PT" ? (
                        <>
                          <div>PT 이용권</div>
                          {n.detail}( {n.period}
                          {n.unit === "1" ? "일" : "개월"} )
                        </>
                      ) : n.gubun === "헬스" ? (
                        <>
                          <div>헬스 이용권</div>
                          {n.detail}
                        </>
                      ) : null}
                      <div></div>
                      <div>{addComma(n.amount)}원</div>
                    </OderLeftContainer>

                    <OderRightContent>
                      <Button
                        size="large"
                        type="primary"
                        onClick={() =>
                          handleOrder({
                            seq: n.seq,
                            amount: n.amount,
                            item_name: n.gubun + " " + n.detail,
                          })
                        }
                      >
                        구매하기
                      </Button>
                    </OderRightContent>
                  </OderBottomContent>
                ))}
              </ul>
            </OderContent>
          </OderContainer>
        </Container>
      )}
    </Layout>
  );
}

export default Order;
