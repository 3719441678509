import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Tag, Divider, Button } from "antd";
import "antd/dist/antd.css";
import logo from "../img/logo_08.jpg";
import Column from "rc-table/lib/sugar/Column";
import { FiChevronDown } from "react-icons/fi";
import GoPage from "../lib/GoPage";
import HomeVideo from "./HomeVideo"; // 소개영상

const RootContainer = styled.div`
  a {
    color: inherit;
  }
  .mobile-only {
    display: none;
    @media (max-width: 768px) {
      display: block;
      justify-content: start;
      img {
        width: 100%;
      }
    }
  }
  .secondMobileText {
    @media (max-width: 768px) {
      border: 3px solid pink;
      display: flex;
      text-align: left;
    }
  }
  .mobile-second {
    border: 1px solid green;
  }

  .firstContainer {
    background: url(/img/background1.jpg) no-repeat center right;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 800px;

    @media (max-width: 768px) {
      display: none;
    }
  }
  .firstContent {
    display: flex;
    width: 1060px;
    height: 330px;
    letter-spacing: 1px;
  }
  .firstLeft {
    width: 40%;
  }
  .firstRight {
    width: 60%;
    @media (max-width: 768px) {
      display: none;
    }
  }
  .firstContetnTitle {
    font-size: 30px;
    color: #3e3a39;
  }
  .firstContetnText {
    font-size: 85px;
    color: #264a7b;
    margin-top: -10px;
    margin-left: -7px;
  }
  .buttonContaienr {
    justify-content: flex-end;
    display: flex;
    @media (max-width: 768px) {
    }
  }
  .buttonContent {
    width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    background: #5f7594;
    color: #fff;
    opacity: 70%;
    font-size: 18px;
  }
  .secendContainer {
    background: url(/img/background2.jpg) no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 800px;
    @media (max-width: 768px) {
      display: none;
    }
  }

  .secondContent {
    display: flex;
    width: 1060px;
    height: 330px;
    letter-spacing: 1px;
    @media (max-width: 768px) {
      flex-direction: column;
      border: 2px solid orange;
      align-items: center;
      width: 100%;
    }
  }

  .secondLeft {
    width: 30%;
    @media (max-width: 768px) {
      /* display: none; */
      width: 0%;
      border: 1px solid purple;
    }
  }
  .secondRight {
    width: 70%;
    @media (max-width: 768px) {
      width: 95%;
      border: 3px solid yellowgreen;
    }
  }

  .secendContetnTitle {
    background: #3f968e;
    color: #fff;
    font-size: 32px;
    font-weight: bold;
    @media (max-width: 768px) {
      background: none;
    }
  }
  .secendContetnText {
    font-size: 25px;
    color: #2f2725;
    background: #f7f6f2;
    text-decoration-line: underline;
    text-underline-position: under;
    @media (max-width: 768px) {
      font-size: 20px;
      background: none;
      font-weight: bold;
      margin-top: -10px;
      text-align: center;
    }
  }

  .organization {
    background: url(/img/organization.png) no-repeat;
    width: 100%;
    min-height: 1500px;
    @media (max-width: 768px) {
      background: url(/img/M_organization.png) no-repeat;
      /* background-size: 100%; */
      /* min-height: initial; */
      background-size: 100%;
      border: 5px solid red;
    }
  }
  .teacherIntroContent {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .tacherIntroTitle {
    display: flex;
    font-size: 60px;
    color: #264a7b;
    font-weight: bold;
  }
  .teacherItemContent {
    display: flex;
    flex-flow: wrap;
  }
  .teacherItem {
    display: flex;
    align-items: center;
    flex-direction: column;
    font-size: 35px;
    margin: 13px;
    /* width: 20%; */
    button {
      background: #3f968e;
      color: #fff;
      border: none;
      cursor: pointer;
    }
  }
  .schedule {
    display: flex;
    flex-direction: column;
  }
  .scheduleTitle {
    display: flex;
    justify-content: center;
    color: #274c7e;
    font-size: 60px;
    font-weight: bold;
  }
  .schedulePic {
    display: flex;
    gap: 0.5rem;
    margin-bottom: 2rem;
    justify-content: space-around;
    width: 100%;
    img {
      width: 50%;
    }
    @media (max-width: 768px) {
      flex-direction: column;
      img {
        width: 100%;
      }
    }
  }
  .courseInfoContainer {
    background: url(/img/background4.jpg) no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 800px;
  }
  .courseInfoContent {
    display: flex;
    width: 1700px;
    height: 680px;
  }
  .courseInfoRight {
    width: 40%;
  }
  .courseInfoLeft {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    letter-spacing: 1px;
  }
  .courseInfoTitle {
    font-size: 60px;
    color: #274c7e;
    font-weight: bold;
  }
  .courseTitle {
    width: 200px;
    color: #274c7e;
    font-size: 25px;
    font-weight: bold;
  }
  td {
    font-size: 23px;
  }
  .courseButton {
    background: #3f968e;
    color: #fefefe;
    width: 400px;
    height: 60px;
    border: none;
    font-size: 30px;
    cursor: pointer;
  }
  .blackBold {
    font-weight: bold;
  }
  .fontRed {
    color: #e60012;
  }
  .fontBlue {
    color: #274c7e;
  }
  .fontYellow {
    color: #fff100;
  }
  .telNumberContainer {
    height: 860px;
    background: #3f968e;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 800px;
    color: #fefefe;
  }
  .telNumberContent {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    width: 1300px;
    height: 700px;
  }
  .footerLogoContainer {
    display: flex;
    justify-content: center;
    height: 240px;
    background: #274c7e;
  }
  .footerLogoContent {
    display: flex;
    align-items: center;
  }
`;

const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background-color: #264a7b;
  color: #fff;
  font-size: 17px;
  @media (max-width: 768px) {
    height: 44px;
    width: 100%;
    img {
      width: 110px;
    }
  }
`;
const MenuListItem = styled.div`
  display: flex;
  list-style: none;
  justify-content: center;
  align-items: center;
  li {
    width: 170px;
    text-align: center;
    cursor: pointer;
  }
  button {
    background-color: #3f968e;
  }
  @media (max-width: 768px) {
    display: none;
  }
`;

function Home() {
  // 휴대폰인지 아닌지 체크
  var mobile = /iphone|ipad|ipod|android/i.test(
    navigator.userAgent.toLowerCase()
  );
  if (mobile === true) {
    window.location.href = "/mobile_home";
    return null;
  }

  return (
    <RootContainer>
      <MenuContainer>
        <div className="height" style={{ height: 42 }}></div>
        <div>
          <a href="/">
            <img src={logo}></img>
          </a>
        </div>

        <div className="height" style={{ height: 38 }}></div>

        <MenuListItem>
          <li>
            <a href="#page1">HOME</a>
          </li>
          <li>
            <a href="#page2">예방운동관리사 소개</a>
          </li>
          <li>
            <a href="#page3">조직도</a>
          </li>
          <li onClick={() => GoPage("prop1")}>
            <a href="#page4">교수소개</a>
          </li>
          <li>
            <a href="#page5">교육일정</a>
          </li>
          <li>
            <a href="#page6">등록안내</a>
          </li>
          <div>
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSeOOptTF-FfoTTZYsZS8ADdiJjDKqFU0nZvH01XM9uxZLsbYA/viewform"
              target="_blank"
            >
              <button style={{ padding: "3px 10px" }}>과정등록 바로가기</button>
            </a>
          </div>
        </MenuListItem>
        <div style={{ height: 27 }}></div>
      </MenuContainer>

      <HomeVideo></HomeVideo>

      <a name="page1"></a>
      <div className="firstContainer">
        <div className="firstContent">
          <div className="firstRight">
            <div className="firstContetnTitle">
              2024년 제4기 부산광역시 위탁
            </div>
            <div className="firstContetnText">
              예방운동관리사
              <br />
              양성과정 <br />
              <div className="buttonContaienr">
                <div className="buttonContent">
                  <div>
                    <a href="#page2">더보기</a>
                  </div>
                  <FiChevronDown />
                </div>
              </div>
            </div>

            <div className="firstLeft"></div>
          </div>
        </div>
      </div>

      <div className="mobile-only">
        <img src="/img/M_background1.jpg"></img>
      </div>

      <a name="page2"></a>
      <div className="secendContainer">
        <div className="secondContent">
          <div className="secondLeft"></div>
          <div className="secondRight">
            <div className="secendContetnTitle">
              예방운동전문가로 포스트코로나 시대를 준비하자!
            </div>
            <div style={{ height: 20 }}></div>
            <div className="secendContetnText">
              인생 제2막, 50대 이후의 새로운 삶에 대한 도전!
              <br />
              <div className="secondMobileText">
                부산시가 응원하고, 부산외국어대학교가 그 비젼을 제시합니다.
                <br />
                시대가 요구하는 새로운 전문가, 새로운 일자일, 새로운 창업아이템!
                <br />
                예방운동관리사는 이 모든 요구에 가장 부합되는 전문가입니다.
                <br />
                도전하십시요! 나와 내 가족은 물론, 타인의 건강 전문가가 될
                것입니다.
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="mobile-only">
          <img src="/img/M_background2.jpg"></img>
          <div className="mobile-second">
            <div className="secondContent">
              <div className="secondLeft"></div>
              <div className="secondRight">
                <div className="secendContetnTitle">
                  예방운동전문가로 포스트코로나 시대를 준비하자!
                </div>
                <div style={{ height: 20 }}></div>
                <div className="secendContetnText">
                  인생 제2막, 50대 이후의 새로운 삶에 대한 도전!
                  <br />
                  <div className="secondMobileText">
                    부산시가 응원하고, 부산외국어대학교가 그 비젼을 제시합니다.
                    <br />
                    시대가 요구하는 새로운 전문가, 새로운 일자일, 새로운
                    창업아이템!
                    <br />
                    예방운동관리사는 이 모든 요구에 가장 부합되는 전문가입니다.
                    <br />
                    도전하십시요! 나와 내 가족은 물론, 타인의 건강 전문가가 될
                    것입니다.
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <a name="page3"></a>
      <div className="organization"></div>

      <a name="page4"></a>
      <div className="teacherIntroContent">
        <div style={{ height: 150 }}></div>
        <div className="tacherIntroTitle">교수 소개</div>
        <div className="teacherItemContent">
          <div className="teacherItem">
            <img src="./img/pic1.jpg" alt="#"></img>
            <div>박일봉 교수</div>
            <button onClick={() => GoPage("prop1")}>프로필 보러가기</button>
          </div>
          <div className="teacherItem">
            <img src="./img/pic2.jpg" alt="#"></img>
            <div>이채관 교수</div>
            <button onClick={() => GoPage("prop2")}>프로필 보러가기</button>
          </div>
          <div className="teacherItem">
            <img src="/img/pic3_2023.png" alt="#"></img>
            <div>정지환 교수</div>
            <button onClick={() => GoPage("prop3")}>프로필 보러가기</button>
          </div>
          <div className="teacherItem">
            <img src="./img/pic4.png" alt="#"></img>
            <div>이자연 교수</div>
            <button onClick={() => GoPage("prop4")}>프로필 보러가기</button>
          </div>
          <div className="teacherItem">
            <img src="./img/pic5.jpg" alt="#"></img>
            <div>강슈호 교수</div>
            <button onClick={() => GoPage("prop5")}>프로필 보러가기</button>
          </div>
        </div>
        <div style={{ height: 150 }}></div>
      </div>

      <a name="page5"></a>
      <div className="schedule" style={{ maxWidth: "1602px", margin: "auto" }}>
        <div style={{ height: 50 }}></div>
        <div className="scheduleTitle">세부교육내용 및 일정</div>
        <div style={{ height: 94 }}></div>
        <div className="schedulePic">
          <img src="/img/data2024_1.png"></img>
          <img src="/img/data2024_2.png"></img>
        </div>
        <span style={{ textAlign: "right", marginBottom: "1rem" }}>
          ※ 교육일정은 사정에 따라 변경될 수 있습니다.
        </span>
      </div>

      <a name="page6"></a>
      <div className="courseInfoContainer">
        <div className="courseInfoContent">
          <div className="courseInfoRight"></div>
          <div className="courseInfoLeft">
            <div className="courseInfoTitle">2024년 제4기 과정등록 안내</div>
            <tr>
              <td className="courseTitle">신청기간</td>
              <td>
                <span className="blackBold">
                  2024년 2월 19일(월) ~ 2월 29일 (목)
                </span>
              </td>
            </tr>
            <tr>
              <td className="courseTitle">면접 예정일</td>
              <td>
                <span className="blackBold">개별연락</span>
              </td>
            </tr>
            <tr>
              <td className="courseTitle">교육기간</td>
              <td>
                <span className="blackBold">
                  1학기 : 2024년 3월 12일(화) ~ 6월 18일(금)
                </span>
                <div>(매주 화, 목요일 18:30~21:30)</div>
                <span className="blackBold">
                  2학기 : 2024년 9월 3일(화) ~ 11월 29일(금)
                </span>
                <div>(매주 화요일 18:30~21:30)</div>
              </td>
            </tr>
            <tr>
              <td className="courseTitle">모집대상 및 인원</td>
              <td>
                <span className="blackBold">
                  생애재설계를 꿈꾸는 만50~64세의 부산시민 남,여 누구나
                </span>
                <br />
                <span className="fontBlue">
                  (주민등록등본 상 거주지가 부산이어야 지원 가능)
                </span>
              </td>
            </tr>
            <tr>
              <td className="courseTitle">수강료</td>
              <td>
                <span className="blackBold">본인 10만원</span>
                <span className="fontBlue">
                  (교육비 70만원 중 60만원은 부산시가 지원)
                </span>
              </td>
            </tr>
            <tr>
              <td className="courseTitle">교육장소</td>
              <td>
                <span className="blackBold">부산외국어대학교 평생교육원</span>
              </td>
            </tr>
            <tr>
              <td className="courseTitle">신청방법</td>
              <td>
                <div>
                  <span className="blackBold">방문 접수, 온라인 접수</span>
                  (부산시 금샘로 486번길 65 부산외대 A302호 평생교육원)
                  <br />
                  <span className="fontRed"> 전화접수 불가</span>
                </div>
                <div>
                  <span className="blackBold" style={{ marginRight: 10 }}>
                    인터넷 접수
                  </span>
                  <a
                    href="https://docs.google.com/forms/d/e/1FAIpQLSeOOptTF-FfoTTZYsZS8ADdiJjDKqFU0nZvH01XM9uxZLsbYA/viewform"
                    target="_blank"
                  >
                    <button className="courseButton">구글폼 접수</button>
                  </a>
                </div>
              </td>
            </tr>
            <tr style={{ height: 40 }}></tr>
          </div>
        </div>
      </div>

      <div className="telNumberContainer">
        <div className="telNumberContent">
          <div className="telNumberText">
            <div className="fontYellow" style={{ fontSize: 60 }}>
              교육문의
            </div>
            <div style={{ fontSize: 45 }}>
              "친절하고 빠른 상담으로 도와드리겠습니다."
            </div>
            <div style={{ fontSize: 135, fontWeight: "bold" }}>
              TEL:051)509-6392
            </div>
            <div style={{ fontSize: 40 }}>상담가능시간: 평일 10:00 ~ 16:00</div>
          </div>
        </div>
      </div>
      <div className="footerLogoContainer">
        <div className="footerLogoContent">
          <img src="./img/footer_logo1.jpg" alt="#"></img>
          <div style={{ width: 50 }}></div>

          <a href="https://www.bufs.ac.kr/">
            <img src="./img/footer_logo2.jpg" alt="#"></img>
          </a>
        </div>
      </div>
    </RootContainer>
  );
}

export default Home;
