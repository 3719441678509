import React from "react";
import styled from "styled-components";
import "antd/dist/antd.css";
import "antd/dist/antd.css";
import GoPage from "../lib/GoPage";
import logo from "../img/logo_08.jpg";

const MenuContent = styled.ul`
  width: 100%;
  height: 80px;
  font-size: 20px;
  background-color: #dcdddd;
  justify-content: center;
  display: flex;
  margin-bottom: 0;
  li {
    text-align: center;
    width: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #2e2f29;
    cursor: pointer;
  }
  .underline {
    text-decoration: underline #244673;
    text-underline-position: under;
  }

  .now-menu-is-active {
  }
`;

const RootContainer = styled.div`
  .propContainer {
    color: #fff;
    background: url(/img/teacher3_2023.png) no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 800px;
  }
  .propContent {
    display: flex;
    width: 1060px;
    height: 330px;
    letter-spacing: 1px;
  }

  .propLeft {
    width: 50%;
  }
  .propRight {
    width: 50%;
  }

  .propContetnTitle {
    font-size: 30px;
    font-family: RixCrossRoadR;
    color: #365b8b;
  }
  .propContetnText {
    font-size: 100px;
    color: #2f2725;
  }
`;

const ProfileContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
const ProfileContent = styled.div`
  width: 940px;
  color: #484848;
`;
const ProfileTitle = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  font-size: 30px;
  margin-top: 30px;
  margin-bottom: 30px;
  color: #274c7e;
`;

const ProfileInfo = styled.div`
  display: flex;
  justify-content: left;
  font-size: 25px;
  flex-direction: column;
`;
const BookListContent = styled.div`
  display: flex;
  flex-flow: wrap;
  justify-content: start;

  .bookItem {
    /* border: 3px solid red; */
    margin: 5px;
  }
  .bookName {
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 20px;
  }
`;

const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background-color: #264a7b;
  color: #fff;
  font-size: 17px;
`;

const MenuListItem = styled.div`
  display: flex;
  list-style: none;
  justify-content: center;
  align-items: center;
  li {
    width: 170px;
    text-align: center;
    cursor: pointer;
  }
  button {
    background-color: #3f968e;
  }
`;

function Prop3() {
  let nowActiveMenu1 = "";
  let nowActiveMenu2 = "";
  let nowActiveMenu3 = "";
  let nowActiveMenu4 = "";
  let nowActiveMenu5 = "";

  // if(active==="about1") {
  //   nowActiveMenu1 = "now-menu-is-active";
  // }
  // if (active === "about2") {
  //   nowActiveMenu2 = "now-menu-is-active";
  // }
  // if(active==="about3") {
  //   nowActiveMenu3 = "now-menu-is-active";
  // }
  // if (active === "about4") {
  //   nowActiveMenu4 = "now-menu-is-active";
  // }
  // if (active === "about5") {
  //   nowActiveMenu5 = "now-menu-is-active";
  // }

  const GoPage = (go) => {
    if (go === "prop1") {
      window.location.href = "/Prop1";
    }
    if (go === "prop2") {
      window.location.href = "/Prop2";
    }
    if (go === "prop3") {
      window.location.href = "/Prop3";
    }
    if (go === "prop4") {
      window.location.href = "/Prop4";
    }
    if (go === "prop5") {
      window.location.href = "/Prop5";
    }
  };

  return (
    <RootContainer>
      <MenuContainer>
        <div style={{ height: 42 }}></div>
        <div>
          <img src={logo}></img>
        </div>
        <div style={{ height: 38 }}></div>
        <MenuListItem>
          <li>
            <a href="/" style={{ color: "inherit" }}>
              HOME
            </a>
          </li>
          <li>
            <a href="/#page2" style={{ color: "inherit" }}>
              예방운동관리사 소개
            </a>
          </li>
          <li>
            <a href="/#page3" style={{ color: "inherit" }}>
              조직도
            </a>
          </li>
          <li onClick={() => GoPage("prop1")}>
            <a href="/#page4" style={{ color: "inherit" }}>
              교수소개
            </a>
          </li>
          <li>
            <a href="/#page5" style={{ color: "inherit" }}>
              교육일정
            </a>
          </li>
          <li>
            <a href="/#page6" style={{ color: "inherit" }}>
              등록안내
            </a>
          </li>
          <div>
            <button>교육등록바로가기</button>
          </div>
        </MenuListItem>
        <div style={{ height: 27 }}></div>
      </MenuContainer>
      <MenuContent>
        <li className={nowActiveMenu1} onClick={() => GoPage("prop1")}>
          박일봉 교수
        </li>
        <li className={nowActiveMenu2} onClick={() => GoPage("prop2")}>
          이채관 교수
        </li>
        <li className={nowActiveMenu3} onClick={() => GoPage("prop3")}>
          <span className="underline">정지환 교수</span>
        </li>
        <li className={nowActiveMenu4} onClick={() => GoPage("prop4")}>
          이자연 교수
        </li>
        <li className={nowActiveMenu5} onClick={() => GoPage("prop5")}>
          강슈호 교수
        </li>
      </MenuContent>

      <div className="propContainer">
        <div className="propContent">
          <div className="propLeft"></div>
          <div className="propRight">
            <div className="propContetnTitle">
              모빌리티 컨디셔닝 트레이닝 전문가
            </div>

            <div className="propContetnText">
              정 지 환 <span style={{ fontSize: 70 }}>교수</span>
              <br />
            </div>
          </div>
        </div>
      </div>

      <ProfileContainer>
        <ProfileContent>
          <ProfileTitle>
            <img
              style={{ height: 25, marginRight: 5 }}
              src="./img/prop_icon.jpg"
            />
            학력사항
          </ProfileTitle>
          <ProfileInfo>
            · 동아대학교 체육대학 경기지도학 전공 <br />
            · 부산가톨릭대학교 보건과학대학 물리치료학 전공 <br />
            · 부산외국어대학교 스포츠재활전공 체육학석사 <br />
            · 부산외국어대학교 스포츠건강융합학과 박사과정 <br />
          </ProfileInfo>

          <ProfileTitle>
            <img
              style={{ height: 25, marginRight: 5 }}
              src="./img/prop_icon.jpg"
            />
            교육 경력 사항
          </ProfileTitle>
          <ProfileInfo>
            · 전)동원과학기술대학교 스포츠재활과 겸임교수
            <br />
            · 전)부산본병원 물리치료실 주임
            <br />
            · 전)프레티올라 운동센터 대표
            <br />
            · 현)부산외국어대학교 스포츠재활 연구소 수석연구원
            <br />
            · 현)부산외국어대학교 스포츠재활학과 겸임교수
            <br />
            · 현)사단법인 대한기능재활운동협회 사무국장
            <br />
            · 현)한국기능운동학회 사무국장
            <br />
            · 현)스마트짐 다대포점 대표이사
            <br />
          </ProfileInfo>

          <ProfileTitle>
            <img
              style={{ height: 25, marginRight: 5 }}
              src="./img/prop_icon.jpg"
            />
            자격 및 면허
          </ProfileTitle>
          <ProfileInfo>
            · 물리치료사
            <br />
            · 인강공학기사
            <br />
            · 생활스포츠지도사2급(보디빌딩, 수영)
            <br />
            · 대한정형도수치료학회 정회원
            <br />
          </ProfileInfo>
          <ProfileTitle>
            <img
              style={{ height: 25, marginRight: 5 }}
              src="./img/prop_icon.jpg"
            />
            논문
          </ProfileTitle>
          <ProfileInfo>
            An Epidemiological Investigagion Study of Social Baseball Club
            Members. <br /> - 부산외국어대학교 석사학위 논문, 2020
          </ProfileInfo>
          <div style={{ height: 200 }}></div>
        </ProfileContent>
      </ProfileContainer>
    </RootContainer>
  );
}

export default Prop3;
