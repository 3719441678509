import { atom } from "recoil";

export const loginState = atom({
  key: "loginState",
  default: {
    token: "",
    cid: "",
    mcd: "",
    mb_seq: "",
    auth: "",
    loaded: false,
  },
});