import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import pr from "../lib/Pr";
import Axios from "axios";
import moment from "moment";
import { Spin } from "antd";
import GetToken from "../lib/GetToken";
import GetLogin from "../lib/GetLogin";
// import Container from './Container';
import Layout from "./Layout";
import Loading from "./Loading";
import { Tag, Divider, Button, Input, Modal } from "antd";
import "antd/dist/antd.css";
import GoPage from "../lib/GoPage";
import GetCid from "../lib/GetCid";
import TitleText from "./TitleText";
import axios from "axios";
import GetHost from "../lib/GetHost";
import BackBtn from "./BackBtn";


const RootContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const TopContainer = styled.div`
  flex: 1;
  /* padding: 5px; */
  display: flex;
  justify-content: center;
  Button {
    height: 40px;
    font-size: 18px;
  }
`;
const Content = styled.div`
  width: 90%;
`;

const JoinLogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding-top: 30px; */
`;
const JoinLogoContent = styled.div`
  text-align: center;
  img {
    width: 230px;
  }
`;

const JoinTextContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
`;

const JoinText = styled.div`
  width: 80%;
  .login-text1 {
    font-size: 20px;
    font-weight: bold;
  }
  .login-text2 {
    font-size: 16px;
    color: #7f7f7f;
  }
`;

const JoinInputContainer = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: center;

`;

const JoinInputContent = styled.div`
  width: 80%;
  Input {
    border: none;
    align-items: center;
  }
  input:focus {
    outline: 0;
    box-shadow: none;
  }
`;
const JoinInputTitle = styled.div`
  font-size: 14px;
  display: flex;
  border-bottom: 1px solid #7f7f7f;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
`;
const JoinLeft = styled.div`
  width: 140px;
  text-align: left;
  font-size: 16px;
  align-items: center;
`;


const BottomButtonContainer = styled.div`
  position: fixed;
  width: 100%;
  bottom: 0;
  background-color: #4c6eec;
`;

const BottomButton = styled.div`
  /* background: #808080; */
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
`;

const JoinButton = styled.div`
  /* border: 1px solid yellow; */
  width: 100%;
  text-align: center;
  /* background-color: #4c6eec; */
  font-size: 18px;
  color: #ffffff;
  padding: 10px;
`;


function Login() {
  let result;

  const [token, setToken] = useState(); // --- #인증1
  const [login, setLogin] = useState({}); // --- #인증2
  const [loaded, setLoaded] = useState();
  const [cid, setCid] = useState(""); // GET: cid
  useEffect(() => {
    setCid(GetCid());
  }, []);
  const phoneRef = useRef();
  const nameRef = useRef();
  const pw1Ref = useRef();
  const pw2Ref = useRef();

  const handleNext = async() => {
     const data = {
      cid:cid,
      phone:phoneRef.current.state.value,
      name:nameRef.current.state.value,
      pw1:pw1Ref.current.state.value,
      pw2:pw2Ref.current.state.value,
    }
    result = await axios.post(GetHost()+'/gympass/join2',data);
    if(result.data.ret==='N') {
      Modal.error({ title:'오류 메시지',content:result.data.msg});
      return;
    }
    GoPage('join3',cid);
  }


  // 로그아웃
  // useEffect(() => {
  //   if (loaded === false) {
  //     GoLogout();
  //   }
  // }, [loaded]);

  // 토큰
  // useEffect(() => {
  //   (async () => {
  //     result = await GetToken();
  //     setToken(result);
  //   })();
  // }, []);

  // // 인증
  // useEffect(() => {
  //   (async () => {
  //     if (token !== undefined && token !== "LOGOUT") {
  //       result = await GetLogin(token);
  //       setLogin(result.data);
  //     }
  //   })();
  // }, [token]);

  // // 로그인
  // useEffect(() => {
  //   if (login !== undefined && login.mb_id !== undefined) {
  //     // setLoaded(true);
  //   }
  // }, [login]);

  // 로그인 연장
  // const url = GetHost() + "/member/keep_login";

  useEffect(() => {
    setLoaded(true);
  }, []);

  return (
    <Layout>
      {loaded === false ? (
        <Loading />
      ) : (
        <>
          <BackBtn go="home" cid={cid} onClick={() => GoPage("login")} />

          <RootContainer>
            <TopContainer>
              <Content>
                <JoinLogoContainer>
                  <JoinLogoContent>
                    <img
                      src="/img/common/main_logo_black.png
"
                      alt="#"
                    ></img>
                  </JoinLogoContent>
                </JoinLogoContainer>

                <JoinTextContainer>
                  <JoinText>
                    <div className="login-text1">회원가입</div>
                    <div className="login-text2">회원정보를 입력하세요.</div>
                  </JoinText>
                </JoinTextContainer>

                <JoinInputContainer>
                  <JoinInputContent>
                    <JoinInputTitle>
                      <JoinLeft>이름</JoinLeft>
                      <Input ref={nameRef}></Input>
                    </JoinInputTitle>

                    <JoinInputTitle>
                      <JoinLeft>휴대폰번호</JoinLeft>
                      <Input
                        ref={phoneRef}
                        placeholder="-없이 숫자만 입력"
                      ></Input>
                      {/* <div style={{ marginTop: 15 }}></div> */}
                    </JoinInputTitle>

                    <JoinInputTitle>
                      <JoinLeft>비밀번호</JoinLeft>
                      <Input ref={pw1Ref} type="password"></Input>
                      {/* <div style={{ marginTop: 15 }}></div> */}
                    </JoinInputTitle>

                    <JoinInputTitle>
                      <JoinLeft>비밀번호확인</JoinLeft>
                      <Input ref={pw2Ref} type="password"></Input>
                    </JoinInputTitle>
                  </JoinInputContent>
                </JoinInputContainer>
              </Content>
            </TopContainer>

            <BottomButtonContainer>
              <BottomButton>
                <JoinButton onClick={handleNext}>다음</JoinButton>
              </BottomButton>
            </BottomButtonContainer>
          </RootContainer>
        </>
      )}
    </Layout>
  );
}

export default Login;