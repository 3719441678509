import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Tag, Divider, Button } from "antd";
import "antd/dist/antd.css";
import logo from "../img/M_logo.jpg";
import Column from "rc-table/lib/sugar/Column";
import { FiChevronDown } from "react-icons/fi";
import GoPage from "../lib/GoPage";
import { MenuOutlined } from "@ant-design/icons";
import { Carousel } from "antd";
import { Drawer } from "antd";
import DrawerMenu from "./DrawerMenu";

const TopContent = styled.ul`
  display: flex;
  background-color: #264a7b;
  align-items: center;
  margin-bottom: 0;
  padding: 10px 0;
  li {
    text-align: center;
    display: block;
  }
  .center-area {
    flex: 1;
  }
  .left-area,
  .right-area {
    width: 50px;
  }
`;
const ProfileContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
const ProfileContent = styled.div`
  width: 940px;
  color: #484848;
`;
const ProfileTitle = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  font-size: 22px;
  margin-top: 30px;
  margin-bottom: 30px;
  color: #274c7e;
`;

const ProfileInfo = styled.div`
  display: flex;
  justify-content: left;
  font-size: 16px;
  flex-direction: column;
`;


function MobileProp1() {

  return (
    <div style={{ width: "100%" }}>
      <DrawerMenu />

      <div>
        <img src="/img/M_teacher5_img.jpg" width="100%"></img>
      </div>

      <ProfileContainer style={{ paddingLeft: "5%", paddingRight: "5%" }}>
        <ProfileContent>
          <ProfileTitle>
            <img
              style={{ height: 25, marginRight: 5 }}
              src="./img/prop_icon.jpg"
            />
            학력사항
          </ProfileTitle>
          <ProfileInfo>
            · 일본 와코대학 예술학과(농구선수) 졸업 <br />
            · 부산외국어대학교 일반대학원 스포츠재활학과 석사 졸업
            <br />· 부산외국어대학교 스포츠건강융합학과 박사과정 재학 중
          </ProfileInfo>

          <ProfileTitle>
            <img
              style={{ height: 25, marginRight: 5 }}
              src="./img/prop_icon.jpg"
            />
            교육 경력 사항
          </ProfileTitle>
          <ProfileInfo>
            · 전) 샤샤필라테스 매니저 <br />· 현) 버티힐즈 필라테스 & PT 원장{" "}
            <br />· 현) 대한기능재활운동협회 KARFE 교육이사 <br />
          </ProfileInfo>

          <ProfileTitle>
            <img
              style={{ height: 25, marginRight: 5 }}
              src="./img/prop_icon.jpg"
            />
            자격 및 면허
          </ProfileTitle>
          <ProfileInfo>
            · Powerplate Master (USA) <br />· Ultimateinstability Aquabag Master
            Trainer(Netherlands) <br />· STC Master Trainer
            <br /> · Powerplate Master
            <br /> · RAD Master Trainer (USA) <br />· CFSC Level 1, 2 (Certified
            Functional Strength Coach) <br />· DNS A, B, Pilates, Foot Course
            <br />· PMA Membership(Pilates Method Alliance at USA)
            <br /> · NASM CPT, CES, PES
          </ProfileInfo>

          <ProfileTitle>
            <img
              style={{ height: 25, marginRight: 5 }}
              src="./img/prop_icon.jpg"
            />
            수상 경력
          </ProfileTitle>
          <ProfileInfo>
            · 2014 스포츠서울 Innovation 기업&브랜드 혁신리더 부문 대상 (2014)
            <br />
            · NASM PES - 운동수행능력 향상 트레이닝 우수학술도서 선정 (2015)
            <br />· 2016 헤럴드경제 대한민국 가치경영대상 (2016)
            <br />
          </ProfileInfo>

          <ProfileTitle>
            <img
              style={{ height: 25, marginRight: 5 }}
              src="./img/prop_icon.jpg"
            />
            논문
          </ProfileTitle>
          <ProfileInfo>
            · 필라테스 운동이 무지외반증의 발 변형각도에 미치는 영향 <br />
            - 부산외국어대학교 석사학위 논문, 2020
            <br />
          </ProfileInfo>

          <ProfileTitle>
            <img
              style={{ height: 25, marginRight: 5 }}
              src="./img/prop_icon.jpg"
            />
            방송 및 잡지 출현
          </ProfileTitle>
          <ProfileInfo>
            · 2014년 키즈맘 출산 후 다이어트필라테스 촬영
            <br /> · 2015년 JTBC (닥터의 승부) 출연
            <br /> · 2016년 키즈맘 아리와 함께 하는 필라테스 촬영 <br />· 2017년
            체널A (닥터 지바고) 출연
          </ProfileInfo>
          <div style={{ height: 50 }}></div>
        </ProfileContent>
      </ProfileContainer>
    </div>
  );
}

export default MobileProp1;
