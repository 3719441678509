import React, { useState, useEffect } from "react";
import styled from "styled-components";
import pr from "../lib/Pr";
import axios from "axios";
import moment from "moment";
import { Spin } from "antd";
import GetToken from "../lib/GetToken";
import Loading from "./Loading";
import { Tag, Divider, Button } from "antd";
import "antd/dist/antd.css";
import GoPage from "../lib/GoPage";
import GetCid from "../lib/GetCid";
import GetHost from "../lib/GetHost";
import GetMcd from "../lib/GetMcd";
import { BellOutlined, ConsoleSqlOutlined } from "@ant-design/icons";
import { Carousel } from "antd";
import { useRecoilValue } from "recoil";
import { loginState } from "../lib/State";
import LoginAuth from "../lib/LoginAuth";
import { GetDayDifference } from "../lib/GetDayDifference";
import LoggedBottom from "./LoggedBottom";
import TopContainer from "./TopContainer";
import { set } from "date-fns";

let height = document.documentElement.offsetHeight;
height = height - 200;


const Layout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #111111;
  color: #fff;
`;
const TopContent = styled.div`
  height: 100px;
  background: #111111;
  font-size: 20px;
  font-weight: bold;

`;
const MiddleContent = styled.div`
  flex: 1;
  overflow-y: scroll;
  height: ${height}px;
  display: flex;
  justify-content: center;
`;
const AlramTextContainer = styled.div`
  width: 90%;
  .alram-text1 {
    padding-bottom: 15px;
    border-bottom: 1px solid #cccccc;
  }
`;
const AlramTextContent = styled.div`
  font-size: 14px;
  border-bottom: 1px solid #cccccc;
  padding:15px 0;

  .alram-date {
    color: #44b674;
  }
  .alram-text2 {
  }
`;
const BottomButton = styled.div`
  height: 50px;
  color: #ffffff;
  font-size: 16px;
  background: #4c6eec;
  display: flex;
  align-items: center;
  justify-content: center;
`;
function Test1() {
  const [loaded, setLoaded] = useState();
  const [data, setData] = useState([]);


   // 로그인 / 로그아웃 처리
  const login = useRecoilValue(loginState);
  useEffect(() => {
    if (login.loaded === true) {
      if (login.mcd !== "") {
        setLoaded(true);
        load_data();

      } else {
        GoPage("home", login.cid);
      }
    }
  }, [login.mcd, login.token]);


  async function load_data() {
    const mcd = login.mcd;
    const cid = login.cid;
    const token = login.token;
    let result = await axios.post(GetHost() + "/push/get_list", {
      cid: cid,
      mcd: mcd,
      token: token,
       });
    console.log(result.data);
    setData(result.data);
  }


  return (
    <Layout>
      <LoginAuth />
      {loaded === false ? (
        <Loading />
      ) : (
        <>
          <TopContent>
            <TopContainer />
          </TopContent>

          <MiddleContent>
            <AlramTextContainer>
              <div className="alram-text1">
                <span style={{ fontSize: 20 }}>알림</span>
              </div>
              {data.map((item, index) => {
                const regdate = item.regdate;
                const message = item.message;
                return (
                  <AlramTextContent key={index}>
                    <div className="alram-date">{regdate}</div>
                    <div className="alram-text">[알림] {message}</div>
                  </AlramTextContent>
                );
              })}
              <div style={{ height: 30 }}></div>
            </AlramTextContainer>
          </MiddleContent>

          <BottomButton className="bottom" onClick={() => GoPage("logged")}>
            확인
          </BottomButton>
        </>
      )}
    </Layout>
  );
}

export default Test1;
