import React, { useState, useEffect } from "react";
import styled from "styled-components";
import GoPage from "../lib/GoPage";
import GetCid from "../lib/GetCid";
import getCenter from "../lib/getCenter";
import pr from "../lib/Pr";

const Bottom = styled.div`
  height: 60px;
  display: flex;

  li {
    border-top: 1px solid #333333;
    width: 100%;
    background: #111111;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    img {
      width: 30px;
    }
  }
`;

const cid = GetCid();

function LoggedBottom() {
  const [menuFreequency, setMenuFreequnecy] = useState(false);

  const onCenter = async () => {
    const center = await getCenter({ cid: cid });
    setMenuFreequnecy(center.isFrequency);
  };

  useEffect(() => {
    onCenter();
  }, []);

  return (
    <Bottom>
      <li onClick={() => GoPage("my_pt", cid)}>
        <img src="./img/common/icon_hg.png" alt="#" />
        <div>내수업</div>
      </li>
      { menuFreequency === true && (
        <li onClick={() => GoPage("my_fq", cid)}>
          <img src="./img/common/icon_frequency.png" alt="#" />
          <div>프리퀀시</div>
        </li>
      ) }
      <li onClick={() => GoPage("about1", cid)}>
        <img src="./img/common/icon_center.png" alt="#" />
        <div>센터소개</div>
      </li>
      <li onClick={() => GoPage("my_order", cid)}>
        <img src="./img/common/icon_cart.png" alt="#" />
        <div>구매내역</div>
      </li>
      <li onClick={() => GoPage("my_info", cid)}>
        <img src="./img/common/icon_mypage.png" alt="#" />
        <div>내정보</div>
      </li>
    </Bottom>
  );
}
export default LoggedBottom;
