function GetAgreement(params) {
  const { cid } = params;

  const data = [
    {
      cid: "CID00000009", // 피지컬베이스
      privacy: {
        title: `개인정보 수집 및 이용에 대하여 동의`,
        body: `
            정보통신망법 규정에 따라 피지컬베이스에 회원가입 신청하시는 분께 수집하는 개인정보의 항목, 개인정보의 수집 및 이용목적, 개인정보의 보유 및 이용기간을 안내 드리오니 자세히 읽은 후 동의하여 주시기 바랍니다.
            1. 수집하는 개인정보
            서비스 이용을 위해 필요한 최소한의 개인정보를 수집합니다.
            회원가입 시점에 피지컬베이스가 이용자로부터 수집하는 개인정보는 아래와 같습니다.
            - 회원 가입 시에 ‘이름, 비밀번호, 휴대폰번호' 를 필수항목으로 수집합니다.
            만약 이용자가 입력하는 생년월일이 만14세 미만 아동일 경우에는 법정대리인 정보(법정대리인의 이름, 생년월일, 성별, 중복가입확인정보(DI), 휴대폰번호)를 추가로 수집합니다. 그리고 선택항목으로 이메일 주소를 수집합니다.
            서비스 이용 과정에서 이용자로부터 수집하는 개인정보는 아래와 같습니다.
            피지컬베이스 내의 개별 서비스 이용, 이벤트 응모 및 경품 신청 과정에서 해당 서비스의 이용자에 한해 추가 개인정보 수집이 발생할 수 있습니다.
            추가로 개인정보를 수집할 경우에는 해당 개인정보 수집 시점에서 이용자에게 ‘수집하는 개인정보 항목, 개인정보의 수집 및 이용목적, 개인정보의 보관기간’에 대해 안내 드리고 동의를 받습니다.
            서비스 이용 과정에서 IP 주소, 쿠키, 방문일시·불량 이용 기록 등의 서비스 이용 기록, 기기정보가 생성되어 수집될 수 있습니다.
            구체적으로
            1) 서비스 이용 과정에서 이용자에 관한 정보를 정보통신서비스 제공자가 자동화된 방법으로 생성하여 이를 저장(수집)하거나
            2) 이용자 기기의 고유한 정보를 원래의 값을 확인하지 못하도록 안전하게 변환한 후에 수집하는 경우를 의미합니다.
            2. 수집한 개인정보의 이용
            피지컬베이스는 회원관리, 서비스 개발 및 제공 및 향상, 안전한 인터넷 이용환경 구축 등 아래의 목적으로만 개인정보를 이용합니다.
            - 회원 가입 의사의 확인, 연령 확인 및 법정대리인 동의 진행, 이용자 및 법정대리인의 본인 확인, 이용자 식별, 회원탈퇴 의사의 확인 등 회원관리를 위하여 개인정보를 이용합니다.
            - 콘텐츠 등 기존 서비스 제공(광고 포함)에 더하여, 인구통계학적 분석, 서비스 방문 및 이용기록의 분석, 개인정보 및 관심에 기반한 이용자간 관계의 형성, 지인 및 관심사 등에 기반한 맞춤형 서비스 제공 등 신규 서비스 요소의 발굴 및 기존 서비스 개선 등을 위하여 개인정보를 이용합니다.
            - 법령 및 피지컬베이스 이용약관을 위반하는 회원에 대한 이용 제한 조치, 부정 이용 행위를 포함하여 서비스의 원활한 운영에 지장을 주는 행위에 대한 방지 및 제재, 계정도용 및 부정거래 방지, 약관 개정 등의 고지사항 전달, 분쟁조정을 위한 기록 보존, 민원처리 등 이용자 보호 및 서비스 운영을 위하여 개인정보를 이용합니다.
            - 유료 서비스 제공에 따르는 본인인증, 구매 및 요금 결제, 상품 및 서비스의 이용을 위하여 개인정보를 이용합니다.
            - 이벤트 정보 및 참여기회 제공, 광고성 정보 제공 등 마케팅 및 프로모션 목적으로 개인정보를 이용합니다.
            - 서비스 이용기록과 접속 빈도 분석, 서비스 이용에 대한 통계, 서비스 분석 및 통계에 따른 맞춤 서비스 제공 및 광고 게재 등에 개인정보를 이용합니다.
            - 보안, 프라이버시, 안전 측면에서 이용자가 안심하고 이용할 수 있는 서비스 이용환경 구축을 위해 개인정보를 이용합니다.
            3. 개인정보의 파기
            회사는 원칙적으로 이용자의 개인정보를 회원 탈퇴시 지체없이 파기하고 있습니다.
            단, 이용자에게 개인정보 보관기간에 대해 별도의 동의를 얻은 경우, 또는 법령에서 일정 기간 정보보관 의무를 부과하는 경우에는 해당 기간 동안 개인정보를 안전하게 보관합니다.
            이용자에게 개인정보 보관기간에 대해 별도의 동의를 얻은 경우는 아래와 같습니다.
            부정가입 및 징계기록 등의 부정이용기록은 부정 가입 및 이용 방지를 위하여 수집 시점으로부터 6개월간 보관하고 파기하고 있습니다. 부정이용기록 내 개인정보는 가입인증 휴대폰 번호(만 14세 미만 회원의 경우 법정대리인 DI)가 있습니다.
            결제도용, 불법할인대출(일명 '카드깡') 등 관련 법령 및 이용약관을 위반하는 부정거래기록(아이디, 이름, 휴대폰번호, 배송지 주소, IP주소, 쿠키, 기기정보)은 부정거래 방지 및 다른 선량한 이용자의 보호, 안전한 거래 환경 보장을 위하여 수집 시점으로부터 3년간 보관하고 파기합니다.
            전자상거래 등에서의 소비자 보호에 관한 법률, 전자금융거래법, 통신비밀보호법 등 법령에서 일정기간 정보의 보관을 규정하는 경우는 아래와 같습니다. 피지컬베이스는 이 기간 동안 법령의 규정에 따라 개인정보를 보관하며, 본 정보를 다른 목적으로는 절대 이용하지 않습니다.
            - 전자상거래 등에서 소비자 보호에 관한 법률
            계약 또는 청약철회 등에 관한 기록: 5년 보관
            대금결제 및 재화 등의 공급에 관한 기록: 5년 보관
            소비자의 불만 또는 분쟁처리에 관한 기록: 3년 보관
            - 전자금융거래법
            전자금융에 관한 기록: 5년 보관
            - 통신비밀보호법
            로그인 기록: 3개`,
      },
      agreement: [
        {
          title: `센터 가입원칙 관련사항 동의`,
          body: `
          피지컬베이스 이용약관 - 제 1 조 (가입원칙)
          가. 피지컬베이스 회원가입시 모바일 전용 앱을 이용하여 회원임을 증명하셔야 합니다.
          나. 회원은 모바일 전용 앱을 이용하여 결제, 출입 및 PT, 쇼핑몰 등의 서비스를 이용하여야 합니다.
          다. 영업시간은(08:00-22:00)입니다.
          그 외 (22:00-익일08:00) 과 주말(토~일 : 08:00~익일08:00) 까지는 센터 이용으로 지불한 금액에 포함되지 않는 서비스 차원의 제공입니다.
          라. 영업시간 외의 시간에 발생한 각종사고(신체상해포함)는 당사에서 책임지지 않습니다.
          따라서 안전에 대한 우려가 있으신 분들은 반드시 영업시간내에 이용해 주시기 바랍니다.`,
        },
        {
          title: `센터 사용관련 회원 준수사항 동의`,
          body: `
          피지컬베이스 이용약관 - 제 2 조 (회원준수사항)
          가. 회원상호간에 위화감 조성 및 전염병, 풍기 문란, 음주 등으로 질서유지에 지장을 초래할 경우 회원의 권리를 제한 및 박탈 할 수 있습니다.
            나. 시설물에 대하여 고의 또는 부주의로 훼손, 파괴 됐을 경우 당사자가 책임을 집니다
            다. 본인 부주의로 인한 상해 발생 시 본 센터에서는 책임을 지지 않습니다
            라. 맡기시지 않은 귀중품 분실에 대해서는 본 센터에서는  책임을 지지 않습니다,
            마. 시설이용 시 에는 정해진 복장(운동화, 운동복)을 준수해야 하며, 미 준수 시 이용의 제한을 받을 수 있습니다
            바. 눈병, 피부병 기타 공중위생에 영향을 미칠 경우 입장이 불가합니다
            사. 회원 등록 후 모바일 전용 앱을 사용하지 않고 출입을 할 수 없습니다.
            아. 가입회원이 아닌 분과 동반출입을 하는 경우 민법상 일일 이용금액의 최고 50배를 부과 합니다
            자. 센터 내 비품 도난 시 형사법으로 처벌을 받게 됩니다
            차. 센터는 위생 상 물컵, 수건, 옷, 신발장을 제공하지 않습니다. 개인물컵 및 개인수건을 지참하셔야 합니다
            카. 서비스시간(영업시간 외)  이용 시 시설유지와 안전상의 문제로 노약자 및 임산부, 어린이(14세 미만)의 입장이 제한됩니다. (단, 보호자 동반 시는 어린이 입장이 가능합니다.)
            타. 탈의실 내에는 개인물건을 보관을 하실 수 없습니다. 찾아가지 않는 개인 물품은 익일 모두 폐기처분  합니다
            파. 일일 사물함에 있는 물품등은 키를 사용하셔서 회원님께서 직접 관리 하셔야 합니다
            사물함 키 분실 시 (30,000원) 삼만 원의 비용이 발생합니다.
            (사물함 도난사고는 당사가 책임지지 않습니다.)
            하. 스트레칭존, 비디오, 키오스크 등 편의시설 사용은 영업시간에만 서비스 해드리며 그외 시간은  토, 일 및 공휴일을 포함 하여 사용이 불가능 합니다.
            (본 시설물은 서비스 시설물이므로 이용금액에 포함 되지는 않습니다.)
          `,
        },
        {
          title: `명의 변경 및 휴회(정지)적용 동의`,
          body: `
          피지컬베이스 이용약관 - 제 3 조 (명의변경 및 양도)
          가. 명의 변경 시 1회로 한정하며 사전승인 및 명의변경에 따른 소정의 수수료는 회원이 부담합니다.
          나. 명의 변경 및 양도 수수료는 20,000원 이며, 센터로 내방하셔서 처리하셔야 합니다.
          다. 양도 절차가 진행된 회원권은 환불 신청을 할 수 없습니다.

          피지컬베이스 이용약관 - 제 4 조 (휴회)
          가. 본 센터는 6개월 회원권 등록 시 1회 휴회(기간연장)가 적용됩니다. (3개월, 1개월 회원권 미적용) 휴회기간은 1회에 최소 1주일, 최대 1개월입니다.
          나. 휴회는 회원이 건강상의 문제로 운동 등 신체활동이 불가능할 경우에 신청이 가능하며, 진단서 등 관련 증빙서류를 제출하여야 합니다.
          `,
        },
        {
          title: `환불약점금(위약금10%) 및 환불금 지금방식 동의`,
          body: `
          피지컬베이스 이용약관 - 제 5 조 (환불)
          가. 과납금 산출방식은 입회비에서 수강료, 용품대금, 환불 약정금 (위약금 10%, 계약즉시적용)및 지나간 일수(운동시작일기준)의 일수강료금액 (1일 회원권 10,000원)을 공제한 후 지급되며, 휴회기간 역시 이용기간에 포함되어 공제됩니다.
          나. 환불은 모바일 전용 앱을 이용하여 회원이 직접 신청하여야 합니다.
          다. 양도 절차가 진행된 회원권은 환불 신청을 할 수 없습니다.
          라. 사용기간을 일 단위로 정산하며 미 사용한 상태에서 기간이 경과한 회원권에 대해서는 사용권을 주장할 수 없습니다.
          마. 환불시 재정경제부 제정 소비자피해보상규정 제 3조 별지 2 제 20항에 의거하여 전체금액의 10% 환불 수수료 차감을 합니다.
          바. 센터 개업 오픈 행사시 웰컴팩 용품을 지급받으신 회원분은 용품대금 50,000원을 차감 합니다.
            `,
        },
        {
          title: `영업 및 서비스 시간과 각종사고 책임범위 동의`,
          body: `
          피지컬베이스 이용약관 - 제 6 조 (서비스)
          영업시간은(08:00-22:00)입니다. 그 외 (22:00-익일08:00) 까지는 서비스시간입니다.
        영업시간 외의 시간에 발생한 각종사고(신체상해포함)는 당사에서 책임지지 않습니다.
              `,
        },
      ],
      order: {
        title: "카드결제 이용약관",
        body: `
      <p><h3>피지컬베이스 이용약관 - 제 1 조 (가입원칙)</h3>
          가. 피지컬베이스 회원가입시 모바일 전용 앱을 이용하여 회원임을 증명하셔야 합니다.
          나. 회원은 모바일 전용 앱을 이용하여 결제, 출입 및 PT, 쇼핑몰 등의 서비스를 이용하여야 합니다.
          다. 영업시간은(08:00-22:00)입니다.
          그 외 (22:00-익일08:00) 과 주말(토~일 : 08:00~익일08:00) 까지는 센터 이용으로 지불한 금액에 포함되지 않는 서비스 차원의 제공입니다.
          라. 영업시간 외의 시간에 발생한 각종사고(신체상해포함)는 당사에서 책임지지 않습니다.
          따라서 안전에 대한 우려가 있으신 분들은 반드시 영업시간내에 이용해 주시기 바랍니다.</p>


        <p><h3>피지컬베이스 이용약관 - 제 2 조 (회원준수사항)</h3>
            가. 회원상호간에 위화감 조성 및 전염병, 풍기 문란, 음주 등으로 질서유지에 지장을 초래할 경우 회원의 권리를 제한 및 박탈 할 수 있습니다.
            나. 시설물에 대하여 고의 또는 부주의로 훼손, 파괴 됐을 경우 당사자가 책임을 집니다.
            다. 본인 부주의로 인한 상해 발생 시 본 센터에서는 책임을 지지 않습니다.
            라. 맡기시지 않은 귀중품 분실에 대해서는 본 센터에서는  책임을 지지 않습니다.
            마. 시설이용 시 에는 정해진 복장(운동화, 운동복)을 준수해야 하며, 미 준수 시 이용의 제한을 받을 수 있습니다.
            바. 눈병, 피부병 기타 공중위생에 영향을 미칠 경우 입장이 불가합니다.
            사. 회원 등록 후 모바일 전용 앱을 사용하지 않고 출입을 할 수 없습니다.
            아. 가입회원이 아닌 분과 동반출입을 하는 경우 민법상 일일 이용금액의 최고 50배를 부과 합니다.
            자. 센터 내 비품 도난 시 형사법으로 처벌을 받게 됩니다.
            차. 센터는 위생 상 물컵, 수건, 옷, 신발장을 제공하지 않습니다. 개인물컵 및 개인수건을 지참하셔야 합니다.
            카. 서비스시간(영업시간 외)  이용 시 시설유지와 안전상의 문제로 노약자 및 임산부, 어린이(14세 미만)의 입장이 제한됩니다. (단, 보호자 동반 시는 어린이 입장이 가능합니다.)
            타. 탈의실 내에는 개인물건을 보관을 하실 수 없습니다. 찾아가지 않는 개인 물품은 익일 모두 폐기처분  합니다.
            파. 일일 사물함에 있는 물품등은 키를 사용하셔서 회원님께서 직접 관리 하셔야 합니다.
            사물함 키 분실 시 (30,000원) 삼만 원의 비용이 발생합니다.
            (사물함 도난사고는 당사가 책임지지 않습니다.)
            하. 스트레칭존, 비디오, 키오스크 등 편의시설 사용은 영업시간에만 서비스 해드리며 그외 시간은  토, 일 및 공휴일을 포함 하여 사용이 불가능 합니다.
            (본 시설물은 서비스 시설물이므로 이용금액에 포함 되지는 않습니다.)</p>

          <p><h3>피지컬베이스 이용약관 - 제 3 조 (명의변경 및 양도)</h3>
            가. 명의 변경 시 1회로 한정하며 사전승인 및 명의변경에 따른 소정의 수수료는 회원이 부담합니다.
            나. 명의 변경 및 양도 수수료는 20,000원 이며, 센터로 내방하셔서 처리하셔야 합니다.
            다. 양도 절차가 진행된 회원권은 환불 신청을 할 수 없습니다.</p>

          <p><h3>  피지컬베이스 이용약관 - 제 4 조 (휴회)</h3>
            가. 본 센터는 6개월 회원권 등록 시 1회 휴회(기간연장)가 적용됩니다. (3개월, 1개월 회원권 미적용) 휴회기간은 1회에 최소 1주일, 최대 1개월입니다.
            나. 휴회는 회원이 건강상의 문제로 운동 등 신체활동이 불가능할 경우에 신청이 가능하며, 진단서 등 관련 증빙서류를 제출하여야 합니다.
          </p>

          <p><h3>피지컬베이스 이용약관 - 제 5 조 (환불)</h3>
            가. 과납금 산출방식은 입회비에서 수강료, 용품대금, 환불 약정금 (위약금 10%, 계약즉시적용)및 지나간 일수(운동시작일기준)의 일수강료금액 (1일 회원권 10,000원)을 공제한 후 지급되며, 휴회기간 역시 이용기간에 포함되어 공제됩니다.
            나. 환불은 모바일 전용 앱을 이용하여 회원이 직접 신청하여야 합니다.
            다. 양도 절차가 진행된 회원권은 환불 신청을 할 수 없습니다.
            라. 사용기간을 일 단위로 정산하며 미 사용한 상태에서 기간이 경과한 회원권에 대해서는 사용권을 주장할 수 없습니다.
            마. 환불시 재정경제부 제정 소비자피해보상규정 제 3조 별지 2 제 20항에 의거하여 전체금액의 10% 환불 수수료 차감을 합니다.
            바. 센터 개업 오픈 행사시 웰컴팩 용품을 지급받으신 회원분은 용품대금 50,000원을 차감 합니다.
          </p>

          <p><h3>피지컬베이스 이용약관 - 제 6 조 (서비스)</h3>
            영업시간은(08:00-22:00)입니다. 그 외 (22:00-익일08:00) 까지는 서비스시간입니다.
            영업시간 외의 시간에 발생한 각종사고(신체상해포함)는 당사에서 책임지지 않습니다.
          </p>

          `,
      },
    },
    {
      cid: "CT18T25131655.yADF0", // 피지컬베이스 자이점
      privacy: ``,
      agreement: [],
      order: {
        title: "카드결제 이용약관",
        body: `
      약관입니다....
      약관입니다....
      약관입니다....
      `,
      },
    },
  ];

  // find
  const row = data.find((item) => item.cid === cid);
  return row;
}

export default GetAgreement;
