import { createStore } from 'redux'

const INITIAL_STATE = {
  data: {
    mode:"server",
    local: "http://192.168.76.23:80",   
    server: "https://api.smartg.kr",
    url : "",
    cid : '',
    level : 0
  }
}

function fetch(state = INITIAL_STATE, action) {
  if(action.type==='GET' && action.name==="URL") {
    //   state.data.count =   state.data.count +1;
    if(state.data.mode === "local"){
        state.data.url = state.data.local
    }
    if(state.data.mode === "server"){
        state.data.url = state.data.server
    }
  }

  if(action.type==='SET' && action.name==="CID") {
    state.data.cid = action.value;
  }
  if(action.type==='SET' && action.name==="LEVEL") {
    state.data.level = action.value;
  }

//   console.log( 'count=',state.data.count);
//   console.log( 'name=',action.name );

  return state;
}

const store = createStore(fetch)
export default store