import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { ConfigProvider } from "antd";
import 'moment/locale/ko';
import locale from "antd/lib/locale/ko_KR";

ReactDOM.render(
  <ConfigProvider locale={locale}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ConfigProvider>,
  document.getElementById("root")
);

serviceWorker.unregister();
