import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Tag, Divider, Button } from "antd";
import "antd/dist/antd.css";
import logo from "../img/M_logo.jpg";
import Column from "rc-table/lib/sugar/Column";
import { FiChevronDown } from "react-icons/fi";
import GoPage from "../lib/GoPage";
import { MenuOutlined } from "@ant-design/icons";
import { Carousel } from "antd";
import { Drawer } from "antd";
import DrawerMenu from "./DrawerMenu";


const ProfileContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
const ProfileContent = styled.div`
  width: 940px;
  color: #484848;
`;
const ProfileTitle = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  font-size: 22px;
  margin-top: 30px;
  margin-bottom: 30px;
  color: #274c7e;
`;

const ProfileInfo = styled.div`
  display: flex;
  justify-content: left;
  font-size: 16px;
  flex-direction: column;
`;



function MobileProp1() {

  return (
    <div style={{ width: "100%" }}>

      <DrawerMenu />

      <div>
        <img src="/img/M_teacher1_img.jpg" width="100%"></img>
      </div>

      <ProfileContainer style={{ paddingLeft: "5%", paddingRight: "5%" }}>
        <ProfileContent>
          <ProfileTitle>
            <img
              style={{ height: 25, marginRight: 5 }}
              src="./img/prop_icon.jpg"
            />
            학력사항
          </ProfileTitle>
          <ProfileInfo>
            · 동아대학교 체육학부 졸업 <br />
            · 동아대학교 체육학석사(운동생리학) 졸업 <br />
            · 동아대학교 이학박사(운동생리학) 졸업 <br />
            · 부산가톨릭대학교 물리치료학과 학사
            <br />
            · 부산대학교 의학전문대학원 의학석사 졸업 <br />
            · 부산대학교 의학전문대학원 의학박사 수료
            <br />· 부산대학교 경영대학원 경영컨설팅학박사 졸업
          </ProfileInfo>

          <ProfileTitle>
            <img
              style={{ height: 25, marginRight: 5 }}
              src="./img/prop_icon.jpg"
            />
            교육 경력 사항
          </ProfileTitle>
          <ProfileInfo>
            · 전) 고신대학교 환경보건학과 외래교수
            <br />
            · 전) 동아대학교 스포츠과학대학 외래교수
            <br />
            · 전) 대경대학교 생활체육지도과 외래교수 <br />
            · 전) 동원과학기술대학교 재활스포츠과 겸임교수
            <br />
            · 현) (사)대한기능재활운동협회 협회장
            <br />· 현) 부산외국어대학교 스포츠재활학과 교수
          </ProfileInfo>

          <ProfileTitle>
            <img
              style={{ height: 25, marginRight: 5 }}
              src="./img/prop_icon.jpg"
            />
            자격 및 면허
          </ProfileTitle>
          <ProfileInfo>
            · 물리치료사 [보건복지부]
            <br />
            · 건강운동관리사 [문화체육관광부]
            <br /> · 생활스포츠지도자 2급 보디빌딩, 야구, 축구, 스쿼시
            [문화체육관광부]
            <br />· 미국스포츠의학회(NASM) CPT, CES, PES
          </ProfileInfo>

          <ProfileTitle>
            <img
              style={{ height: 25, marginRight: 5 }}
              src="./img/prop_icon.jpg"
            />
            수상 경력
          </ProfileTitle>
          <ProfileInfo>
            · 2014 스포츠서울 Innovation 기업&브랜드 혁신리더 부문 대상 (2014)
            <br />
            · NASM PES - 운동수행능력 향상 트레이닝 우수학술도서 선정 (2015)
            <br />· 2016 헤럴드경제 대한민국 가치경영대상 (2016)
            <br />
          </ProfileInfo>

          <ProfileTitle>
            <img
              style={{ height: 25, marginRight: 5 }}
              src="./img/prop_icon.jpg"
            />
            저서 및 역서
          </ProfileTitle>
          <div>
            <img src="/img/M_teacher1_book.jpg" width="100%"></img>
          </div>

          <ProfileTitle>
            <img
              style={{ height: 25, marginRight: 5 }}
              src="./img/prop_icon.jpg"
            />
            논문
          </ProfileTitle>
          <div>
            <img src="/img/M_teacher1_thesis.jpg" width="100%"></img>
          </div>
        </ProfileContent>
      </ProfileContainer>
    </div>
  );
}

export default MobileProp1;
