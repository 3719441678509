import React, { useState, useEffect } from "react";
import styled from "styled-components";
import pr from "../lib/Pr";
import axios from "axios";
import moment from "moment";
// import { Spin } from "antd";
// import GetToken from "../lib/GetToken";
// import GetLogin from "../lib/GetLogin";
// import Container from './Container';
import Layout from "./Layout";
import Loading from "./Loading";
import { Tag, Divider, Button } from "antd";
import "antd/dist/antd.css";
import GoPage from "../lib/GoPage";
import TitleText from "./TitleText";
import CenterMenu from "./CenterMenu";
import TopContainer from "./TopContainer";
import queryString from "query-string";
import GetCid from "../lib/GetCid";
import GetMcd from "../lib/GetMcd";
import GetToken from "../lib/GetToken";
import GetLogin from "../lib/GetLogin";
import GetHost from "../lib/GetHost";
import getCenter from "../lib/getCenter";


const Container = styled.div`
  background: #111111;
  /* height: 100%; */
  color: #ffffff;
  // justify-content: center;
`;

const TrainerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
const TrainerContent = styled.div`
  /* border: 1px solid red; */
  width: 90%;
  display: flex;
  font-size: 12px;
  margin: 20px 0 20px 0;
`;

const TrainerImg = styled.div`
  /* border: 1px solid purple; */
  padding-right: 20px;
  img {
    width: 100px;
  }
`;
const TrainerIntro = styled.div`
  flex: 1;

  .trainer-name {
    font-size: 18px;
    font-weight: bold;
    padding: 2% 0;
  }
`;
const TrainerAbout = styled.div`
  color: #e9e9e9;
  .title {
    font-size: 14px;
    font-weight: bold;
  }
`;
const TrainerText = styled.div`
  color: #e9e9e9;
`;
const Line = styled.div`
  border-bottom: 1px solid #7b7b7b;
  width: 90%;
`;
function About2() {

  // 로그인
  const [login, setLogin] = useState({});
  const cid = GetCid();
  const mcd = GetMcd();
  const token = GetToken();

  useEffect(() => {
    if (token !== undefined && mcd !== undefined) {
      (async () => {
        if (token === "LOGOUT") {
        } else {
          result = await GetLogin({ token: token, mcd: mcd, cid: cid });
          setLogin(result.data);
        }
      })();
    }
  }, []);

  let result;

  const [loaded, setLoaded] = useState();
  const [data, setData] = useState([]);

  // 한번만 실행 & 여러번 가능
  useEffect(() => {
    (async () => {
      let { cid } = queryString.parse(window.location.search); // 추출
      // const result = await axios.post(
      //   GetHost()+'/center/get_trainer',
      //   {
      //     cid: cid,
      //   }
      // );
      // pr(result.data);
      // setData(result.data);

       const result = await getCenter({ cid: cid });
       setData(result.trainer);
       console.log(result.trainer);



      setLoaded(true);
    })();
  }, []);

  return (
    <Layout>
      {loaded === false ? (
        <Loading />
      ) : (
        <Container>
          <TopContainer title="센터소개" login={login} />
          <CenterMenu active="about2" />

          {data && data.map((item, index) => {
            return (
              <TrainerContainer key={index}>
                <TrainerContent>
                  <TrainerImg>
                    <img src={item.image} alt="#"></img>
                  </TrainerImg>
                  <TrainerIntro>
                    <div className="trainer-name">{item.name}</div>

                    {item.education.length !== 0 && (
                      <TrainerAbout>
                        <div className="title">학력</div>
                        <TrainerText>
                          {item.education.map((item1, index1) => {
                            return <div key={index1}>{item1}</div>;
                          })}
                        </TrainerText>
                        <div style={{ paddinTop: 5 }}></div>
                      </TrainerAbout>
                    )}

                    {item.career.length !== 0 && (
                      <TrainerAbout>
                        <div className="title">경력</div>
                        <TrainerText>
                          {item.career.map((item1, index1) => {
                            return <div key={index1}>{item1}</div>;
                          })}
                        </TrainerText>
                      </TrainerAbout>
                    )}

                    {item.qulification.length !== 0 && (
                      <TrainerAbout>
                        <div className="title">자격</div>
                        <TrainerText>
                          {item.qulification &&
                            item.qulification.map((item1, index1) => {
                              return <div key={index1}>{item1}</div>;
                            })}
                        </TrainerText>
                      </TrainerAbout>
                    )}
                  </TrainerIntro>
                </TrainerContent>
                <Line></Line>
              </TrainerContainer>
            );
          })}
        </Container>
      )}
    </Layout>
  );
}

export default About2;

/* <GetToken setToken={setToken}></GetToken>
      <GetLogin token={token} setLogin={setLogin}></GetLogin>
      <GetAuth login={login} setLoaded={setLoaded}></GetAuth> */
