/*
  userCode : 카드사용 결제코드
  tierCode : 카드사용 결제코드 (에이젼시모드)
  banner : 상단 롤링배너
  isFrequency : 프리퀀시 사용 Y/N
*/

async function getCenter(params) {
  return new Promise((resolve, reject) => {
    const { cid } = params;
    const data = [
      //피지컬베이스
      {
        cname: "피지컬베이스",
        cid: "CID00000009",
        menu1: {
          image1: "CID00000009/C01.jpg",
          image2: "CID00000009/C02.jpg",
          image3: "CID00000009/C03.jpg",
          image4: "CID00000009/C04.jpg",
          title1: "프리미엄 헬스클럽",
          title2: "피지컬베이스",
          address1: `주소:부산 기장군 정관읍 정관4로 42, 3층 301호(풍산빌딩)`,
          time: `운영시간: 오전 6:00 ~ 오후 10:00 (거리두기로 인한 운영시간 변경가능)`,
          phone: `연락처: 051-728-8771`,
          image5: "CID00000009/T02.png",
        },
        banner: [
          { src: "/CID00000009/banner1.png" },
          { src: "/CID00000009/banner2.png" },
          { src: "/CID00000009/banner3.png" },
          { src: "/CID00000009/banner4.png" },
        ],
        door_id: "35",
        discount_coupon: false,
        tierCode: "G10",
        userCode: "imp76426114",
        isFrequency: true,
        title1: "프리미엄 헬스클럽",
        title2: "피지컬베이스",
        background: "/img/common/main.jpg",
        logoImage: "img/common/main_logo_white.png",
        trainer: [
          {
            name: "하홍수 부점장",
            image: "/CID00000009/T01.png",
            education: [
              "만덕고등학교 문과계열 졸업",
              "신라대학교 체육학부 운동과학 전공 졸업",
            ],
            career: [
              "웰존휘트니스",
              "우리정신병원",
              "김해삼성초등학교",
              "신라태권도",
            ],
            qulification: [
              "펑셔널 실전적용 자격과정(GV)",
              "CPT 퍼스널 트레이너 전문가과정 (서울)",
              "CFSC Level.1 (부산)",
              "산전산후 운동 프로그램",
            ],
          },
          {
            name: "정은미 트레이너",
            image: "/CID00000009/T03.png",
            education: ["영남사이버대학교 생활체육과"],
            career: [
              "World Gym G.X Instructor",
              "Double H MultiGym G.X Instructor",
              "The X Gym Manager",
              "국민건강보험공단 운동강사",
              "기장군 보건소, 정관보건지소 운동처방사",
              "2009, 2011 Adidas Fitness Academy(상해, 말레이시아) Adidas Yoga Class진행",
              "2015 Reebok Fitness Festival Lesmills Body Balance 프레젠터",
            ],
            qulification: [
              "수기관리 관절가동술 전문가(karfe)",
              "기능성근력운동코치(CFSC Lv.1)",
              "통합저항운동 IWT 코스 (karfe)",
              "동적안정화운동 DST 코스 (karfe)",
              "2급 생활스포츠 지도자(보디빌딩, 에어로빅)",
              "건강운동관리사",
              "Kafa Fitness S.M.C 1급",
              "한국요가지도자 연합회 요가 지도자",
              "Yoga Kula Priya 임산부 요가 지도자",
              "Lesmils Body Pump, Body Combat, Body Balance Instructor",
            ],
          },
          {
            name: "한창무 트레이너",
            image: "/CID00000009/T04.png",
            education: [
              "동원과학기술대학교 스포츠재활학과 졸업",
              "부산외국어대학교 사회체육학과 졸업",
            ],
            career: [
              "스마트핏 구서점 트레이너",
              "전국 레슬링 대회(대통령배)동메달",
              "전국 레슬링 선수권 대회 동메달",
            ],
            qulification: [
              "VIPR 펑셔널트레이닝 코스 (karfe)",
              "동적신경근파워 DNP (karfe)",
              "동적안정화운동 DST 코스 (karfe)",
              "생활체육지도자2급(보디빌딩)",
              "수기관리 관절가동술 전문가  전문가(karfe)",
              "스트랭스 코디네이션(STC)",
              "동적신경근 안정화 운동(DNS)",
              "기능성근력운동코치(CFSC Lv.1)",
            ],
          },
        ],
      },
      // 구서점 시설 정보
      {
        cname: "스마트짐 구서점",
        cid: "CT14S16095649.NZcd0",
        menu1: {
          image1: "CT14S16095649.NZcd0/C01.jpg",
          image2: "CT14S16095649.NZcd0/C02.jpg",
          image3: "CT14S16095649.NZcd0/C03.jpg",
          image4: "CT14S16095649.NZcd0/C04.jpg",
          title1: "스마트짐",
          title2: "구서점",
          address1: `주소:부산시 금정구 중앙대로 1841번길 91 마루빌딩 3층`,
          time: `운영시간: 05시~24시(거리두기로 인한 운영시간 변경가능)`,
          phone: `연락처:  051-949-0110`,
          image5: "CT14S16095649.NZcd0/M01.jpg",
        },
        banner: [
          { src: "/img/common/banner1.png" },
          { src: "/img/common/banner2.png" },
          { src: "/img/common/banner3.png" },
          { src: "/img/common/banner4.png" },
        ],
        door_id: "00",
        discount_coupon: false,
        tierCode: "G02",
        userCode: "imp76426114",
        isFrequency: true,
        title1: "스마트짐",
        title2: "구서점",
        background: "/img/common/main.jpg",
        logoImage: "img/common/main_logo_white.png",
        trainer: [
          {
            name: "이채관 점장",
            image: "/CT14S16095649.NZcd0/T01.png",
            education: [
              "부산대학교 체육교육대학원 석사",
              "부산가톨릭대학교 물리치료학 박사 수료",
            ],
            career: [
              "현)부산외국어대학교 스포츠 재활학 석사과정 겸임교수",
              "현)KARFE 교육이사",
              "전)동원과학기술대학 스포츠재활운동학부 겸임교수",
              "재활기능운동학, 퍼스널트레이닝, TRX 서스펜션트레이닝 (한미의학)공역",
              "큰솔병원 물리치료실 (신경계치료)",
              "성심메디컬 물리치료실(근골격치료)",
              "SABA배 MR.MS 보디빌딩대회 준 그랑프리",
              "CFSC Master Instructor",
              "ViPR Master Instructor",
            ],
            qulification: [
              "물리치료사",
              "중등학교 정교사(체육)",
              "NASM CPT, CES, PES Instructor",
              "TRX STC, SMSTC Instructor",
              "Powerplate Instructor",
              "IKFF Kettlebell Instructor",
              "Barefoot Training Specialist",
              "Clinical Orthopedic Maunal Therapy(COMT)",
              "FMS(Functional Movement System) Certificate",
              "미국 Athlets Performance(현 EXOS) Center 선수재활기능 자격",
              "STC(Basic, Rehab, Advance) Master Instructor",
            ],
          },
          {
            name: "손용진 부점장",
            image: "/CT14S16095649.NZcd0/T03.png",
            education: [
              "부산 지산고 졸업",
              "동의대 레저스포츠학과 졸업",
              "부산외국어대학교 스포츠재활학 석사 과정",
            ],
            career: [
              "대우 휘트니스 트레이너",
              "대영레포츠 휘트니스 트레이너",
              "양산 수랜드 휘트니스 팀장",
            ],
            qulification: [
              "NASM CPT,CES,PES Instructor",
              "STC Master",
              "STC Basic,Rehab,Advance Instructor",
              "DNS A,B,C Course Instructor",
              "Power Plate Instructor",
              "TRX STC,SMSTC Instructor",
              "IKFF Kettevell Instructor",
              "Vipr Fitness, VGF Instructor",
              "SMRT-CORE Instructor Course",
              "freeFORM Fitness Instructor",
              "Trgger point Instructor",
              "Lesmills Body Pump Instructor",
            ],
          },
          {
            name: "이자연 팀장",
            image: "/CT14S16095649.NZcd0/T04.png",
            education: [
              "부산외국어대학교 사회체육학부 생활체육전공",
              "부산외국어대학교 스포츠재활학 석사",
            ],
            career: [
              "현, KARFE 교육강사",
              "함안 대산고등학교 여자 축구부 팀닥터",
              "부산외대 배드민턴 선수부 컨디셔닝 관리(제95회 전국체육대회",
            ],
            qulification: [
              "UltimateInstability(Aquabag&Aquaball) Instructor",
              "STC Master",
              "STC Basic, Advance, Rehab Instructor",
              "CFSC Level 1 Instructor",
              "NASM CPT, CES, PES Instructor",
              "Functional Training(GV, KP, SR) Instructor",
              "Functional Training(CFT) Instructor",
              "Vibrogym(중력가속진동운동) Instructor",
              "Small Group Instructor",
              "재활&교정운동전략",
              "수기치료 카이로프랙틱 전문가 과정",
              "산전&산후 전문가 과정",
              "모터컨트롤 운동전문가 과정",
              "생활스포츠지도자 2급(배드민턴)",
            ],
          },
          {
            name: "조은주 트레이너",
            image: "/CT14S16095649.NZcd0/T06.png",
            education: [
              "",
              "",
            ],
            career: [""],
            qulification: [
              "생급(보디빌딩)",
              "스지",
              "스핑",
              "유사",
              "",
              "",
              "A.2",
            ],
          },
          {
            name: "윤준석 트레이너",
            image: "/CT14S16095649.NZcd0/T05.png",
            education: [
              "수원 유신고 졸업",
              "부산외국어대학교 스포츠산업학부 사회체육전공",
            ],
            career: ["스마트짐 다대포점 트레이너"],
            qulification: [
              "생활스포츠지도사 2급(보디빌딩)",
              "스포츠마사지",
              "스포츠테이핑",
              "유아체육지도사",
              "노인체육지도사",
              "PADI ADVANCED OPEN WATER",
              "AIDA Freediving Lv.2",
            ],
          },
        ],
      },
      {
        cname: "스마트짐 옥포점",
        cid: "CT14Z01170314.HXZb9",
        menu1: {
          image1: "CT14Z01170314.HXZb9/C01.jpg",
          image2: "CT14Z01170314.HXZb9/C02.jpg",
          image3: "CT14Z01170314.HXZb9/C03.jpg",
          image4: "CT14Z01170314.HXZb9/C04.jpg",
          title1: "스마트짐",
          title2: "거제옥포점",
          address1: `주소:경상남도 거제시 옥포대첩로 38 유라빌딩 9층`,
          time: `운영시간: 05시~24시(거리두기로 인한 운영시간 변경가능)`,
          phone: `연락처: 055-687-9642`,
          image5: "CT14Z01170314.HXZb9/M01.jpg",
        },
        banner: [
          { src: "/img/common/banner1.png" },
          { src: "/img/common/banner2.png" },
          { src: "/img/common/banner3.png" },
          { src: "/img/common/banner4.png" },
        ],
        door_id: "00",
        discount_coupon: false,
        tierCode: "G01",
        userCode: "imp76426114",
        isFrequency: true,
        title1: "스마트짐",
        title2: "거제옥포점",
        background: "/img/common/main.jpg",
        logoImage: "img/common/main_logo_white.png",
        trainer: [
          {
            name: "정지환 점장",
            image: "/CT14Z01170314.HXZb9/T01.png",
            education: [
              "동아대학교 체육대학 경기지도학과 졸업",
              "부산 가톨릭대학교 물리치료학과 졸업",
              "부산외국어대학교 스포츠재활학 석사",
            ],
            career: [
              "현) ㈜스마트짐 다대포점 대표이사",
              "현) (사)대한기능재활운동협회 사무국장",
              "현) 동원과학기술대학 스포츠재활운동학부 겸임교수",
              "전) 부산본병원 물리치료실 주임",
              "전) ㈜프레티올라 대표",
              "전) 스마트핏 거제 고현점 점장",
              "전) 스마트핏 거제 옥포점 점장",
            ],
            qulification: [
              "물리치료사",
              "생활스포츠지도사 2급(보디빌딩,수영)",
              "인간공학기사",
              "NASM CPT, CES, PES Instructor",
              "STC(Basic, Rehab, Advance) Master Instructor",
              "대한정형도수치료학회 정회원",
              "한국기능운동학회 정회원",
              "PNF학회 정회원",
              "Florida Baseball Ranch 교육과정 이수",
            ],
          },
          {
            name: "정연정 트레이너",
            image: "/CT14Z01170314.HXZb9/T02.png",
            education: [
              "충주여자고등학교 졸업",
              "극동대학 레져스포츠학과 졸업",
            ],
            career: [
              "현) 스마트짐 옥포점 트레이너",
              "전) 조정 엘리트선수",
              "전) 조정 체험학교 교사",
              "전국체전 1위 입상",
              "전국 조정 선수권대회 1위",
            ],
            qulification: [
              "생활스포츠지도자 1급",
              "전문스포츠지도자 2급",
              "Pilates Instructor",
              "PowerPlate Lv.1 Instructor",
              "Functional Training Instructor",
            ],
          },
          {
            name: "전용제 트레이너",
            image: "/CT14Z01170314.HXZb9/T03.png",
            education: ["부산외국어대학교 사회체육학부 졸업"],
            career: ["부산외국대학교 휘트니스 트레이너", "키즈 스포츠 강사"],
            qulification: [
              "NASM CES, CPT, PES Instructor",
              "STC Basic, Rehab Instructor",
              "Ultimate Instability DST Course",
              "코어통합저항운동 코스 이수",
              "스포츠 테이핑 1급",
              "스포츠 마사지 1급",
              "유아체육지도자 1급",
              "노인체육지도자 1급",
            ],
          },
        ],
      },

      {
        cname: "스마트짐 다대포점",
        cid: "CT17U31184140.LSUV3",
        menu1: {
          image1: "CT17U31184140.LSUV3/C01.jpg",
          image2: "CT17U31184140.LSUV3/C02.jpg",
          image3: "CT17U31184140.LSUV3/C03.jpg",
          image4: "CT17U31184140.LSUV3/C04.jpg",
          title1: "스마트짐",
          title2: "다대포점",
          address1: `부산광역시 사하구 다대낙조2길 85 케이프포인트 지하 1층`,
          time: `운영시간: 05시~24시(거리두기로 인한 운영시간 변경가능)`,
          phone: `연락처:  051-262-9679`,
          image5: "CT17U31184140.LSUV3/C05.jpg",
        },
        banner: [
          { src: "/CID00000009/banner1.png" },
          { src: "/CID00000009/banner2.png" },
          { src: "/CID00000009/banner3.png" },
          { src: "/CID00000009/banner4.png" },
        ],
        door_id: "35",
        discount_coupon: false,
        tierCode: "G05",
        userCode: "imp76426114",
        isFrequency: true,
        title1: "스마트짐",
        title2: "다대포점",
        background: "/img/common/main.jpg",
        logoImage: "img/common/main_logo_white.png",
        trainer: [
          {
            name: "정지환 점장",
            image: "/CT17U31184140.LSUV3/T01.png",
            education: [
              "동아대학교 체육대학 경기지도학과 졸업",
              "부산 가톨릭대학교 물리치료학과 졸업",
              "부산외국어대학교 스포츠재활학 석사",
            ],
            career: [
              "현) ㈜스마트짐 다대포점 대표이사",
              "현) (사)대한기능재활운동협회 사무국장",
              "현) 동원과학기술대학 스포츠재활운동학부 겸임교수",
              "전) 부산본병원 물리치료실 주임",
              "전) ㈜프레티올라 대표",
              "전) 스마트핏 거제 고현점 점장",
              "전) 스마트핏 거제 옥포점 점장",
            ],
            qulification: [
              "물리치료사",
              "생활스포츠지도사 2급(보디빌딩,수영)",
              "인간공학기사",
              "NASM CPT, CES, PES Instructor",
              "STC(Basic, Rehab, Advance) Master Instructor",
              "대한정형도수치료학회 정회원",
              "한국기능운동학회 정회원",
              "PNF학회 정회원",
              "Florida Baseball Ranch 교육과정 이수",
            ],
          },
          {
            name: "이원경 팀장",
            image: "/CT17U31184140.LSUV3/T02.png",
            education: [
              "대경대학교 생활 체육학과 졸업",
              "동원과학기술대학교 스포츠재활학과 졸업",
            ],
            career: [
              "현)스마트짐 다대포점 팀장",
              "전)대구 삼성태권도 사범",
              "전)스마트핏 정관점 트레이너",
            ],
            qulification: [
              "NASM CPT,CES,PES Instructor",
              "운동처방사 2급",
              "STC Basic, Advance Instructor",
              "기능성 트레이닝 전문가과정 (CFSC Level.1)",
              "PowerPlate Lv.1 Instructor",
              "Trigger Point Instructor",
              "펑셔널 파워트레이닝 전문가과정  (FPT)",
              "펑셔널 트레이닝 전문가과정 (CFT)",
              "Ultimate Instability DST Course",
              "태권도 공인 4단",
              "실버체육 실기교사 1급 자격",
              "유아체육 실기교사 1급 자격,",
              "레크레이션 지도자 1급 자격",
            ],
          },
          {
            name: "박광민 트레이너",
            image: "/CT17U31184140.LSUV3/T03.png",
            education: ["영산대학교 아세안비즈니스학과(베트남어 전공)"],
            career: [
              "맑은샘 사우나 휘트니스 트레이너",
              "베트남 호치민 시 citi gym 트레이너",
            ],
            qulification: [
              "생활체육지도자 2급 보디빌딩 (연수중 12월 연수완료)",
              "STC basic course 수료",
              "STC advance course 수료",
              "STC rehab course 수료",
              "STC professional course 수료",
              "DST(동적 안정화 트레이닝) course 수료",
            ],
          },
        ],
      },
      {
        cname: "스마트짐 고현점",
        cid: "CT17X10111035.huwy5",
        menu1: {
          image1: "CT17X10111035.huwy5/C01.jpg",
          image2: "CT17X10111035.huwy5/C02.jpg",
          image3: "CT17X10111035.huwy5/C03.jpg",
          image4: "CT17X10111035.huwy5/C04.jpg",
          title1: "스마트짐",
          title2: "고현점",
          address1: `주소: 경남 거제시 거제중앙로 31길 12-1 스타빌딩 3층`,
          time: `운영시간: 05시~24시(거리두기로 인한 운영시간 변경가능)`,
          phone: `연락처:  055-636-9679`,
          image5: "CT17X10111035.huwy5/M01.jpg",
        },
        banner: [
          { src: "/img/common/banner1.png" },
          { src: "/img/common/banner2.png" },
          { src: "/img/common/banner3.png" },
          { src: "/img/common/banner4.png" },
        ],
        door_id: "00",
        discount_coupon: false,
        tierCode: "G07",
        userCode: "imp76426114",
        isFrequency: true,
        title1: "스마트짐",
        title2: "고현점",
        background: "/img/common/main.jpg",
        logoImage: "img/common/main_logo_white.png",
        trainer: [
          {
            name: "조무정 부점장",
            image: "/CT17X10111035.huwy5/T01.png",
            education: [
              "인제대학교 사회체육학과 졸업",
              "부산외국어대학교 스포츠재활학과 졸업",
            ],
            career: [
              "현) 스마트짐 고현점 부점장",
              "스마트핏 정관점 트레이너",
              "스마트짐 옥포점 팀장",
            ],
            qulification: [
              "NASM CPT, CES, PES Instructor",
              "STC master lnstructor",
              "CFSC Level.1 lnstructor",
              "Trigger point lnstructor",
              "DNS A,B course ",
              "P-DTR lnstructor",
              "중력가속진동운동 전문가과정",
              "수기치료 관절 가동술 전문가과정",
              "PNF적용 스트레칭 전문가과정",
            ],
          },
          {
            name: "손종호 트레이너",
            image: "/CT17X10111035.huwy5/T02.png",
            education: ["부경고등학교 졸업", "강릉영동대학 경영학과 졸업"],
            career: [
              "현)스마트짐 고현점 트레이너",
              "전)엘리트 야구선수",
              "전)스마트핏 센텀시티점 트레이너",
            ],
            qulification: [
              "NASM CES, CPT, PES Instructor",
              "STC Basic, Rehab Instructor",
              "코어통합저항운동 코스 이수",
              "Ultimate Instability DST Course",
              "SR Course Instructor",
              "GV Course Instructor",
              "KP Course Instructor",
            ],
          },
          {
            name: "임경령 트레이너",
            image: "/CT17X10111035.huwy5/T03.png",
            education: ["청주대학교 스포츠의학과 졸업"],
            career: ["스마트짐 고현점 트레이너"],
            qulification: [
              "NASM CES, CPT, PES Instructor",
              "STC Basic, Regab Instructor",
              "SR Course Instructor",
              "GV Course Instructor",
            ],
          },
        ],
      },
      {
        cname: "힙업공장 부천역점",
        cid: "CT20V06145403.2DGI1",
        menu1: {
          image1: "CT20V06145403.2DGI1/C01.jpg",
          image2: "CT20V06145403.2DGI1/C02.jpg",
          image3: "CT20V06145403.2DGI1/C03.jpg",
          image4: "CT20V06145403.2DGI1/C04.jpg",
          title1: "힙업공장",
          title2: "부천역점",
          address1: `주소:경기도 부천시 부천로 29번길 7 부천 메디컬프라자 2층 핏블리`,
          time: `운영시간: 08시~22시(거리두기로 인한 운영시간 변경가능)`,
          phone: `연락처:  052-839-645`,
          image5: "CT20V06145403.2DGI1/M01.png",
          image6: "CT20V06145403.2DGI1/M02.jpg",
        },
        banner: [
          { src: "/CT20V06145403.2DGI1/banner01.jpg" },
          { src: "/CT20V06145403.2DGI1/banner02.jpg" },
          { src: "/CT20V06145403.2DGI1/banner03.jpg" },
          { src: "/CT20V06145403.2DGI1/banner4.png" },
        ],
        door_id: "00",
        discount_coupon: false,
        tierCode: "F01",
        userCode: "imp76426114",
        isFrequency: false,
        title1: "힙업공장",
        title2: "부천역점",
        background: "/img/common/main.jpg",
        logoImage: "CT20V06145403.2DGI1/fitvely_logo1.png",
        trainer: [
          {
            name: "핏블리 문석기 대표",
            image: "/CT20V06145403.2DGI1/T01.png",
            education: [],
            career: [],
            qulification: [],
          },
          {
            name: "박창우 매니저",
            image: "/CT20V06145403.2DGI1/T02.png",
            education: [],
            career: ["핏블리힙업공장 매니저", "세븐짐휘트니스 팀장5년"],
            qulification: [
              "FMS (기능적움직임평가)lv1 lv2",
              "Nsca 스포츠영양코치",
              "Prehab Exercise 수료(근막이완)",
              "Bodt work technique 수료(그라스톤테크닉)",
              "Preventive medical training school 이수",
              "rehabilitationfunctional 바이퍼트레이닝 이수",
              "3D functional lnstructor 3D바이퍼 강사",
              "사단기능재활운동협회 kettlebell Instructor 케틀벨트레이닝강사",
              "Akf kettle traner lv1 아시아연맹케틀벨 트레이너1",
              "Akf kettle traner lv2 아시아연맹케틀벨 트레이너2",
              "Akf kettle traner lv2 아시아연맹케틀벨 트레이너3",
            ],
          },
          {
            name: "김설균 팀장",
            image: "/CT20V06145403.2DGI1/T03.png",
            education: ["상명대학교 체육학 학사"],
            career: [
              "핏블리 힙업공장 팀장",
              "피트니스스타 시흥 클래식보디빌딩 5위",
            ],
            qulification: [
              "생활체육스포츠 지도자 2급",
              "검도 2단",
              "대한적십자사 응급처치법 강사",
              "NSCA 스포츠영양코치",
              "AKF KETTLEBELL TRAINER LV.1",
              "AKF KETTLEBELL TRAINER LV.2",
              "AKF KETTLEBELL TRAINER LV.3",
            ],
          },
          {
            name: "곽영휘 트레이너",
            image: "/CT20V06145403.2DGI1/T04.png",
            education: [],
            career: [
              "춘계전국유도체전 -66kg 은메달",
              "경기도,인천 유도체전 -66kg 금메달",
              "mn korea classic 맨즈피지크 노비스 1위",
              "wnc 월드내추럴챔피언십 맨즈피지크 노비스2위 오픈2위",
            ],
            qulification: [
              "생활체육지도자2급",
              "BLS inlruclar",
              "BLS provier",
              "ACSM C.P.T/수료증",
              "P.T.S L1(퍼스널트레이닝전문가과정)",
              "대한적십자사 응급처치법 강사 CPR",
              "피사프 국제 트레이너",
              "유도3단",
              "Bodywork Technique 근막이완",
              "ActivMotion Bar 강사자격",
              "기초 해부학&영양학 과정이수",
              "BODYARTIST 기능 해부학 수료",
              "BODYARTIST 교정운동 수료",
              "BODYARTIST 근육 해부학 수료",
              "ActivMotion Bar 강사자격",
              "기초 해부학&영양학 과정이수",
              "BODYARTIST 기능 해부학 수료",
            ],
          },
          {
            name: "이동은 트레이너",
            image: "/CT20V06145403.2DGI1/T05.png",
            education: [],
            career: [],
            qulification: [
              "생활체육스포츠 지도자 2급",
              "NASM-CPT",
              "FMS Lv1",
              "FISAF 국제 트레이너",
              "운동처방사 1급",
              "스포츠마사지1급",
              "스포츠테이핑1급",
              "노인운동강사 1급",
              "국제노인필라테스 1급",
              "국제노인요가1급",
              "기초 해부학&영양학 과정이수",
            ],
          },
          {
            name: "윤현수 트레이너",
            image: "/CT20V06145403.2DGI1/T06.png",
            education: [],
            career: [],
            qulification: [
              "생활체육스포츠 지도자 2급",
              "인재교육원 재활치료사 수료",
              "코치아카데미 트레이닝법 교육 수료",
              "코치아카데미 영양학 과정 수료",
              "브라이언박 실전 기능해부학 수료",
              "다이어트 프로그래머 수료",
              "물리 치료 재활 치료사 교육 수료",
              "한국트레이너협회 스포츠테이핑 1급",
              "스포츠마사지 2급",
              "대한적십자사 응급처치법 강사",
            ],
          },
          {
            name: "엄소정 트레이너",
            image: "/CT20V06145403.2DGI1/T07.png",
            education: [],
            career: [
              "wngp 수원 비키니 오픈 2위",
              "wngp 수원 비키니 노비스 2위",
              "npca 수원 비키니 오픈 4위",
              "npca 수원 비키니 노비스2위",
              "icn 부산  비키니 오픈2위",
              "icn 부산  비키니 노비스2위",
            ],
            qulification: [
              "생활체육지도사 2급 (보디빌딩/탁구)",
              "NSCA sports nutrition coach",
              "AKF KETTLEBELL TRAINER Lv.1",
              "AKF KETTLEBELL TRAINER Lv.2",
              "AKF KETTLEBELL TRAINER Lv.3",
              "MUST MOVE  2nd Basic Anotomy Course",
              "Korea prehap exercise association(자세체형분석과 동작분석)",
              "PRESTM soft tissue mobilization technique",
            ],
          },
          {
            name: "임희범 트레이너",
            image: "/CT20V06145403.2DGI1/T08.png",
            education: [],
            career: [],
            qulification: [
              "생활체육지도자 2급",
              "스포츠 마사지 2급",
              "스포츠 테이핑 2급",
            ],
          },
          {
            name: "정명규 트레이너",
            image: "/CT20V06145403.2DGI1/T09.png",
            education: [],
            career: [
              "2021년 npc 클래식피지크 노피스 2위",
              "2020년 PCA Korea 머슬모델 4위",
            ],
            qulification: [
              "생활체육지도사 2급",
              "대한예방운동협회 functional movement screen",
              "대한예방운동협회 conditioning care specialist master",
              "nsca 스포츠영양코치",
            ],
          },
          {
            name: "이택근 트레이너",
            image: "/CT20V06145403.2DGI1/T10.png",
            education: [],
            career: [],
            qulification: [
              "생활체육지도사 2급",
              "nsca 스포츠 영양코치",
              "스포츠테이핑",
              "스포츠마사지",
              "그룹피트니스2급",
              "퍼스널 트레이너2급",
              "스포츠 재활트레이너",
              "스포츠 심리 상담사",
              "운동처방사1급",
              "대한 적십자사 cpr수료",
            ],
          },
          {
            name: "이건 트레이너",
            image: "/CT20V06145403.2DGI1/T11.png",
            education: [],
            career: [
              "2019 롯데백화점 머슬섹시백 부분 1위",
              "2017 수원시장배 피지크",
              "2016 아마추어리그 -65kg 10위",
            ],
            qulification: [
              "운동심리학 수료",
              "트레이닝 방법론 수료",
              "스포츠마케팅 이수",
              "Wgym 크로스핏 수업 수료",
              "Wgym 케틀벨 이수",
              "태권도 2단 검도 3단",
            ],
          },
          {
            name: "손화음 트레이너",
            image: "/CT20V06145403.2DGI1/T12.png",
            education: [],
            career: [
              "Personal Trainer 8년차",
              "걸그룹(라붐-지엔,소연) 트레이닝",
              "데상트-터프러너스 코치경력",
            ],
            qulification: [
              "생활체육비도자 3급",
              "사단법인 한국필라테스 매트/소도구 취득",
              "NSCA-CPT(국제공인트레이너) 취득",
              "NSCA-스포츠영양학 코치",
            ],
          },
        ],
      },
      {
        cname: "플라이휘트니스",
        cid: "CID00000006",
        menu1: {
          image1: "CID00000006/C01.jpg",
          image2: "CID00000006/C02.jpg",
          image3: "CID00000006/C03.jpg",
          image4: "CID00000006/C04.jpg",
          title1: "플라이휘트니스",
          title2: "",
          address1: `인천 연수구 앵고개로 262 (동춘동, 동경프라자)`,
          time: `운영시간: 오전 6:00 ~ 오후 10:00(거리두기로 인한 운영시간 변경가능)`,
          phone: `연락처: 032-816-5551`,
          image5: "CID00000006/M01.png",
        },
        banner: [
          { src: "/CID00000006/banner1.png" },
          { src: "/CID00000006/banner2.jpg" },
          { src: "/CID00000006/banner3.jpg" },
          { src: "/CID00000006/banner4.png" },
        ],
        door_id: "00",
        discount_coupon: false,
        tierCode: "F02",
        userCode: "imp76426114",
        isFrequency: false,
        title1: "플라이휘트니스",
        title2: "",
        background: "/img/common/main.jpg",
        logoImage: "CID00000006/logo_fly_white.png",
        trainer: [
          {
            name: "황웅비 팀장",
            image: "/CID00000006/T01.png",
            education: [
              "경희대학교 스포츠의학 전공",
              "요녕 중의학 대학교 해부학 실습과정 수료",
            ],
            career: [],
            qulification: [
              "생활체육지도자 2급 (보디빌딩)",
              "CPR certification",
              "KTS certification(KinesiTherapy Specialist)",
              "FMS certification(Functional movement screen)",
              "Y-balance test certification)",
              "스포츠마사지 2급",
              "스포츠 테이핑 2급",
            ],
          },
          {
            name: "윤태호 팀장",
            image: "/CID00000006/T02.png",
            education: [],
            career: [
              "2015 MR. 청주 -75 2위",
              "2015 MR. 경기 클래식 3위",
              "2014 MR. 경기 -75 4위",
              "2014 MR. 경기 클래식 3위",
              "2014 MR. 광명 -75 3위",
              "2013 MR. 수원 -75 4위",
              "2005 MR. 충북 학생부 1위(충북대표 선수 전국체전 선발 출전)",
              "2005 MR. 청주 학생부 1위",
            ],
            qulification: [
              "생활체육지도자 2급 (보디빌딩)",
              "NASM-CPT certification",
              "CPR certification",
              "Body Work Technique certification",
              "스포츠마사지 2급",
              "유아체육지도자 1급",
            ],
          },
          {
            name: "강민주 트레이너",
            image: "/CID00000006/T03.png",
            education: [],
            career: [],
            qulification: [
              "생활체육지도자 2급 (보디빌딩)",
              "스포츠 테이핑 지도사 1급",
              "스포츠 마사지 1급",
              "자세 교정 운동 지도사 2급",
              "유아체육 지도사 1급",
              "노인체육지도사 1급",
              "유아안전교육지도사 2급",
              "재활 트레이닝 교육 수료",
              "운동 처방사 교육 수료",
              "TRX SUSPENSION TRAINING COURSE",
              "CPR certification",
            ],
          },
          {
            name: "백범진 트레이너",
            image: "/CID00000006/T04.png",
            education: [],
            career: ["2021 NPC 인천 대회 출전 (클래식 피지크)"],
            qulification: [
              "생활체육지도자 2급 (보디빌딩)",
              "스포츠 마사지 1급",
              "유아체육 지도사 1급",
              "CPR certification",
              "재활 트레이닝 교육 수료",
              "운동 처방사 교육 수료",
              "노인체육지도사 1급",
            ],
          },
        ],
      },
    ];
    // find
    const row = data.find((item) => item.cid === cid);
    resolve(row);
  });
}
export default getCenter;

/*
    cid: 센터코드
    userCode: 가맹점 식별코드
    TEST 가맹점 : imp19424728
    discount_coupon : 할인쿠폰 on/off
*/
