import React, { useState, useEffect } from "react";
import styled from "styled-components";
import pr from "../lib/Pr";
import Axios from "axios";
import moment from "moment";
import { Spin } from "antd";
import GetToken from "../lib/GetToken";
import GetLogin from "../lib/GetLogin";
// import Container from './Container';
import Layout from "./Layout";
import Loading from "./Loading";
import { Tag, Divider, Button, Result } from "antd";
import "antd/dist/antd.css";
import GoPage from "../lib/GoPage";
import GetCid from "../lib/GetCid";
import TitleText from "./TitleText";

// const Container = styled.div`
//   display: flex;
//   height: 100%;
//   flex-direction: column;
// `;

// const Content = styled.div`
//   background: #cccccc;
//   flex: 1;
//   overflow: scroll;
// `;

const RootContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const TopContainer = styled.div`
  flex: 1;
  /* padding: 5px; */
  display: flex;
  justify-content: center;
  Button {
    height: 40px;
    font-size: 18px;
  }
`;
const Content = styled.div`
  width: 90%;
`;

const JoinLogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
`;
const JoinLogoContent = styled.div`
  text-align: center;
  img {
    width: 230px;
  }
`;

const JoinTextContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
`;

const JoinText = styled.div`
  width: 80%;
  .login-text1 {
    font-size: 20px;
    font-weight: bold;
  }

`;


function Join3() {
  let result;

  const [token, setToken] = useState(); // --- #인증1
  const [login, setLogin] = useState({}); // --- #인증2
  const [loaded, setLoaded] = useState();
  const [cid, setCid] = useState(""); // GET: cid
  useEffect(() => {
    setCid(GetCid());
  }, []);

  // 로그아웃
  // useEffect(() => {
  //   if (loaded === false) {
  //     GoLogout();
  //   }
  // }, [loaded]);

  // 토큰
  // useEffect(() => {
  //   (async () => {
  //     result = await GetToken();
  //     setToken(result);
  //   })();
  // }, []);

  // // 인증
  // useEffect(() => {
  //   (async () => {
  //     if (token !== undefined && token !== "LOGOUT") {
  //       result = await GetLogin(token);
  //       setLogin(result.data);
  //     }
  //   })();
  // }, [token]);

  // // 로그인
  // useEffect(() => {
  //   if (login !== undefined && login.mb_id !== undefined) {
  //     // setLoaded(true);
  //   }
  // }, [login]);

  // 로그인 연장
  // const url = GetHost() + "/member/keep_login";

  useEffect(() => {
    setLoaded(true);
  }, []);

  return (
    <Layout>
      {loaded === false ? (
        <Loading />
      ) : (
        <RootContainer>
          <TopContainer>
            <Content>
              <JoinLogoContainer>
                <JoinLogoContent>
                  <img
                    src="/img/common/main_logo_black.png"
                    alt="#"
                  ></img>
                </JoinLogoContent>
              </JoinLogoContainer>

              <JoinTextContainer>
                <JoinText>
                  <div className="login-text1">회원가입</div>
                </JoinText>
              </JoinTextContainer>
              <Result
                status="success"
                title="회원 가입이 성공적으로 완료되었습니다."
                subTitle="가입하신 회원정보로 로그인 하시기 바랍니다."
                extra={[
                  <Button
                    type="primary"
                    key="console"
                    onClick={() => GoPage("login", cid)}
                  >
                    로그인 하기
                  </Button>,
                ]}
              />
            </Content>
          </TopContainer>
        </RootContainer>
      )}
    </Layout>
  );
}

export default Join3;
