import React, { useState, useEffect } from "react";
import styled from "styled-components";
import pr from "../lib/Pr";
import Axios from "axios";
import moment from "moment";
import { Spin } from "antd";
import GetToken from "../lib/GetToken";
import GetLogin from "../lib/GetLogin";
import Layout from "./Layout";
import Loading from "./Loading";
import { Tag, Divider, Button } from "antd";
import "antd/dist/antd.css";
import GoPage from "../lib/GoPage";
import TitleText from "./TitleText";
import CenterMenu from "./CenterMenu";
import TopContainer from "./TopContainer";
import getCenter from "../lib/getCenter";
import queryString from "query-string";
import GetCid from "../lib/GetCid";
import GetMcd from "../lib/GetMcd";
import { Carousel } from "antd";


const Container = styled.div`
  /* padding: 5px; */
  /* text-align: center; */
  background-color: #111111;
  color: #ffffff;
  height: 100%;
`;
const CenterIntroContainer = styled.div`
  background: #111111;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const CenterIntro = styled.div`
  width: 90%;
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  .center-photo {
    margin-top: 20px;
  }
  img {
    width: 100%;
    height: 100%;
  }
`;
// const Middle = styled.div`
//   flex: 1;
//   display: flex;
//   flex-direction: column;
// `;

const MiddleTop = styled.div`
  justify-content: center;

`;
const ContentStyle = styled.div`
  width: 100%;
  color: #ffffff;
  background: black;

`;
const CenterAddress = styled.div`
  /* text-align: start; */
  padding-top: 10px;
  padding-bottom: 10px;
  /* margin-top: 20px; */
  font-size: 14px;
`;
const CenterName = styled.div`
  display: flex;
  font-size: 16px;
`;
const CenterMap = styled.div`
  height: 100%;
  padding-bottom: 50px;
  img {
    width: 100%;
    height: 100%;
  }
`;
function About1() {
  let result;

  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState({});

  // 로드 데이타
  const loadData = async() => {
    let { cid } = queryString.parse(window.location.search);
    const result = await getCenter({ cid: cid });
    setData(result);
    setLoaded(true);
  }


  useEffect(()=>{
    loadData();
    // if(data.cid!==undefined){
    //   if(data.cid!==''){
    //     setLoaded(true);
    //   }
    // }
  },[])

  return (
    <Layout>
      {loaded === false ? (
        <Loading />
      ) : (
        <Container>
          <TopContainer title="센터소개" />

          <CenterMenu active="about1" />

          <CenterIntroContainer>
            <CenterIntro>
              {/* <div className="center-photo">
                <img src={data.menu1.image1} />
              </div> */}
              {/* <Middle> */}
              <MiddleTop>
                <Carousel autoplay>
                  <div>
                    <ContentStyle>
                      <img src={data.menu1.image1} alt="#"></img>
                    </ContentStyle>
                  </div>
                  <div>
                    <ContentStyle>
                      <img src={data.menu1.image2} alt="#"></img>
                    </ContentStyle>
                  </div>
                  <div>
                    <ContentStyle>
                      <img src={data.menu1.image3} alt="#"></img>
                    </ContentStyle>
                  </div>
                  <div>
                    <ContentStyle>
                      <img src={data.menu1.image4} alt="#"></img>
                    </ContentStyle>
                  </div>
                </Carousel>
              </MiddleTop>
              {/* </Middle> */}

              <CenterAddress>
                <CenterName>
                  <span>{data.menu1.title1}</span>
                  <span
                    className="CanterNameC"
                    style={{ color: "#13a060", paddingLeft: 5 }}
                  >
                    {data.menu1.title2}
                  </span>
                </CenterName>

                <div className="CenterAdd">{data.menu1.address1}</div>
                <div className="CenterTime">{data.menu1.time}</div>
                <div className="CenterNum">{data.menu1.phone}</div>
              </CenterAddress>

              <CenterMap>
                <img src={data.menu1.image5} />
                <div style={{height:20}}></div>
                <img src={data.menu1.image6} />
              </CenterMap>
            </CenterIntro>
          </CenterIntroContainer>
        </Container>
      )}
    </Layout>
  );
}

export default About1;
