import axios from "axios";
import GetHost from "./GetHost";
import pr from "./Pr";

const get_uuid = async (params) => {
  pr("get_uuid");
  const { cid } = params;
  let result = await axios.post(GetHost() + "/sp/get_uuid", { cid: cid });
  return result.data.beacon;
};

export default get_uuid;
