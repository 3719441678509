import React, { useEffect, useState } from "react";
import styled from "styled-components";
import "antd/dist/antd.css";
import GoPage from "../lib/GoPage";
// import { Tag, Divider, Button } from "antd";
// import queryString from "query-string";


const MenuContent = styled.ul`
  width: 100%;
  background-color: #111111;
  font-size: 1em;
  /* margin-top: -15px; */
  border-bottom: 1px solid #212121;
  border-top: 1px solid #212121;
  border-left: none;
  border-right: none;
  display: flex;
  li {
    text-align: center;
    width: 20%;
    padding: 10px 0px;
    color: #ffffff;

  }

  .now-menu-is-active {
    background:red;
  }

`;

function CenterMenu() {

  /*
  const active = props.active;
  const name = props.name;
  const age = props.age;
  */

  let nowActiveMenu1 = "";
  let nowActiveMenu2 = "";
  let nowActiveMenu3 = "";
  let nowActiveMenu4 = "";
  let nowActiveMenu5 = "";

  // if(active==="about1") {
  //   nowActiveMenu1 = "now-menu-is-active";
  // }
  // if (active === "about2") {
  //   nowActiveMenu2 = "now-menu-is-active";
  // }
  // if(active==="about3") {
  //   nowActiveMenu3 = "now-menu-is-active";
  // }
  // if (active === "about4") {
  //   nowActiveMenu4 = "now-menu-is-active";
  // }
  // if (active === "about5") {
  //   nowActiveMenu5 = "now-menu-is-active";
  // }


  return (
    <MenuContent>
      <li className={nowActiveMenu1} onClick={() => GoPage("about1")}>
        시설안내
      </li>
      <li className={nowActiveMenu2} onClick={() => GoPage("about2")}>
        강사소개
      </li>
      <li className={nowActiveMenu3} onClick={() => GoPage("about3")}>
        이용금액
      </li>
      <li className={nowActiveMenu4} onClick={() => GoPage("about4")}>
        이용안내
      </li>
      <li className={nowActiveMenu5} onClick={() => GoPage("about5")}>
        FAQ
      </li>
    </MenuContent>
  );
}

export default CenterMenu;
