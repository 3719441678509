import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Tag, Divider, Button } from "antd";
import "antd/dist/antd.css";
import logo from "../img/M_logo.jpg";
import Column from "rc-table/lib/sugar/Column";
import { FiChevronDown } from "react-icons/fi";
import GoPage from "../lib/GoPage";
import { MenuOutlined } from "@ant-design/icons";
import { Carousel } from "antd";
import { Drawer } from "antd";
import DrawerMenu from "./DrawerMenu";

const TopContent = styled.ul`
  display: flex;
  background-color: #264a7b;
  align-items: center;
  margin-bottom: 0;
  padding: 10px 0;
  li {
    text-align: center;
    display: block;
  }
  .center-area {
    flex: 1;
  }
  .left-area,
  .right-area {
    width: 50px;
  }
`;
const ProfileContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
const ProfileContent = styled.div`
  width: 940px;
  color: #484848;
`;
const ProfileTitle = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  font-size: 22px;
  margin-top: 30px;
  margin-bottom: 30px;
  color: #274c7e;
`;

const ProfileInfo = styled.div`
  display: flex;
  justify-content: left;
  font-size: 16px;
  flex-direction: column;
`;




function MobileProp1() {

  return (
    <div style={{ width: "100%" }}>
      <DrawerMenu />

      <div>
        <img src="/img/M_teacher2_img.jpg" width="100%"></img>
      </div>

      <ProfileContainer style={{ paddingLeft: "5%", paddingRight: "5%" }}>
        <ProfileContent>
          <ProfileTitle>
            <img
              style={{ height: 25, marginRight: 5 }}
              src="./img/prop_icon.jpg"
            />
            학력사항
          </ProfileTitle>
          <ProfileInfo>
            · 부산카톨릭대학교 물리치료학과 졸업 <br />
            · 부산대학교 교육대학원 체육교육학석사 졸업 <br />
            · 부산카톨릭대학교 대학원 물리치료학 박사과정 수료 <br />
          </ProfileInfo>

          <ProfileTitle>
            <img
              style={{ height: 25, marginRight: 5 }}
              src="./img/prop_icon.jpg"
            />
            교육 경력 사항
          </ProfileTitle>
          <ProfileInfo>
            · 전) 동원과학기술대학교 재활스포츠학과 겸임교수 <br />
            · 현) 부산외국어대학교 스포츠재활학과 겸임교수 <br />
            · 현) (사)대한기능재활운동협회 교육이사 <br />
          </ProfileInfo>

          <ProfileTitle>
            <img
              style={{ height: 25, marginRight: 5 }}
              src="./img/prop_icon.jpg"
            />
            자격 및 면허
          </ProfileTitle>
          <ProfileInfo>
            · 물리치료사 [보건복지가족부장관] <br />· 중등 정교사
            <br />· 생활스포츠지도사 2급 보디빌딩 [문화관광부] <br />· NASM -
            CPT, CES, PES <br />· CFSC(Certified Functional Strength Coach)
            Lv.1, 2 - Michael Boyle(USA)
            <br />
          </ProfileInfo>

          <ProfileTitle>
            <img
              style={{ height: 25, marginRight: 5 }}
              src="./img/prop_icon.jpg"
            />
            저서 및 역서
          </ProfileTitle>
          <div>
            <img src="/img/M_teacher2_book.jpg" width="100%"></img>
          </div>
          <div style={{ height: 50 }}></div>
        </ProfileContent>
      </ProfileContainer>
    </div>
  );
}

export default MobileProp1;
