import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Tag, Divider, Button } from "antd";
import "antd/dist/antd.css";
import logo from "../img/M_logo.jpg";
import Column from "rc-table/lib/sugar/Column";
import { FiChevronDown } from "react-icons/fi";
import GoPage from "../lib/GoPage";
import { MenuOutlined } from "@ant-design/icons";
import { Carousel } from "antd";
import { Drawer } from "antd";
import DrawerMenu from "./DrawerMenu";

const TopContent = styled.ul`
  display: flex;
  background-color: #264a7b;
  align-items: center;
  margin-bottom: 0;
  padding: 10px 0;
  li {
    text-align: center;
    display: block;
  }
  .center-area {
    flex: 1;
  }
  .left-area,
  .right-area {
    width: 50px;
  }
`;
const ProfileContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
const ProfileContent = styled.div`
  width: 940px;
  color: #484848;
`;
const ProfileTitle = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  font-size: 22px;
  margin-top: 30px;
  margin-bottom: 30px;
  color: #274c7e;
`;

const ProfileInfo = styled.div`
  display: flex;
  justify-content: left;
  font-size: 16px;
  flex-direction: column;
`;

function MobileProp1() {
  return (
    <div style={{ width: "100%" }}>
      <DrawerMenu />
      <div>
        <img src="/img/M_teacher3_img_2023.png" width="100%"></img>
      </div>

      <ProfileContainer style={{ paddingLeft: "5%", paddingRight: "5%" }}>
        <ProfileContent>
          <ProfileTitle>
            <img
              style={{ height: 25, marginRight: 5 }}
              src="./img/prop_icon.jpg"
            />
            학력사항
          </ProfileTitle>
          <ProfileInfo>
            · 동아대학교 체육대학 경기지도학 전공 <br />
            · 부산가톨릭대학교 보건과학대학 물리치료학 전공 <br />
            · 부산외국어대학교 스포츠재활전공 체육학석사 <br />
            · 부산외국어대학교 스포츠건강융합학과 박사과정 <br />
          </ProfileInfo>

          <ProfileTitle>
            <img
              style={{ height: 25, marginRight: 5 }}
              src="./img/prop_icon.jpg"
            />
            교육 경력 사항
          </ProfileTitle>
          <ProfileInfo>
            · 전)동원과학기술대학교 스포츠재활과 겸임교수
            <br />
            · 전)부산본병원 물리치료실 주임
            <br />
            · 전)프레티올라 운동센터 대표
            <br />
            · 현)부산외국어대학교 스포츠재활 연구소 수석연구원
            <br />
            · 현)부산외국어대학교 스포츠재활학과 겸임교수
            <br />
            · 현)사단법인 대한기능재활운동협회 사무국장
            <br />
            · 현)한국기능운동학회 사무국장
            <br />
            · 현)스마트짐 다대포점 대표이사
            <br />
          </ProfileInfo>

          <ProfileTitle>
            <img
              style={{ height: 25, marginRight: 5 }}
              src="./img/prop_icon.jpg"
            />
            자격 및 면허
          </ProfileTitle>
          <ProfileInfo>
            · 물리치료사
            <br />
            · 인강공학기사
            <br />
            · 생활스포츠지도사2급(보디빌딩, 수영)
            <br />
            · 대한정형도수치료학회 정회원
            <br />
          </ProfileInfo>

          <ProfileTitle>
            <img
              style={{ height: 25, marginRight: 5 }}
              src="./img/prop_icon.jpg"
            />
            논문
          </ProfileTitle>
          <ProfileInfo>
            An Epidemiological Investigagion Study of Social Baseball Club
            Members. <br /> - 부산외국어대학교 석사학위 논문, 2020
          </ProfileInfo>
          <div style={{ height: 50 }}></div>
        </ProfileContent>
      </ProfileContainer>
    </div>
  );
}

export default MobileProp1;
