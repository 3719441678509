import React, { useState, useEffect } from "react";
import styled from "styled-components";
import pr from "../lib/Pr";
import axios from "axios";
import moment from "moment";
import { Spin } from "antd";
import GetToken from "../lib/GetToken";
import Loading from "./Loading";
import { Tag, Divider, Button } from "antd";
import "antd/dist/antd.css";
import GoPage from "../lib/GoPage";
import GetCid from "../lib/GetCid";
import GetHost from "../lib/GetHost";
import GetMcd from "../lib/GetMcd";
import { Carousel } from "antd";
import { useRecoilValue } from "recoil";
import { loginState } from "../lib/State";
import LoginAuth from "../lib/LoginAuth";
import { GetDayDifference } from "../lib/GetDayDifference";
import LoggedBottom from "./LoggedBottom";
// import TopContainer from "./TopContainer";
import TopContainer from "./TopContainer";
import TopLogoImage from "./TopLogoImage";
import { LogoutOutlined } from "@ant-design/icons";
import DoCircuitTopLogo from "./DoCircuitTopLogo";


let height = document.documentElement.offsetHeight;
height = height - 200;

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  /* border: 3px solid red; */
  background: #111111;
`;

const TopContent = styled.div`
  height: 90px;
  background: #111111;
  display: flex;
  flex-direction: column;
  .logo-img {
    justify-content: center;
    text-align: center;
  }
`;

const MiddleContainer = styled.div`
  flex: 1;
  overflow-y: scroll;
  height: ${height}px;
  width: 100%;
  display: flex;
  justify-content: center;
`;
const MiddleItem = styled.div`
  width: 80%;
`;
const MyInfoTopContent = styled.div`
  padding: 25px 0 25px 0;
  .info-top-title {
    font-size: 20px;
    font-weight: bold;
    color: #fff;
  }
  .info-top-text {
    font-size: 16px;
    color: #7f7f7f;
  }
`;

const MyInfoBottomContent = styled.div`
  font-size: 14px;
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid #333333;
  color: #fff;
  padding: 10px;
  .info-title {
    width: 100px;
    font-weight: bold;
  }
  .info-item {
    flex: 1;
  }
`;
const LogOutContainer = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: center;
  padding: 10px;
  .logout {
    padding:0 30px;
    /* display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 28px;
    border-radius: 13px;
    color: #ffffff;
    background: #4c6eec; */
  }
`;

const BottomButton = styled.div`
  height: 50px;
  color: #ffffff;
  font-size: 16px;
  background: #4c6eec;
  display: flex;
  align-items: center;
  justify-content: center;
`;

function MyInfo() {
  const [loaded, setLoaded] = useState(false);

  // 선언은 제일 위로
  const [name, setName] = useState("");
  const [product, setProduct] = useState("");
  const [startdate, setStartDate] = useState("");
  const [enddate, setEndDate] = useState("");

  // 로그인 / 로그아웃 처리
  const login = useRecoilValue(loginState);
  useEffect(() => {
    if (login.loaded === true) {
      if (login.mcd !== "") {
        setLoaded(true);
      } else {
        GoPage("home", login.cid);
      }
    }
  }, [login.mcd, login.token]);

  // 로그아웃 처리
  const onLogout = () => {
    localStorage.clear();
    GoPage("home", login.cid);
  };

  // 내정보 가져오기 #2
  const load_data = async () => {
    const mcd = login.mcd;
    const cid = login.cid;
    const token = login.token;
    // pr(mcd);
    // pr(cid);
    // pr(token);

    // http://127.0.0.1:3000/gympass/logged_member_one 주소
    // axios 구현
    // ... mcd,cid,token 정보로 데이터베이스에서 정보추출(이미 추출된 상태)

    const result = await axios.post(GetHost() + "/gympass/logged_member_one", {
      mcd: mcd,
      cid: cid,
      token: token,
    }); // 동기화
    console.log(result.data);

    setName(result.data.mb_name);
    setProduct(result.data.pname);
    // 날짜가 빈값인지 확인 후 변경
    if(result.data.sdate!=='') {
      setStartDate(moment(result.data.sdate).format("YYYY-MM-DD"));
    }
    if(result.data.edate!=='') {
      setEndDate(moment(result.data.edate).format("YYYY-MM-DD"));
    }
  };

  // 내정보 가져오기 #1
  useEffect(() => {
    if (login.loaded === true) {
      load_data();
    }
  }, [login.loaded]);

  return (
    <Layout>
      <LoginAuth />
      {loaded === false ? (
        <Loading />
      ) : (
        <>
          <TopContent>
            <TopContainer />
            <div className="logo-img">
              {/* <TopLogoImage /> */}
              <DoCircuitTopLogo />
            </div>
          </TopContent>

          <MiddleContainer>
            <MiddleItem>
              <MyInfoTopContent>
                <div className="info-top-title">내정보</div>
                <div className="info-top-text">
                  피지컬베이스를 이용해주셔서 감사합니다.
                </div>
              </MyInfoTopContent>

              <MyInfoBottomContent>
                <div className="info-title"> 이름</div>
                <div className="info-item">{name}</div>
              </MyInfoBottomContent>

              <MyInfoBottomContent>
                <div className="info-title"> 구매상품</div>
                <div className="info-item">{product}</div>
              </MyInfoBottomContent>

              <MyInfoBottomContent>
                <div className="info-title">이용시작</div>
                <div className="info-item">{startdate}</div>
              </MyInfoBottomContent>

              <MyInfoBottomContent>
                <div className="info-title">이용종료</div>
                <div className="info-item">{enddate}</div>
              </MyInfoBottomContent>

              <LogOutContainer>
                <Button
                  type="primary"
                  className="logout"
                  size="large"
                  onClick={onLogout}
                  shape="round"
                  icon={<LogoutOutlined />}
                >
                  로그아웃
                </Button>
              </LogOutContainer>
            </MiddleItem>
          </MiddleContainer>

          <BottomButton className="bottom" onClick={() => GoPage("logged")}>
            확인
          </BottomButton>
        </>
      )}
    </Layout>
  );
}

export default MyInfo;
