import React, { useState, useEffect } from "react";

function DoCircuitTopLogo() {
  return (
    <img
      style={{ width: 160, height: 32 }}
      className="top-logo"
      src="./img/common/main_logo_white.png
"
      alt="#"
    ></img>
  );
}

export default DoCircuitTopLogo;
