import React, { useState, useEffect } from "react";
import styled from "styled-components";
import pr from "../lib/Pr";
import axios from "axios";
import moment from "moment";
import { Spin } from "antd";
import GetToken from "../lib/GetToken";
import Loading from "./Loading";
import { Tag, Divider, Button } from "antd";
import "antd/dist/antd.css";
import GoPage from "../lib/GoPage";
import GetCid from "../lib/GetCid";
import GetHost from "../lib/GetHost";
import GetMcd from "../lib/GetMcd";
import { BellOutlined } from "@ant-design/icons";
import { Carousel } from "antd";
import { useRecoilValue } from "recoil";
import { loginState } from "../lib/State";
import LoginAuth from "../lib/LoginAuth";
import { GetDayDifference } from "../lib/GetDayDifference";
import LoggedBottom from "./LoggedBottom";

let height = document.documentElement.offsetHeight;
height = height - 200;


const Layout = styled.div`    
    display: flex;
    flex-direction: column;    
    height:100%;   
    .top { height:100px; } 
    .bottom { height: 100px; }
    .middle {flex:1}
    .top, .middle, .bottom {
        border: 3px solid #cccccc;
        margin:3px;
    }
`

const Content = styled.div`    
    border: 3px solid green;
    margin:3px;
    overflow-y:scroll;
    height:${height}px;
`

function Logged() {


  return (
      <Layout>
      <div className="top">
TOP
      </div>
      <div className="middle">
            <Content>
                <div>
                <img src="https://search.pstatic.net/common/?src=http%3A%2F%2Fimage.nmv.naver.net%2Fblog_2021_07_06_2601%2F421003ad-de14-11eb-90b5-505dac8c3607_01.jpg&type=ofullfill340_600"></img>
                </div>
                <div>
                <img src="https://search.pstatic.net/common/?src=http%3A%2F%2Fimage.nmv.naver.net%2Fblog_2021_07_06_2601%2F421003ad-de14-11eb-90b5-505dac8c3607_01.jpg&type=ofullfill340_600"></img>
                </div>
                <div>
                <img src="https://search.pstatic.net/common/?src=http%3A%2F%2Fimage.nmv.naver.net%2Fblog_2021_07_06_2601%2F421003ad-de14-11eb-90b5-505dac8c3607_01.jpg&type=ofullfill340_600"></img>
                </div>
            </Content>
      </div>

      <div className="bottom">
BOTTOM
      </div>
      </Layout>
    
  );
}

export default Logged;
