import React, { useState, useEffect } from "react";
import styled from "styled-components";
import pr from "../lib/Pr";
import Axios from "axios";
import moment from "moment";
import { Spin, Rate } from "antd";
import GetToken from "../lib/GetToken";
import GetLogin from "../lib/GetLogin";
import GetYoil from "../lib/GetYoil";
// import Container from './Container';
import Layout from "./Layout";
import Loading from "./Loading";
import { Tag, Divider, Button, Modal } from "antd";
import "antd/dist/antd.css";
import GoPage from "../lib/GoPage";
import TitleText from "./TitleText";
import MyPtMenu from "./MyPtMenu";
import GetCid from "../lib/GetCid";
import GetHost from "../lib/GetHost";
import GetMcd from "../lib/GetMcd";
import axios from "axios";
import { CloseOutlined } from "@ant-design/icons";
import TopContainer from "./TopContainer";
import { useRecoilValue } from "recoil";
import { loginState } from "../lib/State";
import LoginAuth from "../lib/LoginAuth";
import {
  FieldTimeOutlined,
  CalendarOutlined,
  CarryOutOutlined,
  HeartOutlined,
  ExclamationCircleOutlined,
  StarOutlined,
  CloseSquareOutlined
} from "@ant-design/icons";
const { confirm, success, error } = Modal;

const Container = styled.div`
  /* padding: 5px; */
  background: #111111;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 145px;
`;


const MyPtMenuContainer = styled.div`
  justify-content: center;
  border-top: 1px solid #333333;
  border-bottom: 1px solid #333333;

  display: flex;
`;
const MyClassContainer = styled.div`
  display: flex;

`;

const ClassLeft = styled.div`
  flex: 1;
`;

const ClassRight = styled.div`
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CardStyleContainer = styled.div`
  display: flex;
  justify-content: center;
  overflow-y: scroll;
  height: 100%;
`;

const CardStyleContent = styled.div`
  width: 90%;
  /* height: 100%; */
  margin-top: 20px;
`;

const CardStyle = styled.div`
  color: #fff;
  background-color: #222222;
  border-radius: 10px;
  padding: 20px 10px 30px 20px;
  border: 2px solid #535353;
  margin-bottom: 10px;

  position: relative;

  & .state {
    font-size: 16px;
    margin-bottom: 10px;
  }

  & .state img {
    margin-right: 4px;
    width: 20px;
  }

  & .yellow {
    color: #fff566;
  }

  & .orange {
    color: #fa8c16;
  }

  & .green {
    color: #556b2f;
  }

  & p {
    margin-bottom: 0;
    font-size: 13px;
  }
`;


const ButtonStyle = styled.button`
  padding: 3px 12px;
  font-size: 11px;
  border: none;
  border-radius: 5px;
  position: absolute;
  right: 5%;
  top: 50%;
  margin-top: -25px;
  font-size: 16px;

  &.rate {
    background-color: #dc123c;
  }

  &.req {
    background-color: #d2691e;
  }

  & img {
    display: block;
    margin: 0 auto;
    padding-top: 3px;
    width: 20px;
  }

`;

const StateTitle = styled.div`
  font-size: 18px;
`;

const StateName = styled.div`
  margin-top: 5px;
`;

const HeartTitle = styled.div`
  margin-bottom: 10px;
  font-size: 12px;
  color: #333333;
`;

const HeartQuestion = styled.div`
  margin-bottom: 10px;
  font-size: 14px;
`;

const cid = GetCid();

function Logged() {
  const [rows, setRows] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const login = useRecoilValue(loginState);
  const [question, setQuestion] = useState({
    category: "",
    comment: "",
  });
  const [visible, setVisible] = useState(false);
  const [rateData, setRateData] = useState({
    cid: "",
    mcd: "",
    tcd: "",
    res_seq: "",
    class_date: "",
    category: "",
    comment: "",
  });
  let rate_point = 0;
  const [ratePoint, setRatePoint] = useState(0);

  const onSetRateState = async (res_seq, state) => {
    const result = await axios.post(GetHost() + "/gympass/set_rate_state", {
      res_seq: res_seq,
      state: state,
    });
  };

  // 평가요청
  const onRequestRate = (seq) => {
    confirm({
      title: "별점요청을 하시겠습니까?",
      icon: <ExclamationCircleOutlined />,
      content: "회원 출석이 인정됩니다.",
      okText: "확인",
      cancelText: "취소",
      onOk() {
        onSetRateState(seq, "요청");
        GoPage("my_pt", cid);
      },
      onCancel() {},
    });
  };

  const onRateChange = (value) => {
    setRatePoint(value);
  };

  const onCancelRate = () => {
    setVisible(false);
    setRatePoint(0);
  };

  // 별점등록
  const onRatePost = async () => {
    pr(rateData);
    pr(ratePoint);

    if (ratePoint === 0) {
      error({
        content: "별점을 선택하세요.",
      });
      return;
    }

    // 별점등록
    let result = await axios.post(GetHost() + "/gympass/rate", {
      ...rateData,
      rating: ratePoint,
    });

    success({
      content: "별점 등록이 완료되었습니다.",
      onOk() {
        GoPage("my_pt", cid);
      },
    });

  };

  const onRateHeartPopOpen = async (res_seq, tcd, class_date) => {
    let result = await axios.post(GetHost() + "/gympass/get_random_comment");
    const { category, comment } = result.data;
    setQuestion({
      category: category,
      comment: comment,
    });
    setVisible(true);

    // 평가용 데이타
    setRateData((state) => {
      return {
        ...state,
        cid: login.cid,
        mcd: login.mcd,
        res_seq: res_seq,
        tcd: tcd,
        class_date: class_date,
        category: category,
        comment: comment,
      };
    });
  };

  // 데이타
  const load_data = async () => {
    let result = await axios.post(GetHost() + "/gympass/get_reservation", {
      cid: cid,
      mcd: login.mcd,
      gubun: login.auth,
    });
    setRows(result.data);
    setLoaded(true);
  };

  useEffect(() => {
    if (login.loaded === true) {
      if (login.mcd !== "") {
        load_data();
      } else {
        GoPage("home", login.cid);
      }
    }
  }, [login.mcd, login.token]);

  return (
    <Layout>
      <LoginAuth />
      {loaded === false ? (
        <Loading />
      ) : (
        <Container>
          <Modal
            title="별점주기"
            onOk={onRatePost}
            visible={visible}
            onCancel={onCancelRate}
          >
            <HeartTitle>진행한 수업을 평가해 주세요.</HeartTitle>
            <HeartQuestion>{question.comment}</HeartQuestion>
            <Rate onChange={onRateChange} value={ratePoint} />
          </Modal>

          <TopContainer title="내수업" login={login} />

          <MyPtMenuContainer>
            <MyPtMenu active="1" cid={cid}></MyPtMenu>
          </MyPtMenuContainer>

          <CardStyleContainer>
            <CardStyleContent>
              {rows.length === 0 ? (
                <CardStyle title="NO RESERVATION" extra={<a href="#"></a>}>
                  <p>예약된 일정이 없습니다.</p>
                  <p>내 쿠폰 메뉴에서 수업을 예약하세요.</p>
                </CardStyle>
              ) : null}
              {rows.map((n, i) => {
                const now = moment().format("YYYY-MM-DD HH:mm");
                let show_req_star = false; // 별점요청
                let show_rate_star = false; // 별점주기
                let state = n.state;
                let date = moment(n.class_date).format("YYYY-MM-DD");
                let day = GetYoil(n.class_date);
                let res_seq = n.res_seq;
                let rate_state = n.rate_state;
                let final_state = n.final_state;
                let color = "";
                let icon = "";
                let pt_type = n.pt_type;
                let gubun = login.auth;
                let category = n.category;
                let memo = n.memo;

                let icon_reservation = (
                  <FieldTimeOutlined style={{ fontSize: "20px" }} />
                ); // 예약완료
                let icon_class = (
                  <CalendarOutlined style={{ fontSize: "20px" }} />
                ); // 평가요청
                let icon_star = (
                  <CarryOutOutlined style={{ fontSize: "20px" }} />
                ); // 평가완료
                
                if (pt_type === "PT") pt_type = "[PT]";
                if (pt_type === "GX") pt_type = "[GX]";

                if (final_state === "예약완료") {
                  // state = '예약완료';
                  icon = icon_reservation;
                }

                if (final_state === "평가요청") {
                  // state = '출석완료';
                  icon = icon_class;
                  color = "orange";
                }

                if (final_state === "평가완료") {
                  // state = '별점완료'
                  icon = icon_star;
                  color = "yellow";
                }

                if (final_state === "미평가") {
                  icon = <CloseSquareOutlined style={{ fontSize: 20 }} />;
                  color = "green";
                }

                // 별점요청 및 승인처리
                // 수업시간 종료 x시간 내에 처리
                let class_end = n.class_date + " " + ("0" + n.etime).slice(-5);
                let temp_etime = ("0" + n.etime).slice(-5, -3);
                if (n.ampm === "pm" && temp_etime < 12) {
                  class_end = moment(class_end)
                    .add(12, "hours")
                    .format("YYYY-MM-DD HH:mm");
                }
                let star_point_end = moment(class_end)
                  .add(12, "hours")
                  .format("YYYY-MM-DD HH:mm");

                if (now > class_end) {
                  if (now < star_point_end) {
                    if (rate_state === "") {
                      if (gubun === "teacher") {
                        show_req_star = true;
                      }
                    }
                    if (rate_state === "요청") {
                      if (gubun === "user") {
                        show_rate_star = true;
                      }
                    }
                  } else {
                  }
                } else {
                }

                return (
                  <div key={i}>
                    <CardStyle extra={<a href="#"></a>}>
                      <MyClassContainer>
                        <ClassLeft>
                          <StateTitle style={{ color: color }}>
                            {icon} {final_state} {pt_type}
                          </StateTitle>                          
                          <p style={{fontSize:16, color:"#8dfd72"}}>
                            {date}({day}) {n.stime} {n.ampm}
                          </p>
                          <StateName>{n.cname}</StateName>
                          <p>강사명 : {n.tname} 트레이너</p>
                          <p>회원명 : {n.uname} </p>

                          <p> 운동목적 : {category} </p>
                          <p> 메모 : {memo} </p>
                        </ClassLeft>

                        <ClassRight>
                          {show_req_star && (
                            <ButtonStyle
                              type="primary"
                              shape="round"
                              size="large"
                              className="req"
                              onClick={() => onRequestRate(n.res_seq)}
                            >
                              <HeartOutlined /> 별점요청
                            </ButtonStyle>
                          )}

                          {show_rate_star && (
                            <ButtonStyle
                              type="primary"
                              shape="round"
                              size="large"
                              className="rate"
                              onClick={() =>
                                onRateHeartPopOpen(
                                  n.res_seq.toString(),
                                  n.tcd,
                                  n.class_date
                                )
                              }
                            >
                              <StarOutlined /> 별점주기
                            </ButtonStyle>
                          )}
                        </ClassRight>
                      </MyClassContainer>
                    </CardStyle>
                  </div>
                );
              })}

              <div style={{height:30}}></div>
            </CardStyleContent>
          </CardStyleContainer>
        </Container>
      )}
    </Layout>
  );
}

export default Logged;
