// 토큰 가져오기
function GetToken() {
  return localStorage.getItem("token");
  // return new Promise((resolve, reject) => {
  //   let result = localStorage.getItem("token");
  //   // console.log(result);

  //   if (result === null || result === undefined || result === "") {
  //     resolve('LOGOUT');
  //   } else {
  //     resolve(result);
  //   }
  // });
}
export default GetToken;
