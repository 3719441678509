import React, { Component } from "react";
import styled from 'styled-components'

const GympassTop = styled.div`
  font-size: 25px;
`

function TitleText(props) {
    return(
    <GympassTop>{props.children}</GympassTop>
    );
  }

export default TitleText;
