import React, { Component } from "react";
import styled from 'styled-components'

const Item = styled.div`
  height:100%;
`;

function Layout(props) {
    return (
      <Item>
        {props.children}
      </Item>
    );
  }

export default Layout;
