// 토큰 가져오기
import GetHost from "../lib/GetHost";
import pr from "../lib/Pr";
import axios from "axios";

function GetLogin(params) {
  const host = GetHost();
  const { mcd, cid, token } = params;

  return new Promise((resolve, reject) => {
    const data = {
      mcd: mcd,
      cid: cid,
      token: token,
    };
    axios
      .post(GetHost() + "/gympass/get_login", data)
      .then((result) => {
        resolve(result);
      })
      .catch((e) => {
        alert("GetLogin Error", e);
      });
  });
}

export default GetLogin;

// import React, { useState, useEffect } from "react";
// import GetHost from "../lib/GetHost";
// import axios from "axios";

// // 로그인 정보 가져오기
// function GetLogin(data) {
//   const { token, setLogin } = data;
//   const empty = { mb_id: "", mb_cd: "", auth: "" }; // undefined --> empty string

//   useEffect(() => {
//     if (token !== "") {
//       const url = GetHost() + "/member/get_login";
//       axios.post(url, { token: token })
//         .then((result) => {
//           if (
//             (result.data.mb_id !== "") &
//             (result.data.mb_cd != "") &
//             (result.data.auth != "")
//           ) {
//             if (result.data.auth === "admin" || result.data.auth === "master") {
//               setLogin(result.data);
//             } else {
//               setLogin(empty);
//             }
//           } else {
//             setLogin(empty);
//           }
//         })
//         .catch((e) => {
//           setLogin(empty);
//         });
//     }
//   }, [token]);

//   return null;
// }

// export default GetLogin;
