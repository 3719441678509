import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Tag, Divider, Button } from "antd";
import "antd/dist/antd.css";
import logo from "../img/M_logo.jpg";
import Column from "rc-table/lib/sugar/Column";
import { FiChevronDown } from "react-icons/fi";
import GoPage from "../lib/GoPage";
import { MenuOutlined } from "@ant-design/icons";
import { Carousel } from "antd";


const TopContent = styled.ul`
  display: flex;
  background-color: #264a7b;
  align-items: center;
  margin-bottom: 0;
  padding: 10px 0;
  li {
    text-align: center;
    display: block;
  }
  .center-area {
    flex: 1;
  }
  .left-area,
  .right-area {
    width: 50px;
  }
`;
const SecondContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .mobile-second-content {
    width: 80%;
  }
  .mobile-second-title {
    color: #3f968e;
    font-size: 18px;
  }
  .mobile-second-text {
    color: #2f2725;
    text-decoration-line: underline;
    text-underline-position: under;
    font-size: 14px;
  }
  .middle-text {
    display: flex;
    justify-content: center;
  }
`;
const PropConatainer = styled.div`
  .prop-title {
    display: flex;
    justify-content: center;
    color: #264a7b;
    font-size: 30px;
  }
  button {
    background: #3f968e;
    color: #fff;
    border: none;
    width: 100%;
    height: 60px;
    font-size: 20px;
    display: flex;
    align-items: center;
    /* text-align: center; */
    justify-content: center;
  }
`;
const ContentStyle = styled.div`
  width: 100%;

`;

const CourseContainer = styled.div`
  .courseInfoContainer {
  }
  .courseInfoContent {
    display: flex;
    justify-content: center;
    text-align: center;
  }

  .courseInfoLeft {
    display: flex;
    width: 80%;
    font-size: 16px;
    flex-direction: column;
    justify-content: center;
    /* letter-spacing: 1px; */
  }
  .courseInfoTitle {
    color: #274c7e;
    font-size: 30px;
    font-weight: bold;
  }
  .courseTitle {
    color: #274c7e;
    font-size: 22px;
    margin-top: 20px;
  }

  .courseButton {
    background: #3f968e;
    color: #fefefe;

    border: none;
    cursor: pointer;
  }
  .blackBold {
    font-weight: bold;
  }
  .fontRed {
    color: #e60012;
  }
  .fontBlue {
    color: #274c7e;
  }
  .fontYellow {
    color: #fff100;
  }
  .courseButton {
    background: #3f968e;
    color: #fefefe;
    width: 320px;
    height: 50px;
    border: none;
    font-size: 32px;
    cursor: pointer;
    a {
        color: #fff;
      }
    }

`;
const TelNumberContainer = styled.div`
  background: #3f968e;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fefefe;
  .telNumberContent {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
  }
  .fontYellow {
    color: #fff100;
  }
`;
const FooterLogoContainer = styled.div`
    display: flex;
    justify-content: center;
    height: 134px;
    align-items: center;
    background: #274c7e;
    width: 100%;
  .footerLogoContent {
    display: flex;
    align-items: center;
  }
  img{
    width: 158px;
    height: 35px;
  }
`;

function Test() {
  return (
    <div style={{width: "100%"}}>
      <TopContent>
        <li className="left-area">
          <MenuOutlined style={{ color: "#fff" }} />
        </li>
        <li className="center-area">
          <a href="/">
            <img src={logo}></img>
          </a>
        </li>
        <li className="right-area"></li>
      </TopContent>

      <div>
        <img src="/img/M_background1.jpg" width="100%"></img>
      </div>

      <div>
        <img src="/img/M_background2.jpg" width="100%"></img>
      </div>

      <SecondContainer>
        <div className="mobile-second-content">
          <div className="mobile-second-title">
            예방운동전문가로 포스트코로나 시대를 준비해자!
          </div>
          <div style={{ height: 10 }}></div>
          <div className="mobile-second-text">
            <span className="middle-text">
              인생 제2막, 50대 이후의 새로운 삶에 대한 도전!
            </span>
            부산시가 응원하고, 부산외국어대학교가 그 비젼을 제시합니다.
            <br />
            시대가 요구하는 새로운 전문가, 새로운 일자일, 새로운 창업아이템!
            <br />
            부산시가 응원하고, 부산외국어대학교가 그 비젼을 제시합니다.
            <br />
            예방운동관리사는 이 모든 요구에 가장 부합되는 전문가입니다.
            <br />
            도전하십시요! 나와 내 가족은 물론, 타인의 건강 전문가가 될 것입니다.
            <br />
          </div>
          <div style={{ height: 40 }}></div>
        </div>
      </SecondContainer>

      <div>
        <img src="/img/M_organization.jpg" width="100%"></img>
      </div>

      <PropConatainer>
        <div style={{ height: 30 }}></div>
        <div className="prop-title">
          <div>교수 소개</div>
        </div>
        <Carousel autoplay dots={false}>
          <div>
            <ContentStyle>
              <img
                onClick={() => GoPage("prop1")}
                src="/img/M_teacher1.jpg"
                width="100%"
              ></img>
            </ContentStyle>
          </div>

          <div>
            <ContentStyle>
              <img
                onClick={() => GoPage("prop2")}
                src="/img/M_teacher2.jpg"
                width="100%"
              ></img>
            </ContentStyle>
          </div>
          <div>
            <ContentStyle>
              <img
                onClick={() => GoPage("prop3")}
                src="/img/M_teacher3.jpg"
                width="100%"
              ></img>
            </ContentStyle>
          </div>
          <div>
            <ContentStyle>
              <img
                onClick={() => GoPage("prop4")}
                src="/img/M_teacher4.jpg"
                width="100%"
              ></img>
            </ContentStyle>
          </div>
          <div>
            <ContentStyle>
              <img
                onClick={() => GoPage("prop5")}
                src="/img/M_teacher5.jpg"
                width="100%"
              ></img>
            </ContentStyle>
          </div>
        </Carousel>
      </PropConatainer>

      <div>
        <img src="/img/M_schedule1.jpg" width="100%"></img>
      </div>

      <div>
        <img src="/img/M_schedule2.jpg" width="100%"></img>
      </div>

      <div>
        <img src="/img/M_background3.jpg" width="100%"></img>
      </div>

      <CourseContainer>
        <div className="courseInfoContainer">
          <div className="courseInfoContent">
            <div className="courseInfoLeft">
              <div className="courseInfoTitle">과정등록 안내</div>
              <div>
                <div className="courseTitle">신청기간</div>
                <div>
                  <span className="blackBold">
                    2021년 9월 1일(수)~9월27일(월)
                  </span>
                </div>
              </div>
              <div>
                <div className="courseTitle">교육기간</div>
                <div>
                  <span className="blackBold">
                    2021년 9월 1일(수)~9월27일(월)
                  </span>
                  <div>(매주 화,목요일 18:30~21:30 / 토요일 10:00~13:00)</div>
                </div>
              </div>
              <div>
                <div className="courseTitle">모집대상 및 인원</div>
                <div>
                  <span className="blackBold">
                    생애재설계를 꿈꾸는 만50~64세의 부산시민 남,여 누구나
                  </span>
                  <br />
                  <span className="fontBlue">
                    (주민등록등본 상 거주지가 부산이어야 지원 가능)
                  </span>
                </div>
              </div>
              <div>
                <div className="courseTitle">수강료</div>
                <div>
                  <span className="blackBold">본인 10만원</span>
                  <span className="fontBlue">
                    (교육비 65만원 중 55만원은 부산시가 지원)
                  </span>
                </div>
              </div>
              <div>
                <div className="courseTitle">교육장소</div>
                <div>
                  <span className="blackBold">
                    부산외국어대학교 평생 교육원
                  </span>
                </div>
              </div>
              <div>
                <div className="courseTitle">신청방법</div>
                <div>
                  <div>
                    <div className="blackBold">방문 접수</div>부산시 금샘로
                    486번길 65 부산외대 A302호 평생교육원
                    <div className="fontRed"> (전화접수 불가)</div>
                  </div>
                  <div>
                    <div className="blackBold">인터넷 접수</div>
                    <div>구글폼</div>
                    <div>https://forms.gle/FCL1vsp3RfmLTj4S9 접속 후 등록</div>
                  </div>
                </div>
              </div>
              <div style={{ height: 40 }}></div>

              <div>
                <div>
                  <button className="courseButton">
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLScNmpqRE8Lc977mqSY07FxWrOPEMTWNOyGU6upUAfiyaZyw3w/closedform">
                      교육등록바로가기
                    </a>
                  </button>
                </div>
                <div style={{ height: 50 }}></div>
              </div>
            </div>
          </div>
        </div>
      </CourseContainer>

      <TelNumberContainer>
        <div className="telNumberContent">
          <div className="telNumberText">
            <div style={{ height: 57 }}></div>

            <div className="fontYellow" style={{ fontSize: 30 }}>
              교육문의
            </div>
            <div style={{ fontSize: 22 }}>
              "친절하고 빠른 상담으로 도와드리겠습니다."
            </div>
            <div style={{ fontSize: 35, fontWeight: "bold" }}>
              TEL:051)509-6392
            </div>
            <div style={{ fontSize: 16 }}>상담가능시간: 평일 09:00 ~ 18:00</div>
            <div style={{ height: 45 }}></div>
          </div>
        </div>
      </TelNumberContainer>

      <FooterLogoContainer>
        <div className="footerLogoContent">
          <img src="./img/M_footer_logo1.jpg" alt="#"></img>
          <div style={{ width: 28 }}></div>

          <a href="https://www.bufs.ac.kr/">
            <img src="./img/M_footer_logo2.jpg" alt="#"></img>
          </a>
        </div>
      </FooterLogoContainer>
    </div>
  );
}

export default Test;
