import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Tag, Divider, Button } from "antd";
import "antd/dist/antd.css";
import logo from "../img/M_logo_new.jpg";
import Column from "rc-table/lib/sugar/Column";
import { FiChevronDown } from "react-icons/fi";
import GoPage from "../lib/GoPage";
import { MenuOutlined } from "@ant-design/icons";
import { Carousel } from "antd";
import { Drawer } from "antd";
import { Menu, Dropdown, Space } from "antd";
import DrawerMenu from "./DrawerMenu";
import HomeVideo from "./HomeVideo"; // 소개영상

const SecondContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .mobile-second-content {
    width: 85%;
  }
  .mobile-second-title {
    color: #3f968e;
    font-size: 18px;
  }
  .mobile-second-text {
    color: #2f2725;
    text-decoration-line: underline;
    text-underline-position: under;
    font-size: 14px;
  }
  .middle-text {
    display: flex;
    justify-content: center;
    font-size: 16px;
    margin-bottom: 5px;
  }
`;
const PropConatainer = styled.div`
  .prop-title {
    display: flex;
    justify-content: center;
    color: #264a7b;
    font-size: 30px;
  }
  button {
    background: #3f968e;
    color: #fff;
    border: none;
    width: 100%;
    height: 60px;
    font-size: 20px;
    display: flex;
    align-items: center;
    /* text-align: center; */
    justify-content: center;
  }
`;
const ContentStyle = styled.div`
  width: 100%;
`;

const CourseContainer = styled.div`
  font-size: 16px;
  .courseInfoContent {
    display: flex;
    justify-content: center;
    text-align: center;
  }

  .courseInfoLeft {
    img {
      margin-top: 20px;

      max-width: 200px;
    }
  }
  .courseInfoTitle {
    color: #274c7e;
    font-size: 30px;
    font-weight: bold;
  }
  .courseTitle {
    color: #274c7e;
    font-size: 22px;
    margin-top: 20px;
  }

  .blackBold {
    font-weight: bold;
  }
  .fontRed {
    color: #e60012;
  }
  .fontBlue {
    color: #274c7e;
  }
  .fontYellow {
    color: #fff100;
  }
  .courseButton {
    background: #3f968e;
    color: #fefefe;
    width: 350px;
    height: 50px;
    border: none;
    font-size: 16px;
    cursor: pointer;
    a {
      color: #fff;
    }
  }
`;
const TelNumberContainer = styled.div`
  background: #3f968e;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fefefe;
  .telNumberContent {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
  }
  .fontYellow {
    color: #fff100;
  }
`;
const FooterLogoContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 134px;
  align-items: center;
  background: #274c7e;
  width: 100%;
  .footerLogoContent {
    display: flex;
    align-items: center;
  }
  img {
    width: 158px;
    height: 35px;
  }
`;
const DrawerConainer = styled.div`
  a {
    text-decoration: none !important;
  }
  .drawer-font {
    font-size: 70px;
  }
`;

function MobileHome() {
  return (
    <div style={{ width: "100%" }}>
      <DrawerMenu />

      <HomeVideo></HomeVideo>

      <div style={{ position: "relative" }}>
        <img src="/img/M_background1.png" width="100%"></img>
        <div
          style={{
            position: "absolute",
            top: "50%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            backgroundColor: "rgba(255,255,255,0.5)",
            padding: "1rem 0",
            transform: "translate(0, -50%)",
          }}
        >
          <p style={{ color: "#3e3a39", fontSize: "0.8rem", margin: "0" }}>
            2024년 제4기 부산광역시 위탁
          </p>
          <p
            style={{
              fontSize: "2rem",
              color: "#264a7b",
              lineHeight: "120%",
              margin: "0",
            }}
          >
            예방운동관리사
            <br />
            양성과정
          </p>
        </div>
      </div>

      <div>
        <img src="/img/M_background2.jpg" width="100%"></img>
      </div>

      <SecondContainer>
        <div className="mobile-second-content">
          <div className="mobile-second-title">
            예방운동전문가로 포스트코로나 시대를 준비해자!
          </div>
          <div style={{ height: 10 }}></div>
          <div className="mobile-second-text">
            <span className="middle-text">
              인생 제2막, 50대 이후의 새로운 삶에 대한 도전!
            </span>
            부산시가 응원하고, 부산외국어대학교가 그 비젼을 제시합니다. 시대가
            요구하는 새로운 전문가, 새로운 일자일, 새로운 창업아이템! 부산시가
            응원하고, 부산외국어대학교가 그 비젼을 제시합니다. 예방운동관리사는
            이 모든 요구에 가장 부합되는 전문가입니다. 도전하십시요! 나와 내
            가족은 물론, 타인의 건강 전문가가 될 것입니다.
          </div>
          <div style={{ height: 40 }}></div>
        </div>
      </SecondContainer>

      <a name="chart"></a>
      <div>
        <img src="/img/M_organization.png" width="100%"></img>
      </div>

      <PropConatainer>
        <div className="prop-title">
          <img src="/img/M_teacher_title.jpg" width="100%"></img>
        </div>
        <Carousel autoplay dots={false}>
          <div>
            <ContentStyle>
              <a href="/mobile_prof1">
                <img
                  onClick={() => GoPage("mobile_prof1")}
                  src="/img/M_teacher1.png"
                  width="100%"
                ></img>
              </a>
            </ContentStyle>
          </div>

          <div>
            <ContentStyle>
              <a href="/mobile_prof2">
                <img
                  onClick={() => GoPage("mobile_prof2")}
                  src="/img/M_teacher2.png"
                  width="100%"
                ></img>
              </a>
            </ContentStyle>
          </div>
          <div>
            <ContentStyle>
              <a href="/mobile_prof3">
                <img
                  onClick={() => GoPage("mobile_prof3")}
                  src="/img/M_teacher3_2023.png"
                  width="100%"
                ></img>
              </a>
            </ContentStyle>
          </div>
          <div>
            <ContentStyle>
              <a href="/mobile_prof4">
                <img src="/img/M_teacher4.png" width="100%"></img>
              </a>
            </ContentStyle>
          </div>
          <div>
            <ContentStyle>
              <a href="/mobile_prof5">
                <img
                  onClick={() => GoPage("mobile_prof5")}
                  src="/img/M_teacher5.png"
                  width="100%"
                ></img>
              </a>
            </ContentStyle>
          </div>
        </Carousel>
      </PropConatainer>

      <a name="plan"></a>
      <div>
        <img src="/img/data2024_1.png" width="100%"></img>
      </div>

      <div>
        <img src="/img/data2024_2.png" width="100%"></img>
      </div>

      <div>
        <img src="/img/M_background3.jpg" width="100%"></img>
      </div>

      <CourseContainer>
        <div className="courseInfoContent">
          <div className="courseInfoLeft">
            <img src="/img/M_info_title.jpg"></img>
            <div>
              <div className="courseTitle">신청기간</div>
              <div>
                <span className="blackBold">
                  2024년 2월 19일(월) ~ 2월 29일 (목)
                </span>
              </div>
            </div>
            <div>
              <div className="courseTitle">면접 예정일</div>
              <div>
                <span className="blackBold">개별연락</span>
              </div>
            </div>
            <div>
              <div className="courseTitle">교육기간</div>
              <div>
                <span className="blackBold">
                  1학기 : 2024년 3월 12일(화) ~ 6월 18일(금)
                </span>
                <div>(매주 화, 목요일 18:30~21:30)</div>
                <span className="blackBold">
                  2학기 : 2024년 9월 3일(화) ~ 11월 29일(금)
                </span>
                <div>(매주 화요일 18:30~21:30)</div>
              </div>
            </div>
            <div>
              <div className="courseTitle">모집대상 및 인원</div>
              <div>
                <span className="blackBold">
                  생애재설계를 꿈꾸는 만50~64세의 부산시민 남,여 누구나
                </span>
                <br />
                <span className="fontBlue">
                  (주민등록등본 상 거주지가 부산이어야 지원 가능)
                </span>
              </div>
            </div>
            <div>
              <div className="courseTitle">수강료</div>
              <div>
                <div className="blackBold">본인 10만원</div>
                <span className="fontBlue">
                  (교육비 70만원 중 60만원은 부산시가 지원)
                </span>
              </div>
            </div>
            <div>
              <div className="courseTitle">교육장소</div>
              <div>
                <span className="blackBold">부산외국어대학교 평생교육원</span>
              </div>
            </div>
            <div>
              <div className="courseTitle">신청방법</div>
              <div>
                <div>
                  <div className="blackBold">방문 접수, 온라인 접수</div>부산시
                  금샘로 486번길 65 부산외대 A302호 평생교육원
                  <div className="fontRed"> (전화접수 불가)</div>
                </div>
                <div>
                  <span className="blackBold" style={{ marginRight: 10 }}>
                    인터넷 접수
                  </span>
                  <a
                    href="https://docs.google.com/forms/d/e/1FAIpQLSeOOptTF-FfoTTZYsZS8ADdiJjDKqFU0nZvH01XM9uxZLsbYA/viewform"
                    target="_blank"
                  >
                    <button className="courseButton">구글폼 접수</button>
                  </a>
                </div>
                <div style={{ height: "30px" }}></div>
              </div>
            </div>
          </div>
        </div>
      </CourseContainer>

      <TelNumberContainer>
        <div className="telNumberContent">
          <div className="telNumberText">
            <div style={{ height: 35 }}></div>

            <div className="fontYellow" style={{ fontSize: 28 }}>
              교육문의
            </div>
            <div style={{ fontSize: 20 }}>
              "친절하고 빠른 상담으로 도와드리겠습니다."
            </div>
            <div style={{ fontSize: 33, fontWeight: "bold" }}>
              TEL : 051-509-6392
            </div>
            <div style={{ fontSize: 16 }}>상담가능시간: 평일 10:00 ~ 16:00</div>
            <div style={{ height: 50 }}></div>
          </div>
        </div>
      </TelNumberContainer>

      <FooterLogoContainer>
        <div className="footerLogoContent">
          <span style={{ marginRight: "10px" }}>
            <img src="/img/M_footer_logo1.jpg" alt="#"></img>
          </span>
          {/* <div style={{ width: 28 }}></div> */}

          <a href="https://www.bufs.ac.kr/">
            <span>
              <img src="/img/M_footer_logo2.jpg" alt="#"></img>
            </span>
          </a>
        </div>
      </FooterLogoContainer>
    </div>
  );
}

export default MobileHome;
