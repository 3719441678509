import React from "react";
import styled from "styled-components";

function IntroVideoTitle(props) {
  let { fontSize } = props;
  fontSize = parseInt(fontSize);

  return (
    <div className="video-title" style={{ fontSize: fontSize }}>
      {props.children}
    </div>
  );
}

const VideoStyle = styled.div`
  .video-title {
    font-size: 60px;
    color: #264a7b;
    font-weight: bold;
    padding: 50px 0;
  }
  .pc {
    display: block;
    text-align: center;
  }
  .mobile {
    display: none;
  }

  /* css for mobile */
  @media (max-width: 768px) { 
    .video-title {
      font-size: 30px;
      color: #264a7b;
      font-weight: bold;
      padding: 10px 0;
    }
    .pc {
      display: none;
    }
    .mobile {
      display: block;
      width: 100%;
      text-align: center;

      padding: 10px;
    }
    .movideo {
      max-height: 300px;
      width: 100%;
    }
  }
`;

export default function HomeVideo() {
  return (
    <VideoStyle>
      <div className="pc">
        <IntroVideoTitle>예방운동관리사 소개영상 1</IntroVideoTitle>
        <div>
          <video
            className="pcvideo"
            poster="/img/videoposter2023.png"
            controls
            src="/video/50+1.mp4"
            width="1200"
            height="700"
            autoplay
            onclick="this.play()"
          ></video>
        </div>
      </div>
      <div className="pc">
        <IntroVideoTitle>예방운동관리사 소개영상 2</IntroVideoTitle>
        <div>
          <video
            className="pcvideo"
            poster="/img/videoposter2023.png"
            controls
            src="/video/50+2.mp4"
            width="1200"
            height="700"
            autoplay
            onclick="this.play()"
          ></video>
        </div>
      </div>
      <div className="pc">
        <IntroVideoTitle>예방운동관리사 소개영상 3</IntroVideoTitle>
        <div>
          <video
            className="pcvideo"
            poster="/img/videoposter2023.png"
            controls
            src="/video/50+3.mp4"
            width="1200"
            height="700"
            autoplay
            onclick="this.play()"
          ></video>
        </div>
      </div>
      <div className="pc" style={{ marginTop: 60 }}></div>

      <div className="mobile">
        <IntroVideoTitle>예방운동관리사 소개영상 1</IntroVideoTitle>
        <div>
          <video
            className="movideo"
            poster="/img/videoposter2023.png"
            controls
            src="/video/50+1.mp4"
            width="1200"
            height="700"
            autoplay
            onclick="this.play()"
          ></video>
        </div>
      </div>
      <div className="mobile">
        <IntroVideoTitle>예방운동관리사 소개영상 2</IntroVideoTitle>
        <div>
          <video
            className="movideo"
            poster="/img/videoposter2023.png"
            controls
            src="/video/50+2.mp4"
            width="1200"
            height="700"
            autoplay
            onclick="this.play()"
          ></video>
        </div>
      </div>
      <div className="mobile">
        <IntroVideoTitle>예방운동관리사 소개영상 3</IntroVideoTitle>
        <div>
          <video
            className="movideo"
            poster="/img/videoposter2023.png"
            controls
            src="/video/50+3.mp4"
            width="1200"
            height="700"
            autoplay
            onclick="this.play()"
          ></video>
        </div>
      </div>
      <div className="mobile" style={{ marginTop: 20 }}></div>
    </VideoStyle>
  );
}
