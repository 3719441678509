import React, { Component } from "react";
import styled from "styled-components";
import { LeftOutlined } from "@ant-design/icons";
import GoPage from "../lib/GoPage";

const BackContainer = styled.div`
  height: 60px;
  display: flex;
  align-items: center;
  padding-left: 10px;
`;
function BackBtn(props) {
  const { cid, go } = props;

  const handleClick = () => {
    GoPage(go, cid);
  };

  return (
    <BackContainer>
      <LeftOutlined
        style={{ fontSize: "200%", padding: 5 }}
        onClick={() => handleClick()}
      />
    </BackContainer>
  );
}

export default BackBtn;
