import React, { useState, useEffect } from "react";
import styled from "styled-components";
import pr from "../lib/Pr";
import Axios from "axios";
import moment from "moment";
import { Spin } from "antd";
import GetToken from "../lib/GetToken";
import GetLogin from "../lib/GetLogin";
// import Container from './Container';
import Layout from "./Layout";
import Loading from "./Loading";
import { Tag, Divider, Button, Checkbox, Modal } from "antd";
import "antd/dist/antd.css";
import GoPage from "../lib/GoPage";
import GetCid from "../lib/GetCid";
import TitleText from "./TitleText";
import GetAgreement from "../lib/GetAgreement";
import { BorderOutlined, CheckSquareOutlined } from "@ant-design/icons";
// import TimelineItem from "antd/lib/timeline/TimelineItem";
import BackBtn from "./BackBtn";

const RootContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const TopContainer = styled.div``;

const BottomButtonContainer = styled.div`
  position: fixed;
  width: 100%;
  bottom: 0;
`;

const BottomButton = styled.div`
  background: #4c6eec;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 16px;
`;

const JoinTopContainer = styled.div`
  flex: 1;
`;

const JoinLogoContainer = styled.div`
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding-top: 40px; */
`;

const JoinLogoConte = styled.div`
  /* border: 1px solid blue; */
  text-align: center;
  img {
    width: 260px;
  }
`;

const JoinTextContainer = styled.div`
  display: flex;
  margin-top: 40px;
  justify-content: center;

  .join-text {
    display: flex;
    font-size: 16px;
  }
  .L {
    flex: 1;
  }
  .R {
    font-size: 20px;
  }
`;

const JoinTextContent = styled.div`
  width: 90%;
  /* border: 1px solid orange; */
  .join-text1 {
    /* border: 1px solid green; */
    font-size: 20px;
    font-weight: bold;
  }
  .join-text2 {
    /* border: 1px solid green; */
    font-size: 16px;
    color: #7f7f7f;
  }
  .join-text3 {
    font-size: 20px;
    font-weight: bold;
  }

  .gray-line {
    border-bottom: 1px solid #cccccc;
  }
`;

const JoinText = styled.div`
  padding-bottom: 12px;
`;

function Login() {
  let result;
  let agreement = {};
  const [token, setToken] = useState(); // --- #인증1
  const [login, setLogin] = useState({}); // --- #인증2
  const [loaded, setLoaded] = useState();
  const [agree, setAgree] = useState([]);
  const [privacy, setPrivacy] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const [modalText, setModalText] = useState({});
  const [checked, setChecked] = useState([]);
  const [pno, setPno] = useState();
  let max = 0;
  const cid = GetCid();

  useEffect(() => {
    // 약관로드
    if (cid !== "") {
      agreement = GetAgreement({ cid: cid });
      setAgree(agreement.agreement);
      setPrivacy(agreement.privacy);
    }
  }, [cid]);

  useEffect(() => {
    // debugger;
    setLoaded(true);
  }, []);

  const showModal = (item, no) => {
    setPno(no);
    setModalText({
      title: item.title,
      body: item.body.replace(/\n/gi, "<br>"),
    });
    setModalVisible(true);
  };

  const handleOk = () => {
    setChecked((state) => {
      state[pno] = 1;
      return { ...state };
    });
    setModalVisible(false);
  };

  const handleCancle = () => {
    setModalVisible(false);
  };

  const handleNext = () => {
    let i;
    let count = 0;
    const total = agree.length + 1;
    for (i = 0; i < total; i++) {
      if (checked[i] === 1) {
        count++;
      }
    }
    if (checked[99] === 1) count++;
    if (count < total) {
      Modal.error({
        title: "오류 메시지",
        content: "약관 동의 후 가입 가능합니다.",
      });
      return;
    }
    GoPage("join2", cid);
  };

  return (
    <Layout>
      {loaded === false ? (
        <Loading />
      ) : (
        <RootContainer>
          <TopContainer>
            <Modal
              title={modalText.title}
              visible={modalVisible}
              height="200"
              onOk={handleOk}
              onCancel={handleCancle}
            >
              <div
                dangerouslySetInnerHTML={{ __html: modalText.body }}
                style={{ height: 200, overflowY: "scroll" }}
              ></div>
            </Modal>

            <BackBtn go="home" cid={cid} onClick={() => GoPage("login")} />

            <JoinTopContainer>
              <JoinLogoContainer>
                <JoinLogoConte>
                  <img
                    src="/img/common/main_logo_black.png
"
                    alt="#"
                  ></img>
                </JoinLogoConte>
              </JoinLogoContainer>

              <JoinTextContainer>
                <JoinTextContent>
                  <JoinText>
                    <div className="join-text1">회원가입</div>
                    <div className="join-text2">
                      이용약관, 개인정보 수집 및 이용에 관한 내용을 각각 확인 후
                      동의하기실 바랍니다.
                    </div>
                  </JoinText>

                  <JoinText>
                    <div className="join-text3">
                      서비스 이용약관에 대한 동의 (필수)
                    </div>
                    <div className="gray-line"></div>

                    <ul>
                      {agree.map((n, i) => {
                        return (
                          <li
                            className="join-text"
                            key={i}
                            onClick={() => showModal(n, i)}
                          >
                            <div className="L"> {n.title}</div>
                            <div className="R">
                              {checked[i] !== 1 ? (
                                <BorderOutlined style={{ color: "#808080" }} />
                              ) : (
                                <CheckSquareOutlined style={{ color: "red" }} />
                              )}
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </JoinText>

                  <JoinText>
                    <div className="join-text3">
                      개인정보 수집 및 이용에 대한 동의 (필수)
                    </div>
                    <div className="gray-line"></div>

                    <ul>
                      <li
                        className="join-text"
                        onClick={() => showModal(privacy, 99)}
                      >
                        <div className="L">{privacy.title}</div>

                        <div className="R">
                          {checked[99] !== 1 ? (
                            <BorderOutlined style={{ color: "#808080" }} />
                          ) : (
                            <CheckSquareOutlined style={{ color: "red" }} />
                          )}
                        </div>
                      </li>
                    </ul>
                  </JoinText>
                </JoinTextContent>
              </JoinTextContainer>
            </JoinTopContainer>

            <div style={{ height: 40 }}></div>
          </TopContainer>

          <BottomButtonContainer>
            <BottomButton onClick={() => handleNext()}>동의완료</BottomButton>
          </BottomButtonContainer>
        </RootContainer>
      )}
    </Layout>
  );
}

export default Login;
