import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import pr from "../lib/Pr";
import axios from "axios";
import { Spin, Select } from "antd";
import GetToken from "../lib/GetToken";
import GetLogin from "../lib/GetLogin";
import GetMcd from "../lib/GetMcd";

// import Container from './Container';
import Layout from "./Layout";
import Loading from "./Loading";
import { Tag, Divider, Button, Modal } from "antd";
import { Result } from "antd";
import "antd/dist/antd.css";
import GoPage from "../lib/GoPage";
import GetCid from "../lib/GetCid";
import GetHost from "../lib/GetHost";
import TitleText from "./TitleText";
import moment from "moment";
import { MinusSquareOutlined, CheckSquareOutlined } from "@ant-design/icons";
import queryString from "query-string";
import addComma from "../lib/AddComma";
import getConfig from "../lib/getConfig";
import GetAgreement from "../lib/GetAgreement";

const { Option } = Select;

const Container = styled.div``;

function OrderResult() {
  let result;
  const cid = GetCid();
  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState({});

  // 처리결과
  let query = queryString.parse(window.location.search);
  useEffect(() => {
    setData(query);
  }, []);

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      setLoaded(true);

      (async () => {
        // 결제처리
        await axios.post(GetHost() + "/gympass/cardpay_result", data);
      })();
    }
  }, [data]);

  return (
    <Layout>
      {loaded === false ? (
        <Loading />
      ) : (
        <Container>
          <Button onClick={() => GoPage("logged")}>BACK LOGGED</Button>

          {data.success === "true" ? (
          <Result
            status="success"
            title="카드 결제를 완료했습니다."
            subTitle={`거래번호 : ${query.merchant_uid} 이용해 주셔서 감사합니다.`}
            extra={[
              <Button type="primary" key="console" onClick={()=>GoPage('logged',cid)}>
                홈으로 돌아가기
              </Button>,
            ]}
          />):(

          <Result
            status="error"
            title="카드 결제를 실패했습니다."
            subTitle={`오류내용 : ${query.error_msg}.`}
            extra={[
              <Button type="primary" key="console" onClick={()=>GoPage('logged',cid)}>
                홈으로 돌아가기
              </Button>,
            ]}
          /> )}

        </Container>
      )}
    </Layout>
  );
}

export default OrderResult;

/*
apply_num: "30952054"
bank_name: null
buyer_addr: ""
buyer_email: ""
buyer_name: "홍길동"
buyer_postcode: ""
buyer_tel: ""
card_name: "BC카드"
card_number: "910032*********8"
card_quota: "0"
cid: "CID00000009"
currency: "KRW"
custom_data: null
imp_uid: "imp_197243794789"
merchant_uid: "mid_1623308242953"
name: "헬스 1개월"
paid_amount: "1000"
paid_at: "1623308328"
pay_method: "card"
pg_provider: "html5_inicis"
pg_tid: "StdpayISP_INIpayTest20210610155847987734"
pg_type: "payment"
receipt_url: "https://iniweb.inicis.com/DefaultWebApp/mall/cr/cm/mCmReceipt_head.jsp?noTid=StdpayISP_INIpayTest20210610155847987734&noMethod=1"
status: "paid"
success: "true"
*/

/*
cid: "CID00000009"
error_msg: "사용자가 결제를 취소하셨습니다"
imp_uid: "imp_355323644395"
merchant_uid: "mid_1623311322517"
pay_method: "card"
pg_provider: "html5_inicis"
pg_type: "payment"
success: "false"
*/

/*
http://127.0.0.1:8080/order_result?cid=CID00000009&apply_num=32309422&bank_name&buyer_addr=&buyer_email=&buyer_name=%ED%99%8D%EA%B8%B8%EB%8F%99&buyer_postcode=&buyer_tel=&card_name=BC%EC%B9%B4%EB%93%9C&card_number=910032%2A%2A%2A%2A%2A%2A%2A%2A%2A8&card_quota=0&currency=KRW&custom_data&imp_uid=imp_587661515663&merchant_uid=mid_1623317660406&name=%ED%97%AC%EC%8A%A4%201%EA%B0%9C%EC%9B%94&paid_amount=1000&paid_at=1623317746&pay_method=card&pg_provider=html5_inicis&pg_tid=StdpayISP_INIpayTest20210610183545981909&pg_type=payment&receipt_url=https%3A%2F%2Finiweb.inicis.com%2FDefaultWebApp%2Fmall%2Fcr%2Fcm%2FmCmReceipt_head.jsp%3FnoTid%3DStdpayISP_INIpayTest20210610183545981909%26noMethod%3D1&status=paid&success=true
*/
