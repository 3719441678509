import React, { useState, useEffect } from "react";
import styled from "styled-components";
import pr from "../lib/Pr";
import axios from "axios";
import moment from "moment";
import { Spin } from "antd";
import GetToken from "../lib/GetToken";
import Loading from "./Loading";
import { Tag, Divider, Button } from "antd";
import "antd/dist/antd.css";
import GoPage from "../lib/GoPage";
import GetCid from "../lib/GetCid";
import GetHost from "../lib/GetHost";
import GetMcd from "../lib/GetMcd";
import { BellOutlined } from "@ant-design/icons";
import { Carousel } from "antd";
import { useRecoilValue } from "recoil";
import { loginState } from "../lib/State";
import LoginAuth from "../lib/LoginAuth";
import { GetDayDifference } from "../lib/GetDayDifference";
import LoggedBottom from "./LoggedBottom";
import TopContainer from "./TopContainer";

let height = document.documentElement.offsetHeight;
height = height - 150;

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #111111;
  color: #ffffff;
  .top {
    height: 100px;
    display: flex;
    flex-direction: column;
  }
  .bottom {
    height: 50px;
  }
  .middle {
    flex: 1;
  }

`;
const MyOderTop = styled.div`
  display: flex;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
`;

const MyOderTopText = styled.div`
  /* border: 1px solid orange; */
  display: flex;
  justify-content: center;
  .login-text1 {
    width: 90%;
    font-size: 20px;
    font-weight: bold;
    /* border: 1px solid orange; */
  }
`;
const Container = styled.div`
  display: flex;
  justify-content: center;
`;
const Content = styled.div`
  width: 90%;
  /* border: 3px solid green; */
  /* margin: 3px; */
  overflow-y: scroll;
  height: ${height}px;
`;
const CardStyle = styled.div`
  color: #fff;
  background-color: #222222;
  border-radius: 10px;
  padding: 20px 10px 30px 20px;
  border: 2px solid #535353;
  margin-bottom: 10px;

  position: relative;

  & .state {
    font-size: 16px;
    margin-bottom: 10px;
  }

  & .state img {
    margin-right: 4px;
    width: 20px;
  }

  & .yellow {
    color: #fff566;
  }

  & .orange {
    color: #fa8c16;
  }

  & .green {
    color: #556b2f;
  }

  & p {
    margin-bottom: 0;
    font-size: 13px;
  }
`;

const OderListContent = styled.div`
  display: flex;
  margin: 13px 0 13px 0;
  border-bottom: 1px solid #333333;
`;
const OderListLeftContainer = styled.div`
  flex-basis: 70%;
`;
const OderListLeftContent = styled.div`
  font-size: 14px;
  margin-bottom: 5px;
  .oder-title1 {
    color: #cccccc;
  }
`;
const OderListRightContainer = styled.div`
  flex-basis: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const OderListRightContent = styled.div``;
const BottomButtonContainer = styled.div`
  position: fixed;
  width: 100%;
  bottom: 0;
`;
const BottomButton = styled.div`
  background: #4c6eec;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 16px;
`;


function Logged() {
  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState([]);

  const [p_date, setPdate] = useState("");
  const [p_name, setPname] = useState("");
  const [sdate, setSdate] = useState("");
  const [edate, setEdate] = useState("");

  // 로그인 / 로그아웃 처리
  const login = useRecoilValue(loginState);
  useEffect(() => {
    if (login.loaded === true) {
      if (login.mcd !== "") {
        setLoaded(true);
      } else {
        GoPage("home", login.cid);
      }
    }
  }, [login.mcd, login.token]);


  // 내정보 가져오기 #2
  const load_data = async () => {
    const mcd = login.mcd;
    const cid = login.cid;
    const token = login.token;

    const result = await axios.post(GetHost() + "/gympass/member_purchase", {
      mcd: mcd,
      cid: cid,
      token: token,
    }); // 동기화
    console.log(result.data);
    setData(result.data);

  };

  // 내정보 가져오기 #1
  useEffect(() => {
    if (login.loaded === true) {
      load_data();
    }
  }, [login.loaded]);

  return (
    <Layout>
      <div className="top">
        <MyOderTop>
          <TopContainer ffff />
          {/* <TopContainer login={login} /> */}

          <MyOderTopText>
            <div className="login-text1">구매내역</div>
          </MyOderTopText>
        </MyOderTop>
      </div>


      <div className="middle">
        <Container>
          <Content>
        {data.length === 0 ? (
       <CardStyle title="NO RESERVATION"
        extra={<a href="#"></a>}>
         <p>예약된 일정이 없습니다.</p>
         <p>내 쿠폰 메뉴에서 수업을 예약하세요.</p>
       </CardStyle>
        ) : null}
            {data.map((item, index) => {
              let { p_date, sdate, edate, p_name } = item;
              // let p_date = item.p_date;

              const year1 = p_date.substring(0, 4);
              const month1 = p_date.substring(4, 6);
              const day1 = p_date.substring(6, 8);
              // const hour1 = p_date.substring(8, 10);
              // const min1 = p_date.substring(10, 12);
              p_date = year1 + "." + month1 + "." + day1;

              const year2 = sdate.substring(0, 4);
              const month2 = sdate.substring(4, 6);
              const day2 = sdate.substring(6, 8);
              sdate = year2 + "." + month2 + "." + day2;

              const year3 = edate.substring(0, 4);
              const month3 = edate.substring(4, 6);
              const day3 = edate.substring(6, 8);
              edate = year3 + "." + month3 + "." + day3;

              return (
                <OderListContent>
                  <OderListLeftContainer>
                    <OderListLeftContent>
                      <div className="oder-title1">{p_date}</div>
                      <div className="oder-title">{p_name}</div>
                      <div className="oder-title">
                        {sdate}~{edate}
                      </div>
                    </OderListLeftContent>
                  </OderListLeftContainer>

                  <OderListRightContainer>
                    <OderListRightContent>
                      {/* <Button type="primary" onClick={() => GoPage("#")}>
                        환불신청
                      </Button> */}
                    </OderListRightContent>
                  </OderListRightContainer>
                </OderListContent>
              );
            })}
          </Content>
        </Container>
      </div>

      <div className="bottom">
        <BottomButtonContainer>
          <BottomButton onClick={() => GoPage("logged")}>확인</BottomButton>
        </BottomButtonContainer>
      </div>
    </Layout>
  );
}

export default Logged;
